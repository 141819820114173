import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { ChartColors } from "../../models/colors";
import { SelectionService } from "../../services/selection.service";
import { TargetBpmService } from "app/services/target-bpm.service";

@Component({
  selector: "app-oee-dashboard-component",
  templateUrl: "oee-dashboard.component.html",
  styleUrls: ["oee-dashboard.component.scss"],
})
export class OeeDashboardComponent implements OnInit {
  // calendar settings
  today = moment().toDate();

  showSlowdownRemainder: boolean = true;

  dataAvailable = false;
  dataCurrentlyLoading$ = new BehaviorSubject(false);
  topReasonsDetails$ = new BehaviorSubject({});
  failuresRunning$ = new BehaviorSubject({});
  shortstopFailures$ = new BehaviorSubject({});
  slowdowns$ = new BehaviorSubject({});
  technicalFailures$ = new BehaviorSubject({});
  adjustmentWorkFailures$ = new BehaviorSubject({});
  noOilFailures$ = new BehaviorSubject({});
  oeeDetails$ = new BehaviorSubject({});
  setupDetails$ = new BehaviorSubject({});

  columnChartsData: any = [];

  slowdownColor: string = ChartColors["slowdowns"];

  // packtype dropdown
  packtypeData = [];
  packtypeList = [];

  selectedPacktypes = [];
  concatenatedMachineIds = [];

  disableMachineDropdown: boolean;
  disablePacktypeDropdown: boolean;

  public overtime = null;

  constructor(
    private dataService: DataService,
    public selectionService: SelectionService,
    public targetBpmService: TargetBpmService
  ) {}

  async ngOnInit() {
    await this.onFetchData();
    await this.getAllPacktypes();
  }

  async onFetchAllOeeData() {
    this.showSlowdownRemainder = true;
    await this.concatenateMachineIds();

    // full OEE diagram
    const data = await this.dataService.getDataFor("oee-details", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    this.overtime = null;
    const dataOee = data
      .filter((item) => item.chartCat.includes("oee"))
      .filter(item => {
        if (item.label === 'overtime' && item.durationInMinutes < 0) {
          this.overtime = Math.round(-1.0 * item.durationInMinutes);
        } else {
          return true;
        }
      })
      .sort((a, b) => a.position - b.position);

    this.oeeDetails$.next(dataOee);

    // top 5 failures
    const top5 = data
      .filter((item) => item.chartCat.includes("top"))
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 5);
    this.topReasonsDetails$.next(top5);

    this.columnChartsData.push({
      data$: this.topReasonsDetails$,
      name: "TOP 5 Gründe",
      color: ChartColors["top"],
    });

    // setup details
    const setupdetails = {
      failureEvents: data.filter((item) =>
        item.chartCat.includes("setupfailure")
      ),
      setupEvents: data.filter(
        (item) =>
          item.chartCat.includes("setup") ||
          item.chartCat.includes("nonsetup") ||
          item.chartCat.includes("setupfilling")
      ),
    };
    this.setupDetails$.next(setupdetails);

    // slowdowns
    let slowdowns = await this.dataService.getDataFor("slowdowns", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    slowdowns = slowdowns
    .filter(slowdown => slowdown.event !== 'noTargetBpmAssigned' )
    .map((slowdown) => {
      if (slowdown.event === "notIdentified")
        slowdown.event = "Nicht identifizierte Events";
      slowdown.actualSlowdownDuration = slowdown.slowDownOeeMinutes
        ? slowdown.durationInMinutes - slowdown.slowDownOeeMinutes
        : slowdown.durationInMinutes;
      return slowdown;
    });

    // this is complete velocity loss from oee chart including slowdown events time
    const slowdownsRemainderOeeChart = data.find(
      (item) =>
        item.event === "slowdownremainder"
    );

    let slowdownDurationDifference = 0;
    let slowdownDurationDifferenceOee  = 0;
    // if overall velocity losses
    if (slowdownsRemainderOeeChart) {

      const slowdownOveralltime = slowdownsRemainderOeeChart.durationInMinutes;
      const summedUpSlowDownTimeFromSlowDownEvents = slowdowns
        .reduce((acc, curr) => acc + curr.actualSlowdownDuration, 0);
      slowdownDurationDifference = slowdownOveralltime - summedUpSlowDownTimeFromSlowDownEvents;

      const slowdownsOeeChart = data.find(
        (item) =>
          item.event === "OEE"
      );
      const summedUpOeeTimeFromSlowDownEvents = slowdowns.reduce((acc, curr) => acc + (curr.slowDownOeeMinutes ? curr.slowDownOeeMinutes : 0 ), 0);
      slowdownDurationDifferenceOee = slowdownsOeeChart ? (slowdownsOeeChart.durationInMinutes - summedUpOeeTimeFromSlowDownEvents) : 0;

    }



    slowdowns = slowdowns
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 10);

    if (slowdownDurationDifference > 0 || slowdownDurationDifferenceOee > 0 ) {
      slowdowns.unshift({
        event: "GV ohne Zuordnung",
        actualSlowdownDuration: slowdownDurationDifference,
        slowDownOeeMinutes: slowdownDurationDifferenceOee,
        hidden: false,
      });
    }

    this.slowdowns$.next(slowdowns);

    // shortstops
    let shortstops = await this.dataService.getDataFor("shortstop-failures", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    shortstops = shortstops
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 10);

    this.shortstopFailures$.next(shortstops);
    this.columnChartsData.push({
      data$: this.shortstopFailures$,
      name: "Kurzstopps",
      color: ChartColors["shortstops"],
    });

    // technical failures
    let technical = await this.dataService.getDataFor("technical-failures", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    technical = technical
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 10);

    this.technicalFailures$.next(technical);
    this.columnChartsData.push({
      data$: this.technicalFailures$,
      name: "Technische Ausfälle",
      color: ChartColors["ta"],
    });

    // adjustment work failures
    let adjustmentWork = await this.dataService.getDataFor("adjustment-work", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    adjustmentWork = adjustmentWork
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 10);

    this.adjustmentWorkFailures$.next(adjustmentWork);
    this.columnChartsData.push({
      data$: this.adjustmentWorkFailures$,
      name: "Justierarbeiten",
      color: ChartColors["ja"],
    });

    // no oil failures
    let noOilFailures = await this.dataService.getDataFor("no-oil", {
      start: this.selectionService.selectedDates[0],
      end: this.selectionService.selectedDates[1],
      machineIds: this.concatenatedMachineIds,
    });
    noOilFailures = noOilFailures
      .sort((a, b) => b.durationInMinutes - a.durationInMinutes)
      .slice(0, 10);

    this.noOilFailures$.next(noOilFailures);
    this.columnChartsData.push({
      data$: this.noOilFailures$,
      name: "Kein Öl",
      color: ChartColors["oil"],
    });
  }

  async toggleShowRemainder() {
    const slowdowns = JSON.parse(JSON.stringify(this.slowdowns$.getValue()));
    const remainder = slowdowns.find(
      (slowdown) => slowdown.event === "GV ohne Zuordnung"
    );
    if (remainder) {
      remainder.hidden = !this.showSlowdownRemainder;
      this.slowdowns$.next(slowdowns);
    }
  }

  // packtype data for dropdown
  async getAllPacktypes() {
    const packtypeData = await this.targetBpmService.getPackTypes();

    Object.entries(packtypeData).forEach((packtype) => {
      this.packtypeList.push({
        label: packtype[0],
        value: packtype[1],
      });
    });

    if (this.selectionService.selectedMachines.length > 0) {
      this.disablePacktypeDropdown = true;
    } else {
      this.disablePacktypeDropdown = false;
    }
  }

  onChangeMachineDropdown(event) {
    this.selectionService.selectedMachinesChanged(event);
    if (this.selectionService.selectedMachines.length > 0) {
      this.disablePacktypeDropdown = true;
    } else {
      this.disablePacktypeDropdown = false;
    }
  }

  onChangePacktypeDropdown() {
    if (this.selectedPacktypes.length > 0) {
      this.disableMachineDropdown = true;
    } else {
      this.disableMachineDropdown = false;
    }
  }

  async concatenateMachineIds() {
    this.concatenatedMachineIds = this.selectionService.selectedMachines.concat.apply(
      this.selectionService.selectedMachines,
      this.selectedPacktypes
    );
  }

  async onFetchData() {
    this.columnChartsData = [];
    try {
      this.dataAvailable = true;
      this.dataCurrentlyLoading$.next(true);
      await this.onFetchAllOeeData();
      this.dataCurrentlyLoading$.next(false);
    } catch (err) {
      console.log("CANNOT FETCH DATA", err);
    }
  }
}
