export enum PackType {
  MEDIUM = 'medium',
  INTERMEDIATE = 'intermediate',
  SMALL = 'small',
  LARGE = 'large',
  IBC = 'ibc'
}

export class Packaging {
  constructor(public _id: string,
              public name: string,
              public amountInLiters: number,
              public unitsPerContainer: number,
              public packType: PackType,
              public createdAt: Date,
              public updatedAt: Date) {

  }
}
