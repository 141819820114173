import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import {Sku} from '../models/sku';
import {Po} from '../models/po';
import {Daystats} from '../models/daystats';

@Injectable()
export class DaystatsService {

  dataAPIEndpoint = environment.APIEndpoint + '/daystats';
  data: any;

  constructor(private http: HttpClient) { }


  getDaystats(startDate, endDate, machineIds: string []): Promise<Daystats[]> {
    if (!endDate) endDate = startDate;
    const body = {
      start: startDate,
      end: endDate,
      machineIds
    };
    return this.http.post<Daystats[]>(this.dataAPIEndpoint, body).toPromise();
  }
}
