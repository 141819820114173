<app-header-bar [title]="'Sample Outlook'"></app-header-bar>
<div class="subheader-menu">
  <label class="toggle">
    <input id="toggleswitch" type="checkbox" (change)="changeState()">
    <span class="roundbutton"></span>
  </label>
  <span class="">Einträge älter als 8 Stunden zeigen</span>
</div>
<div class="main-container p-grid p-col-12">
  <!-- Beginning of Stop Table  -->
  <p-table
    [columns]="columnsSampleOutlook"
    [value]="sampleOutlookData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-3">
        Stop Tabelle
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; let idx = index">
          <th [ngClass]="(idx === 3 ) ? 'p-col-2' : 'p-col-1'">
            {{ col.header }} 
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr *ngIf="rowData.setupType == 'stop' && rowData.clearance == null" style="background-color: #e6c229">
        <ng-container *ngIf="isDisplayed(rowData)">
          <td>{{ rowData.machine }}</td>
          <td>{{ rowData.start | date: "dd.MM.yyyy, HH:mm" }}</td>
          <td>{{ rowData.po }}</td>
          <td>{{ rowData.sku }}</td>
          <td>{{ rowData.mc }}</td>
          <td>{{ rowData.phase }}</td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
  <!-- End of Stop Table -->

  <!-- Beginning of No Stop Table  -->
  <p-table
    [columns]="columnsSampleOutlook"
    [value]="sampleOutlookData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-3">
        NoStop Tabelle
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; let idx = index">
          <th [ngClass]="(idx === 3 ) ? 'p-col-2' : 'p-col-1'">
            {{ col.header }} 
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr *ngIf="rowData.setupType == 'no-stop' && rowData.clearance == null && rowData.clearanceTimeStamp == null && rowData.clearanceFailureTimeStamp == null" style="background-color: #e6c229">
        <ng-container *ngIf="isDisplayed(rowData)">
          <td>{{ rowData.machine }}</td>
          <td>{{ rowData.start | date: "dd.MM.yyyy, HH:mm" }}</td>
          <td>{{ rowData.po }}</td>
          <td>{{ rowData.sku }}</td>
          <td>{{ rowData.mc }}</td>
          <td>{{ rowData.phase }}</td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
  <!-- End of No Stop Table -->
</div>
