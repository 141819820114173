import { Component, OnInit } from "@angular/core";
import { Sku } from "app/models/sku";
import { Packaging } from "app/models/packaging";
import { SkuService } from "app/services/sku.service";
import { PackagingService } from "app/services/packaging.service";
import { Machine } from "app/models/machine";
import * as moment from "moment";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: "app-sku-data",
  templateUrl: "./sku-data.component.html",
  styleUrls: ["./sku-data.component.scss"],
})
export class SkuDataComponent implements OnInit {
  skuData: Sku[];
  packagingData: Packaging[];
  packagingList = [];

  public machine: Machine;
  packaging: Packaging[];

  displayAddSkuDialog: boolean = false;
  displayEditSkuDialog: boolean = false;
  newSkuId;
  newSkuNumber;
  newSkuDescription;
  newSkuMastercode;
  newSkuComponentNumber;
  newSkuPackaging;
  newSkuActive: boolean = true;
  editedSkuId;
  newSkuValidFrom;

  public showInactiveSkus = false

  columnsSkuTable = [
    { field: "skuNumber", header: "SKU Nummer " },
    { field: "description", header: "Beschreibung" },
    { field: "mastercode", header: "Mastercode" },
    { field: "componentNumber", header: "Komponentennummer" },
    { field: "packagingName", header: "Gebinde" },
    { field: "validFromFormatted", header: "Gültig seit" }
  ];

  public booleanDropDownOptions = [
    { label: "Ja", value: true },
    { label: "Nein", value: false },
  ];

  constructor(
    private skuService: SkuService,
    private packagingService: PackagingService,
    private confirmationService: ConfirmationService
  ) {
    // this.role = this.authService.role;
  }

  async ngOnInit() {
    await this.getSkus();
    this.packagingData = await this.packagingService.getPackagings();
  }

  public showDisplayAddSkuDialog() {
    this.displayAddSkuDialog = true;
  }

  public clearNewSku() {
    this.newSkuDescription = null;
    this.newSkuMastercode = null;
    this.newSkuNumber = null;
    this.newSkuComponentNumber = null;
    this.newSkuPackaging = null;
    this.newSkuActive = true;
  }

  public async getSkus() {
    const skus: any[] = await this.skuService.getSkus();
    const packagingListOptions = {};
    this.skuData = skus
      .filter((sku: Sku) => {
        return this.showInactiveSkus ? sku : sku.active
      })
      .map((sku: Sku) => {
      sku["packagingName"] = sku.packaging?.name;
      sku["activeTranslation"] = sku.active ? "Ja" : "Nein";
      sku["validFromFormatted"] = moment(sku.validFrom).format(
        "DD.MM.YYYY HH:mm:ss"
      );
      if (!packagingListOptions[sku["packagingName"]]) {
        packagingListOptions[sku["packagingName"]] = {
          label: sku["packagingName"],
          value: sku["packagingName"],
        };
      }
      return sku;
    });
    this.packagingList = [{ label: "Alle", value: null }];
    for (const key in packagingListOptions) {
      if (packagingListOptions.hasOwnProperty(key)) {
        this.packagingList.push(packagingListOptions[key]);
      }
    }
  }

  onEditSku(sku: Sku) {
    this.displayEditSkuDialog = true;
    this.newSkuId = sku._id;
    this.newSkuNumber = sku.skuNumber;
    this.newSkuDescription = sku.description;
    this.newSkuMastercode = sku.mastercode;
    this.newSkuComponentNumber = sku.componentNumber;
    this.newSkuPackaging = sku.packaging._id;
    this.newSkuActive = sku.active;
    this.newSkuValidFrom = sku.validFrom;
  }

  onChangeSkuActive(sku: Sku) {
    this.confirmationService.confirm({
      message: 'Soll diese Sku wirklich deaktiviert werden?',
      accept: async () => {
        await this.skuService.changeSkuActiveState(sku);
        await this.getSkus();
      },
      key: "skuConfirmDialog"
    })
  }


  // save new sku

  async saveSku() {
    const packaging: Packaging = this.packagingData.find((pg) => {
      return pg._id === this.newSkuPackaging;
    });
    const newSku = new Sku(
      this.newSkuId,
      this.newSkuDescription,
      this.newSkuMastercode,
      this.newSkuNumber,
      this.newSkuComponentNumber,
      packaging,
      null,
      null,
      this.newSkuValidFrom,
      this.newSkuActive
    );

    await this.skuService.postSku(newSku);
    this.clearNewSku();
    await this.getSkus();
    this.displayAddSkuDialog = false;
    this.displayEditSkuDialog = false;
  }

  // save edited sku

  async saveEditedSku() {
    const newSku = new Sku(
      this.newSkuId,
      this.newSkuDescription,
      this.newSkuMastercode,
      this.newSkuNumber,
      this.newSkuComponentNumber,
      this.newSkuPackaging,
      null,
      null,
      this.newSkuValidFrom,
      this.newSkuActive
    );

    await this.skuService.editSku(newSku);
    this.clearNewSku();
    await this.getSkus();
    this.displayEditSkuDialog = false;
  }
}
