import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Machine} from '../models/machine';
import { SpecialDay } from '../models/special-days';

@Injectable()
export class OperationTimesService {

  dataAPIEndpoint = environment.APIEndpoint + '/configure';
  data: any;

  constructor(private http: HttpClient) {
  }

  getDefaultWeekForMachine(machineId: string) {
    const params = new HttpParams().append('machine', machineId);
    return this.http.get<any[]>(this.dataAPIEndpoint + '/get-default-week', {params: params}).toPromise();
  }

  getSpecialDays(machineId: string) {
      const params = new HttpParams().append('machine', machineId);
      return this.http.get<SpecialDay[]>(this.dataAPIEndpoint + '/get-special-days', {params: params}).toPromise();
  }

  setSpecialDay(newSpecialDay: SpecialDay) {
    return this.http.post(this.dataAPIEndpoint + '/set-special-day', newSpecialDay).toPromise();
  }

  removeSpecialDay(body) {
    return this.http.post(this.dataAPIEndpoint + '/remove-special-day', body).toPromise();
  }
}
