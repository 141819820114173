import {Pipe, PipeTransform} from '@angular/core';
import { SkuService } from '../services/sku.service';


@Pipe({
  name: 'skuToSkuDescription'
})
export class SkuToSkuDescriptionPipe implements PipeTransform {

    constructor( private skuService: SkuService){}

    async transform(sku: string): Promise<string> {
        const skuToTransform = await this.skuService.getSkuByNumber(sku);
        return skuToTransform ? skuToTransform.description : sku;
      }
}
