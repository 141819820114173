<div class="p-d-flex p-flex-column p-jc-center p-ai-center" [style]="{height: '100vh'}">
  <h1>OEE Cockpit Login </h1>
  <!-- formBox -->
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center form-box">
    <form  (ngSubmit)="submitLogin()">
      <h5>Bitte Login-Daten eingeben.</h5>
      <div class="p-inputgroup p-mb-2">
        <span class="p-inputgroup-addon"><i class="fa fa-user p-d-flex p-jc-center"></i></span>
        <input type="text" pInputText placeholder="Nutzername" name="usernameFieldMustBeHere" [(ngModel)]="usernameInput">
      </div>

      <div class="p-inputgroup p-mb-3">
        <span class="p-inputgroup-addon"><i class="fa fa-key"></i></span>
        <input type="password" pInputText placeholder="Passwort" name="passwordFieldMustBeHere" [(ngModel)]="passwordInput">
      </div>
      <p-button type="submit" label="Login"></p-button>
    </form>
  </div>
</div>
