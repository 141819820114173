<app-header-bar [title]="'Event Übersicht'"></app-header-bar>
<div class="subheader-menu">
  <span>Anlage</span>
  <p-dropdown
    [options]="selectionService.machineList"
    [ngModel]="selectionService.forMachine"
    (ngModelChange)="selectionService.forMachineChanged($event)"
    placeholder="Anlage wählen"
  >
  </p-dropdown>
  <span class="">Zeitraum</span>
  <p-calendar
    dateFormat="dd.mm.yy"
    [(ngModel)]="selectionService.selectedDates"
    selectionMode="range"
    [maxDate]="selectionService.today"
    [defaultDate]="selectionService.today"
    [locale]="selectionService.de"
    placeholder="Heute"
    class="horizontal-margin"
  ></p-calendar>
  <p-button
    [disabled]="!selectionService.forMachine"
    (click)="fetchData(true)"
    label="Events holen"
  >
  </p-button>
  <p-button
    *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)"
    (click)="onAddNewEvent()"
    label="Neues Event"
  >
  </p-button>
</div>

<div class="main-container">

  <div class="eventfilter">
    <div class="selectinline" >
        <h5>Kategorie 1. Ebene</h5>
        <select
          [formControl]="firstLevelFormControl"
          (change)="filterSelectionChanged()"
        >
          <ng-container *ngFor="let item of firstLevelList  ">
            <option [value]="item._id">
              {{ item.label }}
            </option>
          </ng-container>
        </select>

    </div>

    <div class="selectinline">
      <h5>Kategorie 2. Ebene</h5>
      <select
        [formControl]="secondLevelFormControl"
        (change)="filterSelectionChanged()"
        #selectsecond
      >
        <ng-container *ngFor="let item of secondLevelList  ">
          <option [value]="item._id">
            {{ item.label }}
          </option>
        </ng-container>
      </select>
    </div>

    
    <div class="selectinline">
      <h5>Kategorie 3. Ebene</h5>
    <select
    [formControl]="thirdLevelFormControl"
    (change)="filterSelectionChanged()"
  >
    <ng-container *ngFor="let item of thirdLevelList  ">
      <option [value]="item._id">
        {{ item.label }}
      </option>
    </ng-container>
  </select>
    </div>

  </div>


    <ng-container *ngIf="dataAvailable$ && eventsToDisplay?.length > 0">

      <p-table
        #dt
        [columns]="columnsEventTable"
        [value]="eventsToDisplay"
        [paginator]="eventsToDisplay?.length > 15"
        [rows]="15"
        [autoLayout]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>
              Event
              <p-dropdown
                [disabled] = "firstLevelFormControl.value != ''"
                [ngModel]="selectedEventFilter"
                name="eventFilterForm"
                [options]="eventList"
                [style]="{ width: '100%' }"
                (onChange)="filter($event.value)"
                appendTo="body"
              >
              </p-dropdown>
            </th>
            <th>Anlage</th>
            <th>Beginn</th>
            <th>Ende</th>
            <th>Dauer</th>
            <th>Phase</th>
            <th>FillingID</th>
            <th>Geschw.verlust</th>
            <th></th>
            <th *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
              Bearbeiten
            </th>
            <th
              class="multi"
              *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)"
            >
              <div>Multi</div>

              <div *ngIf="getDeleteSelectionLength()">
                <img
                  class="cursor-pointer p-pr-2"
                  (click)="displayModalEditMany = true"
                  src="./assets/icons/pencil.svg"
                />
                <img
                  [ngClass]="{ 'cursor-pointer': true }"
                  src="./assets/icons/delete.svg"
                  (click)="displayModalDeleteMany = true"
                />
              </div>
            </th>
            <th *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
              Geplante Abschaltung
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
          <tr class="p-text-center" [pSelectableRow]="rowData">
            <td>
              {{
                rowData.eventDescription
                  ? (rowData.eventDescription.label | processPhaseLabels)
                  : "Nicht identifiziert"
              }}
            </td>
            <td>{{ rowData.machine.name }}</td>
            <td>{{ rowData.startTimestamp | date: "dd.MM.yyyy, HH:mm" }}</td>
            <td>{{ rowData.endTimestamp | date: "HH:mm" }}</td>
            <td>{{ rowData.durationInSeconds | secondsToHours }}</td>
            <td>{{ rowData.processPhase | processPhaseLabels }}</td>
            <td [title]="rowData.fillingProcess">
              {{ rowData.fillingProcess | fillingProcessPipe }}
            </td>

            <td>{{ rowData.isSlowdown | booleanToLabel }}</td>
            <td>
              <p-button
                class="icon-button"
                *ngIf="rowData.comment"
                icon="pi pi-comment"
                (click)="onViewCommentClick(rowData)"
              >
              </p-button>
            </td>
            <td *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
              <img
                class="cursor-pointer p-pr-2"
                (click)="onEditInit(rowData)"
                src="./assets/icons/pencil.svg"
                alt=""
              />
              <img
                class="cursor-pointer"
                (click)="onDeleteInit(rowData)"
                src="./assets/icons/delete.svg"
                alt=""
              />
            </td>
            <td>
              <p-checkbox
                name="multidelete"
                binary="true"
                [(ngModel)]="multiDeleteSelection[rowData._id]"
              ></p-checkbox>
            </td>

            <td>
              <div 
                *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)"
                class="p-d-flex p-dir-row p-jc-evenly">
                <img
                  class="cursor-pointer"
                  [src]="
                    rowData.isPlannedInterruption
                      ? './assets/icons/toggle-on.svg'
                      : './assets/icons/toggle-off.svg'
                  "
                  (click)="toggleActive(rowData._id)"
                  alt=""
                />
                </div>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </ng-container>

  <ng-container
    *ngIf="dataAvailable$ && eventsData?.length == 0"
    class="no-data"
  >
    <div class="no-data">
      <p>
        Für den gewählten Zeitraum sind keine Ereignisse für die Maschine
        gespeichert.
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="!dataAvailable$">
    <div class="no-data">
      <p>
        Bitte wählen Sie Zeitaum und Maschine aus, für die die Events angezeigt
        werden sollen.
      </p>
    </div>
  </ng-container>
</div>

<p-dialog
  header="Kommentar"
  [(visible)]="displayComment"
  [modal]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [minY]="70"
  [baseZIndex]="10000"
  dismissableMask="true"
>
  <span>{{ commentForEvent }}</span>
</p-dialog>

<p-dialog
  header="Löschen?"
  [(visible)]="displayModalDeleteMany"
  [modal]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [minY]="70"
  [baseZIndex]="10000"
  dismissableMask="true"
>
  <span
    >Wollen Sie wirklich {{ getDeleteSelectionLength() }} Events löschen?</span
  >

  <p-footer>
    <div class="p-d-flex p-jc-end">
      <div>
        <button
          pButton
          type="button"
          icon="fa fa-check"
          (click)="deleteMultipleItems()"
          label="Ja"
        ></button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="Umbuchung"
  [(visible)]="displayModalEditMany"
  [modal]="true"
  [style]="{ width: '950px', minWidth: '200px' }"
  [minY]="70"
  [baseZIndex]="10000"
  dismissableMask="true"
>
  <span>Für Auswahl zu buchendes Event:</span>

  <div class="p-field p-grid">
    <div class="p-col-5">
      <select
        name="addMultiEvent"
        required
        [(ngModel)]="newMultiEventDescription"
      >
        <ng-container *ngFor="let description of alphLocalSortedEvents">
          <option [value]="description.id">
            {{ description.processLabel }}
          </option>
        </ng-container>
      </select>
    </div>
  </div>

  <p-footer>
    <div class="p-d-flex p-jc-end">
      <div>
        <p-button
          [disabled]="!newMultiEventDescription"
          type="button"
          (click)="submitMultiEdit()"
          >OK</p-button
        >
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="Event bearbeiten"
  [(visible)]="displayDialog"
  showEffect="fade"
  [modal]="true"
  class="events-dialog"
  styleClass="width-800 overflow-auto"
  dismissableMask="true"
  (onHide)="clearEditEvent()"
>
  <form #editEventForm="ngForm">
    <div *ngIf="selectedEvent">
      <div class="p-field p-grid">
        <label class="p-col-3" for="machine">Anlage</label>
        <div class="p-col">
          <select
            id="machine"
            name="machine"
            [disabled]="true"
            required
            [(ngModel)]="editEventMachineName"
            (change)="onChangeMachineForExistingEvent($event)"
          >
            <ng-container *ngFor="let machine of machines">
              <option [value]="machine.name">
                {{ machine.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-3" for="event">Event</label>
        <div class="p-col-5">
          <select
            id="event"
            name="event"
            required
            (change)="isProcessPhase($event)"
            [(ngModel)]="eventNameForEditing"
          >
            <ng-container *ngFor="let description of alphLocalSortedEvents">
              <option [value]="description.name">
                {{ description.processLabel }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-3" for="start">Beginn</label>
        <div class="p-col">
          <p-calendar
            id="start"
            name="start"
            required
            dateFormat="dd.mm.yy"
            placeholder="{{ selectedEvent.startTimestamp }}"
            [(ngModel)]="selectedStartDate"
            [showTime]="true"
            [locale]="selectionService.de"
            [maxDate]="today"
            styleClass="small-calendar"
            (onSelect)="changedStart = true"
            appendTo="body"
          >
          </p-calendar>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-3" for="end">Ende</label>
        <div class="p-col">
          <p-calendar
            id="end"
            name="end"
            required
            dateFormat="dd.mm.yy"
            placeholder="{{ selectedEvent.endTimestamp }}"
            [(ngModel)]="selectedEndDate"
            [showTime]="true"
            [locale]="selectionService.de"
            [maxDate]="today"
            [minDate]="selectedStartDate"
            styleClass="small-calendar"
            (onSelect)="changedEnd = true"
            appendTo="body"
          >
          </p-calendar>
        </div>
      </div>

      
    <div class="p-field p-grid">
      <label class="p-col-3" for="addFillingMod">Füllvorgang</label>
      <div class="p-col">
        <select
          id="addFillingMod"
          name="addFillingMod"
          [(ngModel)]="newFillingProcessId"
          [disabled]="!(selectedStartDate && selectedEndDate)"
        >
          <ng-container *ngFor="let fillingProcess of fillingProcesses">
            <option [value]="fillingProcess._id">
              {{ fillingProcess.startTimestamp | date: "d.L./HH:mm" }} -
              {{ fillingProcess.endTimestamp | date: "d.L./HH:mm" }} &nbsp;
              {{ fillingProcess._id }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

      <div class="p-field p-grid">
        <label class="p-col-3" for="phase">Phase</label>
        <div class="p-col">
          <select
            id="phase"
            name="phase"
            required
            [(ngModel)]="selectedEvent.processPhase"
            [disabled]="selectedEventIsProcessPhase"
          >
            <ng-container *ngFor="let description of eventDescriptions">
              <option
                *ngIf="description.isProcessPhase === true"
                [value]="description.name"
              >
                {{ description.name | processPhaseLabels }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-3" for="comment">Kommentar</label>
        <div class="p-col-5">
          <textarea
            pInputTextarea
            id="comment"
            name="comment"
            [(ngModel)]="selectedEvent.comment"
            [rows]="5"
            [cols]="30"
          >
          </textarea>
        </div>
      </div>
    </div>

    <p-footer>
      <div class="p-d-flex p-jc-end">
        <div>
          <button
            pButton
            type="button"
            [disabled]="stillLoading || !editEventForm.valid"
            icon="fa fa-check"
            (click)="save()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>

<p-dialog
  header="Event hinzufügen"
  [(visible)]="displayAddNewDialog"
  showEffect="fade"
  [modal]="true"
  class="events-dialog"
  dismissableMask="true"
  styleClass="width-800 overflow-auto"
  (onHide)="clearNewEvent()"
>
  <form #newEventForm="ngForm">
    <div class="p-field p-grid">
      <label class="p-col-3" for="addMachine">Anlage</label>
      <div class="p-col">
        <select
          [disabled]="true"
          id="addMachine"
          name="addMachine"
          required
          (change)="onChangeMachineForNewEvent($event)"
          [(ngModel)]="newEventMachineName"
        >
          <ng-container *ngFor="let machine of machines">
            <option [value]="machine.name">
              {{ machine.name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addEvent">Event</label>
      <div class="p-col-5">
        <select
          id="addEvent"
          name="addEvent"
          required
          (change)="isProcessPhase($event)"
          [(ngModel)]="newEventName"
        >
          <ng-container *ngFor="let description of alphLocalSortedEvents">
            <option [value]="description.name">
              {{ description.processLabel }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addStart">Beginn</label>
      <div class="p-col">
        <p-calendar
          id="addStart"
          name="addStart"
          required
          dateFormat="dd.mm.yy"
          [(ngModel)]="newEventStartDate"
          (ngModelChange)="onDateChange($event)"
          [showTime]="true"
          [locale]="selectionService.de"
          [maxDate]="selectionService.today"
          styleClass="small-calendar"
          (onSelect)="changedStart = true"
          appendTo="body"
        >
        </p-calendar>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addEnd">Ende</label>
      <div class="p-col">
        <p-calendar
          id="addEnd"
          name="addEnd"
          required
          dateFormat="dd.mm.yy"
          [(ngModel)]="newEventEndDate"
          (ngModelChange)="onDateChange($event)"
          [showTime]="true"
          [locale]="selectionService.de"
          [maxDate]="today"
          [minDate]="newEventStartDate"
          styleClass="small-calendar"
          (onSelect)="changedEnd = true"
          appendTo="body"
        >
        </p-calendar>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addFilling">Füllvorgang</label>
      <div class="p-col">
        <select
          id="addFilling"
          name="addFilling"
          [(ngModel)]="newFillingProcessId"
          [disabled]="!(newEventStartDate && newEventEndDate)"
        >
          <ng-container *ngFor="let fillingProcess of fillingProcesses">
            <option [value]="fillingProcess._id">
              {{ fillingProcess.startTimestamp | date: "d.L./HH:mm" }} -
              {{ fillingProcess.endTimestamp | date: "d.L./HH:mm" }} &nbsp;
              {{ fillingProcess._id }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addPhase">Phase</label>
      <div class="p-col">
        <select
          id="addPhase"
          name="addPhase"
          required
          [(ngModel)]="newEventProcessPhase"
          [disabled]="selectedEventIsProcessPhase"
        >
          <ng-container *ngFor="let description of eventDescriptions">
            <option
              *ngIf="description.isProcessPhase === true"
              [value]="description.name"
            >
              {{ description.name | processPhaseLabels }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-3" for="addComment">Kommentar</label>
      <div class="p-col-5">
        <textarea
          pInputTextarea
          id="addComment"
          name="AddComment"
          [(ngModel)]="newEventComment"
          [rows]="5"
          [cols]="30"
        >
        </textarea>
      </div>
    </div>

    <div class="p-d-flex p-jc-end">
      <div>
        <button
          type="button"
          pButton
          [disabled]="stillLoading || !newEventForm.valid"
          icon="fa fa-check"
          (click)="onSaveNewEvent()"
          label="Speichern"
        ></button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog
  header="Löschen bestätigen"
  [(visible)]="displayDeleteDialog"
  showEffect="fade"
  [modal]="true"
  styleClass="width-400 overflow-auto"
  dismissableMask="true"
>
  <div class="p-field p-grid">
    <label class="p-col-4" for="addEvent">Event:</label>
    <div class="p-col">
      {{
        eventToDelete?.eventDescription
          ? eventToDelete.eventDescription?.label
          : "Nicht identifiziert"
      }}
    </div>
  </div>

  <div class="p-field p-grid">
    <label class="p-col-4" for="addMachine">Anlage:</label>
    <div class="p-col">
      {{ eventToDelete?.machine?.name }}
    </div>
  </div>

  <div class="p-field p-grid">
    <label class="p-col-4" for="addStart">Beginn:</label>
    <div class="p-col">
      {{
        eventToDelete?.startTimestamp
          ? (eventToDelete?.startTimestamp | date: "dd.MM.yyyy, HH:mm")
          : "-"
      }}
    </div>
  </div>

  <div class="p-field p-grid">
    <label class="p-col-4" for="addEnd">Ende:</label>
    <div class="p-col">
      {{
        eventToDelete?.endTimestamp
          ? (eventToDelete?.endTimestamp | date: "dd.MM.yyyy, HH:mm")
          : "-"
      }}
    </div>
  </div>

  <div class="p-field p-grid">
    <label class="p-col-4" for="addPhase">Phase:</label>
    <div class="p-col">
      {{ eventToDelete?.processPhase | processPhaseLabels }}
    </div>
  </div>

  <div class="p-field p-grid">
    <label class="p-col-4" for="addComment">Kommentar:</label>
    <div class="p-col">
      {{ eventToDelete?.comment ? eventToDelete?.comment : "-" }}
    </div>
  </div>

  <p-footer>
    <div class="p-d-flex p-jc-end">
      <div>
        <button
          pButton
          type="button"
          icon="fa fa-check"
          (click)="onDeleteEvent()"
          label="Löschen"
        ></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
