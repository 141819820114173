import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '../../../utils';
import * as moment from 'moment';

export enum ConnectionErrorStatus  {
  NO_SOCKET_CONNECTION = 'keine Socket-Verbindung zum Werksserver',
  NO_DI_CONNECTION = 'keine Verbindung zum Digital Input'
}

@Component({
  selector: 'app-machine-data-component',
  templateUrl: './machine-data.component.html',
  styleUrls: ['./machine-data.component.scss']
})
export class MachineDataComponent implements OnInit {

  @Input('machineName') machineName: string;
  @Input('machineType') machineType: number;
  @Input('currentBpm') currentBpm: number;
  @Input('defaultMaxBpm') defaultMaxBpm: number;
  @Input('connectionErrorStatus') connectionErrorStatus: ConnectionErrorStatus;
  @Input() eventLabel: string;
  @Input('eventColor') eventColor: string;
  @Input('quantity') quantity?: { actualAmountToday: number, rawAmountFillingProcessInUnits: number, targetAmountPoInUnits: number, targetAmountToday: number };
  @Input('lastEventTimestamp') lastEventTimestamp: any;
  @Input() unitsPerContainer: number;

  lastStopTimer: any;

  displayTime: any;

  public CES = ConnectionErrorStatus;

  constructor() {
  }

  ngOnInit() {
    this.initTimer();
  }

  initTimer() {
    this.lastStopTimer = setInterval(this.updateTimer.bind(this), 1000);
  }

  updateTimer() {
    const timeSinceLastStop = Utils.getSecondsSince(moment(this.lastEventTimestamp));
    this.displayTime = Utils.toTimerFormat(timeSinceLastStop);
  }

  getButtonClass(state) {
    return [`${state}-button`, 'status'];
  }

  getColorClass(state) {
    return `${state}-text`;
  }

}
