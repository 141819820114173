import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'booleanToLabel'
})
export class BooleanToLabelPipe implements PipeTransform {
  transform(value: boolean) {
    if (value) {
      return 'Ja';
    } else {
      return 'Nein';
    }

  }
}
