<div class="p-d-flex p-flex-row p-jc-center p-pb-2">
  <p-button 
    (click)="showDisplayAddPackagingDialog()"
    *ngIf="[Role.ADMIN].includes(role)"
  >
    Neues Gebinde
  </p-button>
</div>
<p-table
  #packaging
  *ngIf="packagingData"
  [columns]="columnsPackagingTable"
  [value]="packagingData"
  [paginator]="packagingData?.length > 15"
  [rows]="15"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- add packaging dialog -->

<p-dialog
  header="Neues Gebinde hinzufügen"
  [(visible)]="displayAddPackagingDialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <div>
    <form (ngSubmit)="savePackaging()" #packagingForm="ngForm">
      <div class="p-field p-grid">
        <label class="p-col-5" for="packagingName">Name</label>
        <div class="p-col">
          <input
            class="form-control"
            id="packagingName"
            name="packagingName"
            type="text"
            pInputText
            required
            [(ngModel)]="newPackagingName"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="packagingAmount">Menge in Liter</label>
        <div class="p-col">
          <input
            class="form-control"
            id="packagingAmount"
            name="packagingAmount"
            type="number"
            pInputText
            required
            [(ngModel)]="newPackagingAmount"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="unitsPerContainer">Gebindeeinheit</label>
        <div class="p-col">
          <input
            class="form-control"
            id="unitsPerContainer"
            name="unitsPerContainer"
            type="number"
            pInputText
            required
            [(ngModel)]="newPackagingUnitsPerContainer"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="packType">Packtyp</label>
        <div class="p-col">
          <select
            id="packType"
            name="packType"
            required
            [(ngModel)]="newPackagingPackType"
          >
            <option value="small">small</option>
            <option value="medium">medium</option>
            <option value="intermediate">intermediate</option>
            <option value="large">large</option>
            <option value="ibc">ibc</option>
          </select>
        </div>
      </div>      
        <div class="p-d-flex p-jc-end">
          <div>
            <button 
              type="button"
              pButton
              [disabled]="!packagingForm.valid"
              icon="fa fa-check"
              (click)="savePackaging()"
              label="Speichern"
            ></button>
          </div>
        </div>
    </form>
  </div>
</p-dialog>
