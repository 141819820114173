import {Component, OnDestroy, OnInit} from "@angular/core";
import { NoStopScreenService } from "../../services/no-stop-screen.service";

@Component({
  selector: "app-nostop-screen",
  templateUrl: "./no-stop-screen.component.html",
  styleUrls: ["./no-stop-screen.component.scss"],
})
export class NoStopScreenComponent implements OnInit, OnDestroy {
  noStopData= [];
  private updateInterval;
  public columnsNoStopScreen = [
    { field: "start", header: "Füllbeginn" },
    { field: "machine", header: "Anlage" },
    { field: "sku", header: "SKU" },
    { field: "skudescription", header: "Beschreibung" },

    { field: "clearance", header: "Freigabestatus" },
    { field: "clearanceTimeStamp", header: "Freigabezeit" },
  ];

  constructor(
    private noStopScreenService: NoStopScreenService,
  ){ }

  async ngOnInit() {
    await this.fetchData();
    this.updateInterval = setInterval(this.fetchData.bind(this), 10000);

  }

  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }

  async fetchData() {
    this.noStopData = await this.noStopScreenService.getNoStopData();
  }

}
