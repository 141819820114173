import { Packaging } from './packaging';

export class Machine {
  constructor(public _id: string,
              public name: string,
              public isActive: boolean,
              public DIport: string,
              public DIhost: string,
              public socketID: string,
              public restartDelayTime: number,
              public slowDownEventTime: number,
              public shortStopDuration: number,
              public machineType: number,
              public accumulateTimeFactor: number,
              public defaultMaxBpm: number,
              public defaultUnitsPerContainer: number,
              public createdAt: Date,
              public updatedAt: Date) {
  }
}
