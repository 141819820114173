import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "../../services/data.service";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";
import { MachineService } from "../../services/machine.service";
import { SelectionService } from "../../services/selection.service";

@Component({
  selector: "app-machine-performance-component",
  templateUrl: "machine-performance.component.html",
  styleUrls: ["machine-performance.component.scss"],
})
export class MachinePerformanceComponent implements OnInit, OnDestroy {
  public allDaysMachineData$ = new BehaviorSubject({});

  updateInterval;
  dataObject;
  public allMachines = {};

  constructor(
    public dataService: DataService,
    public machineService: MachineService,
    public selectionService: SelectionService
  ) {}

  async ngOnInit() {
    const currentMachineData = await this.machineService.getMachines();    
    currentMachineData.forEach((machine) => {
      this.allMachines[machine._id] = {
        name: machine.name,
        bpm: machine.defaultMaxBpm,
        type: machine.machineType,
      };
    });
    await this.fetchData();
  }

  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }

  // multi select
  async fetchData() {
    const startDate = moment(this.selectionService.selectedDates[0]);
    let endDate = moment(this.selectionService.selectedDates[1]);
    if (!endDate.isAfter(startDate)) {
      endDate = startDate.endOf("day");
    }

    const selectedMachines = this.selectionService.selectedMachines;

    const allMappedQuantities = {};

    for (let machine of selectedMachines) {
      const currentDataObject = await this.dataService.getMachinePerformanceData(
        startDate,
        endDate,
        machine
      );

      currentDataObject.days.forEach((day) => {
        const unitsPerContainer = currentDataObject.machine.defaultUnitsPerContainer ? currentDataObject.machine.defaultUnitsPerContainer : 1;
        const currentInBoxes = day.actualAmount ? Math.floor(day.actualAmount/unitsPerContainer) : null;
        const targetInBoxes = day.targetAmount ? Math.floor(day.targetAmount/unitsPerContainer) : null;
        const difference = currentInBoxes && targetInBoxes ? currentInBoxes - targetInBoxes : null;

        const machineDay = {
          timestamp: day.timestamp,
          quantity: { currentInBoxes, targetInBoxes, difference, unitsPerContainer },
          speedValues: day.speedValues,
        };
        console.log(machineDay.quantity)
        const currentDay = day.timestamp;

        if (!allMappedQuantities[currentDay]) {
          allMappedQuantities[currentDay] = {};
        }
        allMappedQuantities[currentDay][machine] = machineDay;
      });
    }

    this.allDaysMachineData$.next(allMappedQuantities);
  }
}
