<div class="header-bar">
  <div class="spacer"></div>
  <div class="title">
    {{title}}
  </div>
  <div  class="header-icon-container">
    <img *ngIf="!noBranding" class="logo" id="neuhof-logo" src="./assets/neuhof-logo.png"/>
    <img *ngIf="!noBranding" class="logo" id="castrol-logo" src="./assets/castrol-logo-round.png"/>
    <img *ngIf="!noBranding" class="logo" id="bp-logo" src="./assets/bp_logo.png"/>
  </div>
</div>
