import {Pipe, PipeTransform} from '@angular/core';
import {ProcessPhaseLabel} from '../models/process-phase';

@Pipe({
  name: 'processPhaseLabels'
})
export class ProcessPhaseLabelsPipe implements PipeTransform {
  transform(eventName) {
    return ProcessPhaseLabel[eventName] ? ProcessPhaseLabel[eventName] : eventName;
  }
}
