import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-live-amount-chart',
  template: '<div [chart]="chart"></div>',
})

export class LiveAmountChartComponent implements OnInit {
  chart: Chart;

  @Input()
  set data({ current, target }: { current: number, target: number, difference: number }) {
    if (this.chart.ref) {
      console.log(current, target);
      if (current > target) {
        this.chart.ref.series[0].data[0].update(current-target);
        this.chart.ref.series[1].data[0].update(target);
      } else {
        this.chart.ref.series[0].data[0].update(target-current);
        this.chart.ref.series[1].data[0].update(current);
      }
    } else {
      if (current > target) {
        this.chart.addSeries({
          type: 'bar',
          name: 'Über',
          data: [current - target],
          color: '#99CC00',
          stack: 'Menge'
        }, true, true);
        this.chart.addSeries({
          type: 'bar',
          name: 'Soll',
          data: [target],
          color: '#3a7248',
          stack: 'Menge'
        }, true, true);
      } else {
        this.chart.addSeries({
          type: 'bar',
          name: 'Soll',
          data: [target-current],
          color: '#CCCCCC',
          stack: 'Menge'
        }, true, true);
        this.chart.addSeries({
          type: 'bar',
          name: 'Ist',
          data: [current],
          color: '#3a7248',
          stack: 'Menge'
        }, true, true);
      }
    }
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'bar',
        width: 100,
        height: 50,
        margin: 0,
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      xAxis: {
        labels: {
          enabled: true
        }
      },
      yAxis: {
        categories: ['Menge'],
        gridLineWidth: 0
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: 'normal',
 
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.y} </b><br/>',
        followPointer: true
      },
      exporting: { enabled: false },
      series: []
    });
  }

  ngOnInit() {
  }
}
