import {FillingProcess} from './filling-process';
import {Sku} from './sku';

export class Po {
  constructor(public _id: string,
              public fillingProcesses: FillingProcess[],
              public poNumber: string,
              public targetAmountInUnits: number,
              public sku: Sku,
              public density: number,
              public startTimestamp: string,
              // private createdAt: Date,
              // private updatedAt: Date
  ) {
  }

}
