import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Utils} from '../../../utils';
import {ProcessPhaseLabel} from '../../../models/process-phase';

@Component({
  selector: 'app-detailed-failure-evaluation',
  template: '<div [chart]="chart"></div>',
})

export class DetailedFailureEvaluationChartComponent implements OnInit {

  chart: Chart;

  @Input() name: string;
  @Input() color: string;
  @Input()
  set data(data: any[]) {
    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }
    const converted = [];
    data.forEach((element: {
      event: string,
      durationInMinutes: number
    }) => {
      let label: string;
      if (ProcessPhaseLabel[element.event]) {
        label = ProcessPhaseLabel[element.event];
      } else {
        label = element.event;
      }
      converted.push([label, Utils.round(element.durationInMinutes, 2)]);
    });

    converted.sort((a, b) => b[1] - a[1]);

    this.chart.addSeries({
      type: 'column',
      name: this.name,
      data: converted,
      color: this.color
    }, true, true);
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        type: 'column',
        height: 220,
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          rotation: -30,
          useHTML: true,
          style: {
            width: 100,
            textAlign: 'right',
            lineHeight: '11px'
          },
        },
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Zeiten in Min'
        }
      },
      series: [],
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '{point.key}<br>',
        pointFormat: '{point.y:.2f}',
        style: {
          width: 130,
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
        },
      }
    });
  }

  ngOnInit() {
  }


}
