import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {CategoryInfoMap, EventCategory} from '../../../models/categories';
import {ProcessPhaseLabel} from '../../../models/process-phase';

import {convert} from 'color-convert';

@Component({
  selector: 'app-oee-evaluation-chart',
  template: '<div [chart]="chart"></div>'
})

export class OeeEvaluationChartComponent implements OnInit, AfterViewInit {

  chart: Chart;
  colors = [];
  title = '';
  summedUpTime = 0;

  @Input()
  public set data(data: {
    event: string,
    durationInMinutes: number,
    color: number[]
  }[]) {
    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
      while (this.colors.length > 0) {
        this.colors.pop();
      }
    }
    const converted = [];
    data.forEach(d => {
      let label = d.event;
      if (ProcessPhaseLabel[d.event]) label = ProcessPhaseLabel[d.event];
      converted.push([label, d.durationInMinutes]);
      this.summedUpTime = this.summedUpTime + d.durationInMinutes;
      if (d.color) {
        this.colors.push(`rgb(${d.color[0]}, ${d.color[1]}, ${d.color[2]}) ` ); //   CategoryInfoMap.get(d.event).color);
      }
    });
    this.colors.push( '#996666', '#4d1933', '#0086b3', '#b38f00', '#3a7864', '#b5d4e3', '#8a5c7a', '#669999', '#937f6c', '#666699');
   // this.title = `OEE\n ${((((converted[0][1]) / this.summedUpTime) * 100)).toFixed(1)}%`;

    this.chart.addSeries({
      type: 'pie',
      name: 'Prozent',
      innerSize: '50%',
      data: converted,
    }, true, true);

  }

  constructor() {
    this.chart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 200,
      },
      title: {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        x: -70,
        y: 5
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemMarginBottom: 2,
        itemStyle: {
          textOverflow: null
        }
      },
      plotOptions: {
        pie: {
          size: '100%',
          colors: this.colors,
          showInLegend: true,
          dataLabels: {
            enabled: true,
            distance: 5,
            format: '{point.percentage:.2f} %',
          },
          point: {
            events: {
              legendItemClick(): void {
                event.preventDefault();
              },
            }
          },
        },
      },
      tooltip: {
        pointFormat: '{point.y:.2f} min'
      },
      series: []
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    if (this.chart.ref) {
      this.chart.ref.setTitle({text: this.title}, null, true);
    }
  }
}
