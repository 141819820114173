import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable()

export class CollectionService {

  dataAPIEndpoint = environment.APIEndpoint + '/collections';
  data: any;

  constructor(private http: HttpClient) {
  }

  getDataFileBuffer(downloadPath: string, body: {
    startDate: string,
    endDate: string,
    machineIds: string[]
  }): Promise<any> {
    if (!body.endDate) body.endDate = body.startDate;
    return this.http.post(this.dataAPIEndpoint + '/' + downloadPath, body, {responseType: 'arraybuffer'}).toPromise();
  }
}
