import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SampleOutlookData } from "../models/sample-outlook-data";

@Injectable()
export class SampleOutlookService {
  private apiEndpoint = environment.APIEndpoint;
  data: any;
  dataAPIEndpoint = this.apiEndpoint + '/filling-process/sample-outlook';

  constructor(private http: HttpClient) {}

  getSampleOutlook() {
    return this.http.get<SampleOutlookData[]>(this.dataAPIEndpoint).toPromise();
  }
}
