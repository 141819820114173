import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Packaging} from '../models/packaging';
import {TargetBpm} from '../models/target-bpm';

@Injectable()
export class TargetBpmService {

  dataAPIEndpoint = environment.APIEndpoint + '/target-bpm';
  data: any;

  constructor(private http: HttpClient) { }


  getTargetBpms() {
    return this.http.get<any[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getTargetBpm(machineId: string, packagingId: string ) {
    const params = new HttpParams().append('machine', machineId).append('packaging', packagingId);
    return this.http.get(this.dataAPIEndpoint, {params: params});
  }

  postTargetBpm(targetBpm: TargetBpm) {
    return this.http.post<TargetBpm>(this.dataAPIEndpoint + '/', targetBpm).toPromise();
  }

  getPackTypes() {
    return this.http.get<any[]>(this.dataAPIEndpoint + '/packtypes').toPromise();
  }
}
