export enum ProcessPhase {
  SETUP = 'setup',
  BETWEEN_PO = 'between-po',
  SETUP_PACKAGING_CHANGE = 'setup-packaging-change',
  SETUP_WAIT = 'setup-wait',
  SETUP_LAB = 'setup-lab',
  UNEQUIP = 'unequip',
  RUNNING = 'running',
}

export const ProcessPhaseLabel = {};
ProcessPhaseLabel[ProcessPhase.BETWEEN_PO] = 'Zwischen Aufträgen';
ProcessPhaseLabel[ProcessPhase.SETUP] = 'Rüsten';
ProcessPhaseLabel[ProcessPhase.SETUP_WAIT] = 'Verz. Füllbeginn';
ProcessPhaseLabel[ProcessPhase.SETUP_LAB] = 'Freigabezeit';
ProcessPhaseLabel[ProcessPhase.UNEQUIP] = 'Abrüsten';
ProcessPhaseLabel[ProcessPhase.RUNNING] = 'Filling';
ProcessPhaseLabel['break'] = 'Pause';

