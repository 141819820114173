import { Component, OnInit } from "@angular/core";
import { TargetBpm } from "app/models/target-bpm";
import { TargetBpmService } from "app/services/target-bpm.service";
import { Machine } from "app/models/machine";
import { Packaging } from "app/models/packaging";
import { MachineService } from "app/services/machine.service";
import { PackagingService } from "app/services/packaging.service";
import { Role } from 'common/Auth/Role';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: "app-target-bpm",
  templateUrl: "./target-bpm.component.html",
  styleUrls: ["./target-bpm.component.scss"],
})
export class TargetBpmComponent implements OnInit {
  targetBpmData: TargetBpm[];
  packagingData: Packaging[];

  packagingList = [];
  machineList = [];
  machines: Machine[];

  displayAddTargetBpmDialog: boolean = false;
  public addButtonText: string;
  public newTargetBpmMachineId;
  public newTargetBpmPackaging;
  public newTargetBpm;

  columnsTargetBpmTable = [
    { field: "machineName", header: "Anlage" },
    { field: "packagingName", header: "Gebinde" },
    { field: "bpm", header: "Soll-BPM" },
  ];

  public role: Role;
  public Role = Role;  // otherwise not accessable in the template

  constructor(
    private targetBpmService: TargetBpmService,
    private machineService: MachineService,
    private packagingService: PackagingService,
    private authService: AuthService
  ) {
    this.role = this.authService.role;
  }

  async ngOnInit() {
    await this.getTargetBpms();
    this.packagingData = await this.packagingService.getPackagings();
    this.machines = await this.machineService.getMachines();
  }

  showDisplayAddTargetBpmDialog() {
    this.displayAddTargetBpmDialog = true;
  }

  clearAllNewItemData() {
    this.newTargetBpm = null;
    this.newTargetBpmMachineId = null;
    this.newTargetBpmPackaging = null;
  }

  async saveTargetBpm() {
    const machine: Machine = this.machines.find((m) => {
      return m._id === this.newTargetBpmMachineId;
    });
    const packaging: Packaging = this.packagingData.find((p) => {
      return p._id === this.newTargetBpmPackaging;
    });
    const targetBpm = new TargetBpm(
      null,
      this.newTargetBpm,
      machine,
      packaging,
      null,
      null
    );
    await this.targetBpmService.postTargetBpm(targetBpm);
    this.clearAllNewItemData();
    await this.getTargetBpms();
    this.displayAddTargetBpmDialog = false;
  }

  async getTargetBpms() {
    const targetBpm = await this.targetBpmService.getTargetBpms();
    const packagingListOptions = {};
    const machineListOptions = {};
    this.targetBpmData = targetBpm.map((t) => {
      t["packagingName"] = t.packaging?.name;
      t["machineName"] = t.machine ? t.machine.name : "";
      if (!packagingListOptions[t["packagingName"]]) {
        packagingListOptions[t["packagingName"]] = {
          label: t["packagingName"],
          value: t["packagingName"],
        };
      }
      if (!machineListOptions[t["machineName"]]) {
        machineListOptions[t["machineName"]] = {
          label: t["machineName"],
          value: t["machineName"],
        };
      }
      return t;
    });
    this.packagingList = [{ label: "Alle", value: null }];
    for (const key in packagingListOptions) {
      if (packagingListOptions.hasOwnProperty(key)) {
        this.packagingList.push(packagingListOptions[key]);
      }
    }
    this.machineList = [{ label: "Alle", value: null }];
    for (const key in machineListOptions) {
      if (machineListOptions.hasOwnProperty(key)) {
        this.machineList.push(machineListOptions[key]);
      }
    }
  }
}
