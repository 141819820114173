import {Packaging} from './packaging';

export class Sku {
  constructor(public _id: string,
              public description: string,
              public mastercode: number,
              public skuNumber: string,
              public componentNumber: string,
              public packaging: Packaging,
              public createdAt: Date,
              public updatedAt: Date,
              public validFrom: Date,
              public active: boolean) {
  }
}
