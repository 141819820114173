import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import {MachineService} from '../../services/machine.service';
import {DialogService} from 'primeng/dynamicdialog';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CollectionService} from '../../services/collection.service';
import { SelectionService } from 'app/services/selection.service';

export enum FileType  {
  JSON = 'json',
  ZIP = 'zip'
}

@Component({
  selector: 'app-collection-component',
  templateUrl: 'collection.component.html',
  styleUrls: ['collection.component.scss'],
  providers: [DialogService]
})

export class CollectionComponent implements OnInit {

  dataAPIEndpoint = environment.APIEndpoint;
  selectedDates = [this.selectionService.today];

  collections: { name: string, collection: string }[];
  zipCollections: { name: string, collection: string }[];

  private exportDateFormat = 'YYYY-MM-DD';
  public FileTypes = FileType;

  constructor(private machineService: MachineService,
              private http: HttpClient,
              private collectionService: CollectionService,
              public selectionService: SelectionService
  ) {

    this.collections = [{collection: 'daystats', name: 'Tagesprotokolle'},
      {collection: 'eventdescriptions', name: 'Eventbescheibungen'},
      {collection: 'events', name: 'Events'},
      {collection: 'failurecategories', name: 'Fehlerkategorien'},
      {collection: 'fillingprocesses', name: 'Abfüllprozesse'},
      {collection: 'machines', name: 'Maschinen'},
      {collection: 'packagings', name: 'Gebinde'},
      {collection: 'pos', name: 'POs'},
      {collection: 'skus', name: 'SKUs'},
      {collection: 'targetbpms', name: 'Soll-BPMs'},
      {collection: 'operationtimes', name: 'Werkzeiten'},
      //
      {collection: 'default-week', name: 'Standard-Wochen-Zeiten'},
      {collection: 'setup-types', name: 'Setup-Types'},
      {collection: 'special-day', name: 'Sondertage'},
      //
    ];
    this.zipCollections = [
      {collection: '_allStatic', name: 'all static data'},
      {collection: '_allDynamic', name: 'all dynamic data'},

    ];
  }

  static saveAsFile(buffer: any, fileName: string, fileType: FileType): void {
    let type = null;
    switch (fileType) {
      case FileType.ZIP: {
        type = {type: 'application/zip'};
        break;
      }
      default: {
        type = {type: 'application/json;charset=utf-8'};
      }
    }
    const data: Blob = new Blob([buffer], type);
    saveAs(data, fileName);
  }

  async ngOnInit() {
  }


  async downloadFile(downloadPath, fileType: FileType) {
    const startDate = moment(this.selectionService.selectedDates[0]).toISOString();
    const endDate = this.selectionService.selectedDates[1] ? moment(this.selectionService.selectedDates[1]).toISOString() : null;
    const dateStr = endDate ? moment(startDate).format(this.exportDateFormat) + '_' + moment(endDate).format(this.exportDateFormat)
      : moment(startDate).format(this.exportDateFormat);
    const res = await this.collectionService.getDataFileBuffer(downloadPath, {startDate, endDate, machineIds: this.selectionService.selectedMachines});

    try {
        CollectionComponent.saveAsFile(res, downloadPath + '_' + dateStr + '.' + fileType, fileType);
      } catch (err) {
      console.log(' problems with loading JSON or ZIP data from Server ', err);
    }
  }


}
