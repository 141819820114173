import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Role } from '../../../common/Auth/Role';
import { FillingProcess } from '../../models/filling-process';
import { Machine } from '../../models/machine';
import { Packaging } from '../../models/packaging';
import { Po } from '../../models/po';
import { Sku } from '../../models/sku';
import { TankType } from '../../models/tank-type';
import { AuthService } from '../../services/auth.service';
import { FillingProcessService } from '../../services/filling-process.service';
import { MachineService } from '../../services/machine.service';
import { PackagingService } from '../../services/packaging.service';
import { PoService } from '../../services/po.service';
import { SelectionService } from '../../services/selection.service';
import { SkuService } from '../../services/sku.service';
import { TankTypeService } from '../../services/tank-type.service';
import { FillingProcessOverviewComponent } from './filling-process-overview/filling-process-overview.component';

@Component({
  selector: "app-po-overview",
  templateUrl: "./po-overview.component.html",
  styleUrls: ["./po-overview.component.scss"],
  providers: [DialogService],
})
export class PoOverviewComponent implements OnInit {
  @ViewChild("form") form: NgForm;

  dataAvailable$ = new BehaviorSubject(false);

  pos: Po[];
  public posToDisplay: Po[];
  public displayDialog: boolean;
  selectedPo: Po;
  public editedPoNumber;
  public editedPoStartDate: Date;
  public selectedStartDate;
  editedTargetAmountInUnits;
  editedSku;
  editedDensity;
  public skus: Sku[];
  machineList = [];
  sortedMachineList = [];
  selectedMachine;
  public machines;
  public poExists = false;
  public existingPoStart;
  public existingPoTargetAmount;
  public existingPoSku;
  public existingPoUnitsPerContainer: number;
  public existingPoNumber;
  public existingPo: Po;

  public displayAddNewPoDialog = false;
  public newPoNumber: string;
  public newPoSku;
  public newPoDensity: number;
  public newPoAmount;
  public newPoStartDate: Date;

  public displayDeleteDialog = false;
  public poToDelete: Po;

  public displayAddNewFpDialog = false;
  public newFpStartDate;
  public newFpEndDate;
  public newFpActualAmount;
  public newFpMachineId;
  public newFpTankTypeId
  public newFpComment;
  public unitsPerContainer: number;

  public tankTypes: TankType[] = [];

  role: Role;
  public Role = Role;

  constructor(
    private poService: PoService,
    public dialogService: DialogService,
    private skuService: SkuService,
    private packagingService: PackagingService,
    private machineService: MachineService,
    private fillingProcessService: FillingProcessService,
    private authService: AuthService,
    public selectionService: SelectionService,
    private tanktypeService: TankTypeService
  ) {
    this.role = this.authService.role;
  }

  async ngOnInit() {
    this.tankTypes = await this.tanktypeService.getTankTypes().pipe(map(list => list.filter(item => item.isActive))).toPromise();
    await this.onFetchData();
  }

  async onFetchData() {
    const startDate = moment(
      this.selectionService.selectedDates[0]
    ).toISOString();
    const endDate = moment(
      this.selectionService.selectedDates[1]
    ).toISOString();
    this.pos = await this.poService.getPos(startDate, endDate);
    this.posToDisplay = this.pos;
    this.dataAvailable$.next(true);
    for (const po of this.pos) {
      po["actualAmount"] = 0;
      po.fillingProcesses.forEach((fp: FillingProcess) => {
        po["actualAmount"] += fp.rawAmountFillingProcessInUnits;
      });
    }
    this.machineList = Array.from(
      new Set(
        this.pos.map((po) => {
          if (po.fillingProcesses[0]) {
            return po.fillingProcesses[0].machine.name;
          }
        })
      )
    ).map((machineName) => {
      return {
        label: machineName,
        value: machineName,
      };
    });

    this.machineList.push({ label: "Alle Anlagen", value: null });
    this.machineList.sort((a, b) => {
      const aLabel = a.label ? a.label : '';
      const bLabel = b.label ? b.label : '';

      return aLabel.localeCompare(bLabel, undefined, { numeric: true });
    });
    this.filter();
  }

  selectMachine(selectedMachine) {
    this.selectedMachine = selectedMachine;
    this.filter();
  }

  filter() {
    if (!this.selectedMachine) {
      this.posToDisplay = this.pos;
    } else {
      this.posToDisplay = this.pos.filter((po) => {
        if (po.fillingProcesses[0]) {
          return po.fillingProcesses[0].machine.name === this.selectedMachine;
        }
      });
    }
  }

  onEditInit(po) {
    this.editedPoNumber = po.poNumber;
    this.editedSku = po.sku ? po.sku.skuNumber : null;
    this.editedDensity = po.density;
    this.editedPoStartDate = moment(po.startTimestamp).toDate();
    this.selectedPo = JSON.parse(JSON.stringify(po));
    this.displayDialog = true;
  }

  async searchPo(event) {
    if (event.length === 7) {
      this.existingPo = await this.poService.checkPo(event);
      if (this.existingPo) {
        this.poExists = true;
        this.existingPoNumber = this.existingPo.poNumber;
        this.existingPoStart = this.existingPo.startTimestamp;
        this.existingPoSku = this.existingPo.sku.skuNumber;
        this.existingPoTargetAmount = this.existingPo.targetAmountInUnits;
        this.existingPoUnitsPerContainer = this.existingPo.sku
          ? this.existingPo.sku.packaging.unitsPerContainer
          : 1;
      } else {
        this.poExists = false;
      }
    } else {
      this.poExists = false;
    }
  }

  async searchPoInAddNewPo(event) {
    if (event.length === 7) {
      const poFromDb = await this.poService.checkPo(event);
      this.poExists = !!poFromDb;
    } else {
      this.poExists = false;
    }
  }

  onAddNewPo() {
    this.newPoStartDate = this.selectionService.selectedDates[0];
    this.displayAddNewPoDialog = true;
  }

  async addFpToExistingPo() {
    await this.poService.reassignFpToExistingPo(
      this.selectedPo.fillingProcesses,
      this.existingPo._id,
      this.selectedPo._id
    );
    await this.onFetchData();
    this.displayDialog = false;
  }

  async onSaveNewPo() {
    let unitsPerContainer;
    if (this.newPoSku) {
      const packaging: Packaging = await this.packagingService.getPackaging(
        this.newPoSku.packaging
      );
      unitsPerContainer = packaging.unitsPerContainer;
    }
    const editedTargetAmountInUnits = this.newPoAmount * unitsPerContainer;
    const startTimestamp = this.newPoStartDate.toISOString();
    const newPo = new Po(
      null,
      null,
      this.newPoNumber,
      editedTargetAmountInUnits,
      this.newPoSku,
      this.newPoDensity,
      startTimestamp
    );
    await this.poService.postPo(newPo);
    await this.onFetchData();
    this.displayAddNewPoDialog = false;
  }

  public clearNewPo() {
    this.newPoNumber = null;
    this.newPoSku = null;
    this.newPoDensity = null;
    this.newPoStartDate = null;
    this.newPoAmount = null;
  }

  onDeleteInit(po) {
    this.displayDeleteDialog = true;
    this.poToDelete = { ...po };
  }

  async onDeletePo() {
    await this.poService.deletePo(this.poToDelete._id);
    await this.onFetchData();
    this.displayDeleteDialog = false;
  }

  async search(event) {
    this.skus = await this.skuService.searchSku(event.query);
  }

  async saveEditedPo(form) {
    let unitsPerContainer;
    if (!this.editedSku.packaging) {
      const sku = await this.skuService.getSkuByNumber(this.editedSku);
      this.editedSku = sku;
      unitsPerContainer = this.editedSku.packaging.unitsPerContainer;
    } else {
      const packaging: Packaging = await this.packagingService.getPackaging(
        this.editedSku.packaging
      );
      unitsPerContainer = packaging.unitsPerContainer;
    }
    const editedTargetAmountInUnits = form.value.amount * unitsPerContainer;
    const startTimestamp = this.editedPoStartDate.toISOString();
    const editedPo = new Po(
      this.selectedPo._id,
      this.selectedPo.fillingProcesses,
      this.editedPoNumber,
      editedTargetAmountInUnits,
      this.editedSku,
      this.editedDensity,
      startTimestamp
    );
    await this.poService.updatePo(editedPo);
    await this.onFetchData();
    this.displayDialog = false;
  }

  async onAddNewFp(po: Po) {
    this.machines = await this.machineService.getMachines();
    this.selectedPo = JSON.parse(JSON.stringify(po)); // {...po};
    this.displayAddNewFpDialog = true;
  }

  async onSaveNewFp() {
    const rawAmountFillingProcessUnits =
      this.newFpActualAmount * this.selectedPo.sku.packaging.unitsPerContainer;
    const startTimestamp = this.newFpStartDate.toISOString();
    const endTimestamp = this.newFpEndDate.toISOString();
    const machine: Machine = this.machines.find((m) => {
      return m._id === this.newFpMachineId;
    });
    const fillingProcess = new FillingProcess(
      null,
      rawAmountFillingProcessUnits,
      startTimestamp,
      endTimestamp,
      machine,
      null,
      null,
      null,
      this.newFpComment,
      null,
      this.selectedPo._id,
      this.newFpTankTypeId
    );
    await this.fillingProcessService.postNewFillingProcess(
      fillingProcess,
      this.selectedPo
    );
    await this.onFetchData();
    this.displayAddNewFpDialog = false;
  }

  onFillingProcessesClick(po: Po) {
    const ref = this.dialogService.open(FillingProcessOverviewComponent, {
      data: {
        fillingProcesses: po.fillingProcesses,
        po: po,
        autoZIndex: false,
        baseZIndex: 500,
        positionTop: 0,
      },
      header: `Füllvorgänge von PO ${po.poNumber}`,
      width: "95%",
    });
    ref.onClose.subscribe(async () => {
      await this.onFetchData();
    });
  }
}
