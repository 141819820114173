import * as moment from 'moment';
import {Moment} from 'moment';
import {Event} from './models/event';
import {ProcessPhaseLabel} from './models/process-phase';
import {Machine} from './models/machine';

export class Utils {
  static round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  static toTimerFormat(inputSeconds) {
    const sec_num = parseInt(inputSeconds, 10); // don't forget the second param
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  static getSecondsSince(time: Moment): number {
    return Math.floor(moment.duration(moment().diff(time)).asSeconds());
  }

  static toBoxes(bottles) {
    return Math.floor(bottles / 12);
  }

  static isProcessPhase(event: Event) {
    return !!ProcessPhaseLabel[event.eventDescription.name];
  }
}

/**
 * This function checks wheather the provided JWT is expired or not. It does not verify its integrity and is thus unsafe.
 * It also returns false if the token is not a valid JWT at all.
 * @param token The full JWT
 * @returns boolean telling whether the JWT is still not expired.
 */
export function isJwtExpiredUnsafe(token: string): boolean {
  try {
    const segments = token.split('.');
    const payload = JSON.parse(atob(segments[1]));
    const expirationTimestamp: number = payload.exp * 1000;
    const currentTimestamp: number = new Date().valueOf();
    return currentTimestamp > expirationTimestamp;  // MTZ !!!
  } catch (err) {
    console.log('An error occured during JWT expiration check', err);
    return false;
  }
}
