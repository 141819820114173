<app-header-bar [title]="'Benutzerverwaltung'"></app-header-bar>
<div class="main-container p-grid p-col-12 p-pt-2">
  <p-table
    [columns]="columnsUser"
    [value]="userData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-2">
        Benutzerübersicht
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr>
        <td>{{ rowData.username }}</td>
        <td>{{ Roles[rowData.role] }}</td>
        <td>
          <img *ngIf="!rowData.role.includes('SYSTEM')"
            class="cursor-pointer"
            (click)="onClickResetPassword(rowData)"
            src="./assets/icons/pencil.svg"
            alt="Password-Reset"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- password reset dialog -->

<p-dialog
  header="Neues Passwort vergeben"
  [(visible)]="showResetPasswordDialog"
  [style]="{width: '650px'}"
  (onHide)="clearPassword()">  
  <form #newPasswordForm="ngForm" class="p-p-3">
    <div class="p-field p-grid p-pt-3">
      <div class="p-col-5">
      <label for="password">Neues Passwort<br>(min. 9 Zeichen)</label>
    </div>
      <div class="p-col-6 p-d-flex p-ai-center p-jc-between">
        <input
          class="form-control"
          id="password"
          name="password"
          [type]="showPassword ? 'text' : 'password'"
          minlength="9"
          pInputText
          required
          [(ngModel)]="newPassword"
        />
        <i *ngIf="!showPassword" (click)="showPassword = true" class="pi pi-eye cursor-pointer" style="font-size: 2rem"></i>
        <i *ngIf="showPassword" (click)="showPassword = false" class="pi pi-eye-slash cursor-pointer" style="font-size: 2rem"></i>
      </div>
    </div>
    <div class="p-d-flex p-jc-end">
      <div>
      <button
        class="p-col-4"
        type="button"
        pButton
        icon="fa fa-check"
        [disabled]="!newPasswordForm.valid"
        (click)="saveNewPassword()"
        label="Speichern"
        class="p-mt-5"
        ></button>
      </div>
    </div>
  </form>
</p-dialog>
