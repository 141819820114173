<app-header-bar [title]="'Process Order Übersicht'"></app-header-bar>
<div class="p-grid p-pt-4 subheader-menu">
  <span>Zeitraum</span>
  <p-calendar
    dateFormat="dd.mm.yy"
    [(ngModel)]="selectionService.selectedDates"
    selectionMode="range"
    [maxDate]="selectionService.today"
    [locale]="selectionService.de"
    placeholder="Heute"
  ></p-calendar>
  <p-button (click)="onFetchData()" label="POs holen"></p-button>
  <p-button
    *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)"
    (click)="onAddNewPo()"
    label="Neue PO"
    ></p-button
  >
</div>

<div class="main-container">
  <ng-container *ngIf="dataAvailable$ && posToDisplay?.length > 0">
    <p-table
      [value]="posToDisplay"
      [paginator]="posToDisplay?.length > 15"
      [rows]="15"
      dataKey="poNumber"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="p-col-2">
            Anlage
            <p-dropdown
              [options]="machineList"
              (onChange)="selectMachine($event.value)"
            >
            </p-dropdown>
          </th>
          <th>Nummer</th>
          <th>Erstellt</th>
          <th>Soll-Menge</th>
          <th>Ist-Menge</th>
          <th>SKU</th>
          <th>Füllvorgänge</th>
          <th *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
            Bearbeiten
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
        <tr>
          <td class="p-pl-4">
            <span *ngIf="rowData.fillingProcesses.length >= 1">
              {{
                rowData.fillingProcesses[0].machine
                  ? rowData.fillingProcesses[0].machine.name
                  : null
              }}
            </span>
          </td>
          <td>
            {{ rowData.poNumber ? rowData.poNumber : "Keine PO vorhanden" }}
          </td>
          <td>
            {{
              rowData.startTimestamp || rowData.createdAt
                | date: "dd.MM.yyyy, HH:mm"
            }}
          </td>
          <td>
            {{
              rowData.targetAmountInUnits
                | bottlesToBoxes
                  : (rowData.sku
                      ? rowData.sku.packaging.unitsPerContainer
                      : rowData.fillingProcesses[0]
                      ? rowData.fillingProcesses[0].machine
                          .defaultUnitsPerContainer
                      : 1)
            }}
            <span *ngIf="rowData.sku || rowData.fillingProcesses[0]?.machine">
              EA
            </span>
            <span *ngIf="!rowData.sku && !rowData.fillingProcesses[0]?.machine">
              Gebinde
            </span>
          </td>
          <td>
            {{
              rowData.actualAmount
                | bottlesToBoxes
                  : (rowData.sku
                      ? rowData.sku.packaging.unitsPerContainer
                      : rowData.fillingProcesses[0]
                      ? rowData.fillingProcesses[0].machine
                          .defaultUnitsPerContainer
                      : 1)
            }}
            <span *ngIf="rowData.sku || rowData.fillingProcesses[0]?.machine">
              EA
            </span>
            <span *ngIf="!rowData.sku && !rowData.fillingProcesses[0]?.machine">
              Gebinde
            </span>
          </td>
          <td>
            {{ rowData.sku ? rowData.sku.skuNumber : null }}
          </td>

          <td>
            <div
              class="text-underline cursor-pointer"
              (click)="onFillingProcessesClick(rowData)"
            >
              {{ rowData.fillingProcesses.length }}
              {{
                rowData.fillingProcesses.length == 1
                  ? "Füllvorgang"
                  : "Füllvorgänge"
              }}
            </div>
          </td>
          <td *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
            <img
              *ngIf="rowData.sku"
              class="cursor-pointer p-pl-2"
              (click)="onAddNewFp(rowData)"
              src="./assets/icons/add.svg"
              alt=""
            />
            <img
              class="cursor-pointer p-pl-2"
              (click)="onEditInit(rowData)"
              src="./assets/icons/pencil.svg"
              alt=""
            />
            <img
              class="cursor-pointer p-pl-2"
              (click)="onDeleteInit(rowData)"
              src="./assets/icons/delete.svg"
              alt=""
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <ng-container *ngIf="dataAvailable$ && pos?.length == 0" class="no-data">
    <div class="no-data">
      <p>Für den gewählten Zeitraum sind keine POs gespeichert.</p>
    </div>
  </ng-container>

  <ng-container *ngIf="!dataAvailable$">
    <div class="no-data">
      <p>
        Bitte wählen Sie einen Zeitraum aus, für den Process Orders angezeigt
        werden sollen.
      </p>
    </div>
  </ng-container>
</div>

<!-- edit PO -->

<p-dialog
  header="PO bearbeiten"
  [(visible)]="displayDialog"
  showEffect="fade"
  [modal]="true"
  class="pos-dialog"
  (onHide)="poExists = false"
  styleClass="width-600 overflow-auto"
>
  <div id="modal-content" class="p-fluid" *ngIf="selectedPo">
    <form #editPoForm="ngForm">
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="editedPoNumber">Nummer</label>
        </div>
        <div class="p-col-8">
          <input
            class="form-control"
            minlength="7"
            maxlength="7"
            required
            type="text"
            pInputText
            id="editedPoNumber"
            name="editedPoNumber"
            [(ngModel)]="editedPoNumber"
            (ngModelChange)="searchPo($event)"
            #po="ngModel"
          />
          <div
            *ngIf="po.invalid && (po.dirty || po.touched)"
            class="alert alert-danger"
          >
            <div
              *ngIf="po.errors.minlength || po.errors.maxlength"
              class="red-text"
            >
              PO Nummer muss 7 Zeichen lang sein.
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-10">
        <div class="p-col-4">
          <label for="editedPoStart">Erstellt</label>
        </div>
        <div class="p-col-8" *ngIf="!poExists">
          <p-calendar
            id="editedPoStart"
            name="start"
            required
            dateFormat="dd.mm.yy"
            placeholder="{{ selectedPo.startTimestamp }}"
            [(ngModel)]="editedPoStartDate"
            [showTime]="true"
            [locale]="selectionService.de"
            [maxDate]="selectionService.today"
            styleClass="small-calendar"
            appendTo="body"
          >
          </p-calendar>
        </div>
        <div class="p-col-8" *ngIf="poExists">
          {{ existingPoStart | date: "dd.MM.yyyy, HH:mm" }}
        </div>
      </div>

      <div class="p-col-10">
        <div class="p-col-8">
          <label for="amount">Soll-Menge (EA)</label>
        </div>
        <div class="p-col-8" *ngIf="!poExists">
          <input
            type="number"
            pInputText
            id="amount"
            name="amount"
            required
            [ngModel]="
              selectedPo.targetAmountInUnits
                | bottlesToBoxes
                  : (selectedPo.sku
                      ? selectedPo.sku.packaging.unitsPerContainer
                      : 1)
            "
            #amount="ngModel"
          />
        </div>
        <div class="p-col-8" *ngIf="poExists">
          {{
            existingPoTargetAmount | bottlesToBoxes: existingPoUnitsPerContainer
          }}
        </div>
      </div>

      <div class="p-col-10">
        <div class="p-col-4">
          <label for="sku">SKU</label>
        </div>
        <div class="p-col-8" *ngIf="!poExists">
          <p-autoComplete
            id="sku"
            name="sku"
            required
            [(ngModel)]="editedSku"
            [placeholder]="editedSku"
            [suggestions]="skus"
            field="skuNumber"
            (completeMethod)="search($event)"
            [forceSelection]="true"
            #skuNumber="ngModel"
          >
          </p-autoComplete>
        </div>
        <div class="p-col-8" *ngIf="poExists">
          {{ existingPoSku }}
        </div>
      </div>
      <div class="p-col-12" *ngIf="poExists" class="p-p-3 p-pt-5 p-text-bold">
        Die eingegebene PO Nummer existiert bereits, <br />
        soll der Füllprozess zu PO {{ existingPoNumber }} hinzugefügt werden?
      </div>

      <div class="p-d-flex p-jc-end">
        <div>
          <button
            type="button"
            *ngIf="!poExists"
            pButton
            [disabled]="!editPoForm.valid"
            icon="fa fa-check"
            (click)="saveEditedPo(editPoForm)"
            label="Speichern"
          ></button>
          <button
            type="button"
            *ngIf="poExists"
            pButton
            [disabled]="!editPoForm.valid"
            icon="fa fa-check"
            (click)="addFpToExistingPo()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- add new PO -->

<p-dialog
  header="Neue PO anlegen"
  [(visible)]="displayAddNewPoDialog"
  showEffect="fade"
  [modal]="true"
  class="pos-dialog"
  styleClass="width-600 overflow-auto"
  (onHide) = "clearNewPo()"
>
  <div id="po-modal-content" class="p-fluid">
    <form #newPoForm="ngForm">
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="newPoNumber">Nummer</label>
        </div>
        <div class="p-col-8">
          <input
            class="form-control"
            minlength="7"
            maxlength="7"
            required
            type="text"
            pInputText
            id="newPoNumber"
            name="newPoNumber"
            [(ngModel)]="newPoNumber"
            (ngModelChange)="searchPoInAddNewPo($event)"
            #poNumber="ngModel"
          />
          <div
            *ngIf="poNumber.invalid && (poNumber.dirty || poNumber.touched)"
            class="alert alert-danger"
          >
            <div
              *ngIf="poNumber.errors.minlength || poNumber.errors.maxlength"
              class="red-text"
            >
              PO Nummer muss 7 Zeichen lang sein.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!this.poExists">
        <div class="p-col-10">
          <div class="p-col-4">
            <label for="addStart">Erstellt</label>
          </div>
          <div class="p-col-8">
            <p-calendar
              id="addStart"
              name="addStart"
              required
              dateFormat="dd.mm.yy"
              [(ngModel)]="newPoStartDate"
              [showTime]="true"
              [locale]="selectionService.de"
              [maxDate]="selectionService.today"
              styleClass="small-calendar"
              appendTo="body"
            >
            </p-calendar>
          </div>
        </div>

        <div class="p-col-10">
          <div class="p-col-8">
            <label for="newPoAmount">Soll-Menge (EA)</label>
          </div>
          <div class="p-col-8">
            <input
              type="number"
              pInputText
              id="newPoAmount"
              name="newPoAmount"
              required
              [(ngModel)]="newPoAmount"
              #amount="ngModel"
            />
          </div>
        </div>

        <div class="p-col-10">
          <div class="p-col-4">
            <label for="newPoSku">SKU</label>
          </div>
          <div class="p-col-8">
            <p-autoComplete
              id="newPoSku"
              name="newPoSku"
              required
              [(ngModel)]="newPoSku"
              [suggestions]="skus"
              field="skuNumber"
              (completeMethod)="search($event)"
              [forceSelection]="true"
              #sku="ngModel"
            >
            </p-autoComplete>
          </div>
        </div>

        <div class="p-d-flex p-jc-end">
          <div>
            <button
              type="button"
              pButton
              [disabled]="!newPoForm.valid"
              icon="fa fa-check"
              (click)="onSaveNewPo()"
              label="Speichern"
            ></button>
          </div>
        </div>
      </div>
      <div *ngIf="poExists" class="p-p-4 p-pt-6">
        <div class="p-col-12 p-text-center red-text">
          Die Eingegebene PO Nummer existiert bereits.
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- delete PO -->

<p-dialog
  header="Löschen bestätigen"
  [(visible)]="displayDeleteDialog"
  showEffect="fade"
  [modal]="true"
  styleClass="width-400 overflow-auto"
  dismissableMask="true"
>
  <div *ngIf="poToDelete">
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="po">PO Nummer:&nbsp;</label>
      </div>
      <div id="po" class="p-col-8">
        {{ poToDelete.poNumber ? poToDelete.poNumber : "Keine PO vorhanden" }}
      </div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="start">Erstellt:&nbsp;</label>
      </div>
      <div id="start" class="p-col-8">
        {{ poToDelete.startTimestamp | date: "dd.MM.yyyy, HH:mm" }}
      </div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col-4">
        <label for="amount2">Soll-Menge:&nbsp;</label>
      </div>
      <div id="amount2" class="p-col-8">
        {{
          poToDelete.targetAmountInUnits
            | bottlesToBoxes
              : (poToDelete.sku
                  ? poToDelete.sku.packaging.unitsPerContainer
                  : 1)
        }}
        <span *ngIf="poToDelete.sku"> EA </span>
        <span *ngIf="!poToDelete.sku"> Gebinde </span>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="p-d-flex p-jc-end">
      <div>
        <button
          type="button"
          pButton
          icon="fa fa-check"
          (click)="onDeletePo()"
          label="Löschen"
        ></button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- new po füllvorgang -->
<p-dialog
  header="Neuen Füllvorgang zu PO {{ selectedPo?.poNumber }} hinzufügen"
  [(visible)]="displayAddNewFpDialog"
  [responsive]="true"
  showEffect="fade"
  class="pos-dialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <div id="new-fp-modal-content" class="p-fluid">
    <form #newFpForm="ngForm">
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="fpStartDate">Beginn</label>
        </div>
        <div class="p-col-8">
          <p-calendar
            required
            id="fpStartDate"
            name="fpStartDate"
            dateFormat="dd.mm.yy"
            [(ngModel)]="newFpStartDate"
            [showTime]="true"
            [locale]="selectionService.de"
            [maxDate]="selectionService.today"
            styleClass="small-calendar"
            appendTo="body"
          >
          </p-calendar>
        </div>
      </div>
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="fpEndDate">Ende</label>
        </div>
        <div class="p-col-8">
          <p-calendar
            required
            id="fpEndDate"
            name="fpEndDate"
            dateFormat="dd.mm.yy"
            [(ngModel)]="newFpEndDate"
            [showTime]="true"
            [locale]="selectionService.de"
            [maxDate]="selectionService.today"
            [minDate]="newFpStartDate"
            styleClass="small-calendar"
            appendTo="body"
          >
          </p-calendar>
        </div>
      </div>
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="actualAmount">Ist-Menge (EA)</label>
        </div>
        <div class="p-col-8">
          <input
            pInputText
            required
            type="number"
            id="actualAmount"
            name="actualAmount"
            [(ngModel)]="newFpActualAmount"
          />
        </div>
      </div>
      <div class="p-col-10">
        <div class="p-col-4">
          <label for="addMachine">Anlage</label>
        </div>
        <div class="p-col-8">
          <select
            id="addMachine"
            name="addMachine"
            required
            [(ngModel)]="newFpMachineId"
          >
            <ng-container *ngFor="let machine of machines">
              <option [value]="machine._id">
                {{ machine.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-col-10">
        <div class="p-col-4">
          <label for="addTankType">Tank-Typ</label>
        </div>
        <div class="p-col-8">
          <select
            id="addTankType"
            name="addTankType"
            required
            [(ngModel)]="newFpTankTypeId"
          >
            <ng-container *ngFor="let tank of tankTypes">
              <option [value]="tank._id">
                {{ tank.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-col-10">
        <div class="p-col-4">
          <label for="fpComment">Kommentar</label>
        </div>
        <div class="p-col-8">
          <input
            pInputText
            id="fpComment"
            name="fpComment"
            [(ngModel)]="newFpComment"
          />
        </div>
      </div>
      <div class="p-d-flex p-jc-end">
        <div>
          <button
            type="button"
            pButton
            [disabled]="!newFpForm.valid"
            icon="fa fa-check"
            (click)="onSaveNewFp()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
