<div div class="p-d-flex p-flex-row p-jc-center p-pb-2">
  <p-button 
    (click)="showDisplayAddTargetBpmDialog()"
    *ngIf="[Role.ADMIN].includes(role)"
  >
    Neue Soll-BPM
  </p-button>
</div>

<p-table
  #targetBpm
  [columns]="columnsTargetBpmTable"
  [value]="targetBpmData"
  [paginator]="targetBpmData?.length > 15"
  [rows]="15"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngSwitch]="col.field">
        {{ col.header }}
        <p-dropdown
          *ngSwitchCase="'packagingName'"
          [options]="packagingList"
          [style]="{ width: '100%' }"
          (onChange)="targetBpm.filter($event.value, col.field, 'equals')"
        ></p-dropdown>
        <p-dropdown
          *ngSwitchCase="'machineName'"
          [options]="machineList"
          [style]="{ width: '100%' }"
          (onChange)="targetBpm.filter($event.value, col.field, 'equals')"
        ></p-dropdown>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- new target pbm dialog -->

<p-dialog
  header="Soll-BPM hinzufügen"
  [(visible)]="displayAddTargetBpmDialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <div>
    <form (ngSubmit)="saveTargetBpm()" #targetBpmForm="ngForm">
      <div class="p-field p-grid">
        <label class="p-col-5" for="targetBpmMachine">Anlage</label>
        <div class="p-col">
          <select
            id="targetBpmMachine"
            name="targetBpmMachine"
            required
            [(ngModel)]="newTargetBpmMachineId"
          >
            <ng-container *ngFor="let machine of machines">
              <option [value]="machine._id">
                {{ machine.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="targetBpmPackaging">Gebinde</label>
        <div class="p-col">
          <select
            id="targetBpmPackaging"
            name="targetBpmPackaging"
            required
            [(ngModel)]="newTargetBpmPackaging"
          >
            <ng-container *ngFor="let packaging of packagingData">
              <option [value]="packaging._id">
                {{ packaging.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="targetBpm">Soll-BPM</label>
        <div class="p-col">
          <input
            class="form-control"
            id="targetBpm"
            name="targetBpm"
            type="number"
            pInputText
            required
            [(ngModel)]="newTargetBpm"
          />
        </div>
      </div>
      <div class="p-d-flex p-jc-end p-pt-2">
        <div>
          <button
            type="button"
            pButton
            [disabled]="!targetBpmForm.valid"
            icon="fa fa-check"
            (click)="saveTargetBpm()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
