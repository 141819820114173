import {Component, OnDestroy, OnInit} from "@angular/core";
import { SampleOutlookService } from "app/services/sample-outlook.service";

@Component({
  selector: "app-sample-outlook",
  templateUrl: "./sample-outlook.component.html",
  styleUrls: ["./sample-outlook.component.scss"],
})
export class SampleOutlookComponent implements OnInit, OnDestroy {
  sampleOutlookData= [];
  private updateInterval;
  public columnsSampleOutlook = [
    { field: "machine", header: "Anlage" },
    { field: "start", header: "Rüstbeginn" },
    { field: "po", header: "PO"},
    { field: "sku", header: "SKU" },
    { field: "mc", header: "MC" },    
    { field: "phase", header: "Aktuelle Rüstphase" },
  ];
  public showOldData = false;
  public changeState = () => {
    this.showOldData = !this.showOldData;
  }
  public isDisplayed = (rowData) => {
    return !rowData.isOld || this.showOldData;
  }

  constructor(
    private sampleOutlookService: SampleOutlookService,
  ){ }

  async ngOnInit() {
    await this.fetchData();
    this.updateInterval = setInterval(this.fetchData.bind(this), 10000);

  }

  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }

  async fetchData() {
    this.sampleOutlookData = await this.sampleOutlookService.getSampleOutlook();
  }

}
