<div div class="p-d-flex p-flex-row p-jc-center p-pb-2">
  <p-button (click)="showDisplayAddSkuDialog()" class="p-mr-5">
    Neue SKU
  </p-button>
  <p-checkbox
    [(ngModel)]="showInactiveSkus"
    [binary]="true"
    (ngModelChange)="getSkus()"
    label="Inaktive SKUs anzeigen"
  >
  </p-checkbox>
</div>
<p-table
  #sku
  [columns]="columnsSkuTable"
  [value]="skuData"
  [paginator]="skuData?.length > 15"
  [rows]="15"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngSwitch]="col.field">
        {{ col.header }}
        <input
          *ngSwitchCase="'skuNumber'"
          pInputText
          type="text"
          placeholder="Suchen"
          (input)="
            sku.filter($event.target.value, col.field, col.filterMatchMode)
          "
          [style]="{ width: '100%' }"
        />
        <p-dropdown
          *ngSwitchCase="'packagingName'"
          [options]="packagingList"
          [style]="{ width: '100%' }"
          (onChange)="sku.filter($event.value, col.field, 'equals')"
        ></p-dropdown>
      </th>
      <th>Aktiv</th>
      <th>Bearbeiten</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
      <td>
        <div>
          {{ rowData.active | booleanToLabel }}
        </div>
      </td>
      <td>
        <div
        class="p-d-flex p-flex-row p-jc-evenly p-pr-2">
          <img
            [class]="rowData.active ? 'cursor-pointer' : null"
            [src]="
              rowData.active
                ? './assets/icons/toggle-on.svg'
                : './assets/icons/toggle-off.svg'
            "
            (click)="rowData.active ? onChangeSkuActive(rowData) : null"
            alt=""
          />
          <!-- edit icon -->
          <svg
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            (click)="rowData.active ? onEditSku(rowData) : null"
            [ngStyle]="
              rowData.active ? { cursor: 'pointer' } : { fill: '#C0C0C0' }
            "
          >
            <path
              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
            />
          </svg>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- add sku dialog -->

<p-dialog
  header="Neue SKU Nummer hinzufügen"
  [(visible)]="displayAddSkuDialog"
  [modal]="true"
  (onHide)="clearNewSku()"
  styleClass="width-600 overflow-auto"
>
  <div>
    <form (ngSubmit)="saveSku()" #newSkuForm="ngForm">
      <div class="p-field p-grid">
        <label class="p-col-5" for="skuNumber">SKU Nummer</label>
        <div class="p-col">
          <input
            class="form-control"
            id="skuNumber"
            name="skuNumber"
            type="text"
            pInputText
            required
            minlength="6"
            maxlength="6"
            [(ngModel)]="newSkuNumber"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="description">Beschreibung</label>
        <div class="p-col">
          <input
            class="form-control"
            id="description"
            name="description"
            type="text"
            pInputText
            required
            [(ngModel)]="newSkuDescription"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="mastercode">Mastercode</label>
        <div class="p-col">
          <input
            class="form-control"
            id="mastercode"
            name="description"
            type="text"
            pInputText
            required
            [(ngModel)]="newSkuMastercode"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="componentNumber">Komponentennummer</label>
        <div class="p-col">
          <input
            class="form-control"
            id="componentNumber"
            name="componentNumber"
            type="text"
            pInputText
            required
            [(ngModel)]="newSkuComponentNumber"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="packaging">Gebinde</label>
        <div class="p-col">
          <select
            id="packaging"
            name="packaging"
            required
            [(ngModel)]="this.newSkuPackaging"
          >
            <ng-container *ngFor="let packaging of packagingData">
              <option [value]="packaging._id">
                {{ packaging.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <!--
      <div class="p-field p-grid">
        <label class="p-col-5" for="active">Aktiv</label>
        <div class="p-col">
          <p-dropdown  
            [disabled]="true"
            id="setupTypeActive"
            name="setupTypeActive"
            [(ngModel)]="newSkuActive"
            [options]="booleanDropDownOptions"
            appendTo="body"
          >
          </p-dropdown>
        </div>
      </div>
    -->
      
      <div class="p-d-flex p-jc-end">
        <div>
          <button
            type="button"
            pButton
            [disabled]="!newSkuForm.valid"
            icon="fa fa-check"
            (click)="saveSku()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- edit sku -->

<p-dialog
  header="SKU Bearbeiten"
  [(visible)]="displayEditSkuDialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
  (onHide)="clearNewSku()"
  >
  <div>
    <form (ngSubmit)="saveSku()" #skuForm="ngForm">
      <div class="p-field p-grid">
        <label class="p-col-5" for="skuNumber">SKU Nummer</label>
        <div class="p-col">
          <input
            class="form-control"
            id="skuNumber"
            name="skuNumber"
            type="text"
            pInputText
            required
            minlength="6"
            maxlength="6"
            [(ngModel)]="this.newSkuNumber"
            disabled="true"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="description">Beschreibung</label>
        <div class="p-col">
          <input
            class="form-control"
            id="description"
            name="description"
            type="text"
            pInputText
            required
            [(ngModel)]="this.newSkuDescription"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="description">Mastercode</label>
        <div class="p-col">
          <input
            class="form-control"
            id="mastercode"
            name="mastercode"
            type="text"
            pInputText
            required
            [(ngModel)]="this.newSkuMastercode"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="componentNumber">Komponentennummer</label>
        <div class="p-col">
          <input
            class="form-control"
            id="componentNumber"
            name="componentNumber"
            type="text"
            pInputText
            required
            [(ngModel)]="this.newSkuComponentNumber"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-5" for="packaging">Gebinde</label>
        <div class="p-col">
          <select
            id="packaging"
            name="packaging"
            required
            [(ngModel)]="this.newSkuPackaging"
          >
            <ng-container *ngFor="let packaging of packagingData">
              <option [value]="packaging._id">
                {{ packaging.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <!--
      <div class="p-field p-grid">
        <label class="p-col-5" for="active">Aktiv</label>
        <div class="p-col">
          <p-dropdown
            id="setupTypeActive"
            name="setupTypeActive"
            [(ngModel)]="this.newSkuActive"
            [options]="booleanDropDownOptions"
            appendTo="body"
          >
          </p-dropdown>
        </div>
      </div>
      -->
      <div class="p-d-flex p-jc-end">
        <div>
          <button
            type="button"
            pButton
            [disabled]="!skuForm.valid"
            icon="fa fa-check"
            (click)="saveEditedSku()"
            label="Speichern"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- deactiveate sku  -->
<p-confirmDialog 
  header="Bestätigen" 
  acceptLabel="Ja" 
  rejectLabel="Nein" 
  key="skuConfirmDialog"
  styleClass="width-600 overflow-auto">
</p-confirmDialog>
