import { AuthService } from './services/auth.service';
import { CockpitComponent } from './pages/cockpit/cockpit.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { OeeDashboardComponent } from './pages/oee-dashboard/oee-dashboard.component';
import { MachinePerformanceComponent } from './pages/machine-performance/machine-performance.component';
import { MachineLiveOverviewComponent } from './pages/machine-live-overview/machine-live-overview.component';
import { MachineDataComponent } from './pages/machine-live-overview/machine-data/machine-data.component';
import { MasterDataComponent } from './pages/master-data/master-data.component';
import { PoOverviewComponent } from './pages/po-overview/po-overview.component';
import { EventsOverviewComponent } from './pages/events-overview/events-overview.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { FillingProcessOverviewComponent } from './pages/po-overview/filling-process-overview/filling-process-overview.component';
import { ConfigurationChangeComponent } from './pages/configuration-change/configuration-change.component';
// Charts
import { OeeEvaluationChartComponent } from './pages/oee-dashboard/charts/oee-evaluation-chart.component';
import { MachineSpeedChartComponent } from './pages/machine-performance/charts/machine-speed-chart.component';
import { MachineAmountChartComponent } from './pages/machine-performance/charts/machine-amount-chart.component';
import { LiveAmountChartComponent } from './pages/machine-live-overview/charts/live-amount-chart.component';
import { LiveSpeedChartComponent } from './pages/machine-live-overview/charts/live-speed-chart.component';
import { TaEvaluationDrilldownChartComponent } from './pages/oee-dashboard/charts/ta-evaluation-drilldown-chart.component';
import { DetailedFailureEvaluationChartComponent } from './pages/oee-dashboard/charts/detailed-failure-evaluation-chart.component';
import { SetupEvaluationChartComponent } from './pages/oee-dashboard/charts/setup-evaluation-chart.component';
import { FillingAmountChartComponent } from './pages/po-overview/filling-process-overview/charts/filling-amount-chart.component';
import { SlowdownEvaluationChartComponent } from "./pages/oee-dashboard/charts/slowdown-evaluation-chart.component";
// Services
import { DataService } from './services/data.service';
import { MachineService } from './services/machine.service';
import { PackagingService } from './services/packaging.service';
import { SkuService } from './services/sku.service';
import { TargetBpmService } from './services/target-bpm.service';
import { SetupTypesService } from './services/setup-types.service';
import { PoService } from './services/po.service';
import { EventService } from './services/event.service';
import { OperationTimesService } from './services/operation-times.service';
import { CollectionService } from './services/collection.service';
import { DaystatsService } from './services/daystats.service';
import { EventDescriptionService } from './services/event-description.service';
import { FailureCategoryService } from './services/failure-category.service';
import { FillingProcessService } from './services/filling-process.service';
// Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as exportData from 'highcharts/modules/export-data.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
// Prime NG
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';



// Auth

import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth/AuthGuard';
import { HttpJwtInterceptor } from './auth/HttpJwtInterceptor';
import { ErrorInterceptor } from './auth/ErrorInterceptor';

import { BottlesToBoxesPipe } from './pipes/bottles-to-boxes.pipe';
import { ProcessPhaseLabelsPipe } from './pipes/process-phase-labels.pipe';
import { SecondsToHoursPipe } from './pipes/seconds-to-hours.pipe';
import { SkuToSkuDescriptionPipe } from './pipes/sku-to-skuDescription.pipe';

import { BooleanToLabelPipe } from './pipes/boolean-to-label.pipe';
import { SocketIdToLabelPipe } from './pipes/socketId-to-label.pipe';
import { FillingProcessPipe } from './pipes/filling-process.pipe';

import { ConfirmationService } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

import { NoStopScreenComponent } from './pages/no-stop-screen/no-stop-screen.component';
import { SampleOutlookComponent } from './pages/sample-outlook/sample-outlook.component';
import { NoStopScreenService } from './services/no-stop-screen.service';
import { SampleOutlookService } from './services/sample-outlook.service';
import { MachinesComponent } from './pages/master-data/machines/machines.component';
import { PackagingDataComponent } from './pages/master-data/packaging-data/packaging-data.component';
import { TankTypeDataComponent } from './pages/master-data/tanktype-data/tanktype-data.component';

import { SetupTypesComponent } from './pages/master-data/setup-types/setup-types.component';
import { SkuDataComponent } from './pages/master-data/sku-data/sku-data.component';
import { TargetBpmComponent } from './pages/master-data/target-bpm/target-bpm.component';
import { Role } from 'common/Auth/Role';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { TankTypeService } from './services/tank-type.service';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderBarComponent,
    EventsOverviewComponent,
    CollectionComponent,
    MachineLiveOverviewComponent,
    MachineDataComponent,
    MachinePerformanceComponent,
    MasterDataComponent,
    LoginComponent,
    CockpitComponent,
    OeeDashboardComponent,
    PoOverviewComponent,
    FillingProcessOverviewComponent,
    ConfigurationChangeComponent,
    // charts
    FillingAmountChartComponent,
    OeeEvaluationChartComponent,
    SetupEvaluationChartComponent,
    MachineSpeedChartComponent,
    MachineAmountChartComponent,
    LiveAmountChartComponent,
    LiveSpeedChartComponent,
    TaEvaluationDrilldownChartComponent,
    DetailedFailureEvaluationChartComponent,
    SlowdownEvaluationChartComponent,
    BottlesToBoxesPipe,
    ProcessPhaseLabelsPipe,
    SecondsToHoursPipe,
    SkuToSkuDescriptionPipe,
    BooleanToLabelPipe,
    SocketIdToLabelPipe,
    FillingProcessPipe,
    NoStopScreenComponent,
    SampleOutlookComponent,
    MachinesComponent,
    PackagingDataComponent,
    TankTypeDataComponent,
    SetupTypesComponent,
    SkuDataComponent,
    TargetBpmComponent,
    UserManagementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    HttpClientModule,
    ChartModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TableModule,
    // ToastModule,
    PanelModule,
    ButtonModule,
    TabViewModule,
    InputTextModule,
    DynamicDialogModule,
    DialogModule,
    MultiSelectModule,
    TreeModule,
    CheckboxModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      {
        path: 'cockpit', component: CockpitComponent, children: [  // MTZ
          { path: 'live-overview', component: MachineLiveOverviewComponent },
          { path: 'machine-performance', component: MachinePerformanceComponent },
          { path: 'oee-dashboard', component: OeeDashboardComponent },
          { path: 'events-overview', component: EventsOverviewComponent },
          {
            path: 'collection',
            canActivate: [AuthGuard],
            data: { roles: [Role.ADMIN, Role.SHIFTLEAD] },
            component: CollectionComponent
          },
          { path: 'po-overview', component: PoOverviewComponent },
          { path: 'master-data', canActivate: [AuthGuard], data: { roles: [Role.ADMIN, Role.SHIFTLEAD] }, component: MasterDataComponent },
          {
            path: 'configuration-change',
            canActivate: [AuthGuard],
            data: { roles: [Role.ADMIN, Role.SHIFTLEAD] },
            component: ConfigurationChangeComponent
          },
          { path: 'no-stop-screen', component: NoStopScreenComponent },
          { path: 'sample-outlook', component: SampleOutlookComponent },
          {
            path: 'user-management',
            canActivate: [AuthGuard],
            data: { roles: [Role.ADMIN] },
            component: UserManagementComponent
          },
        ]
      },
      { path: '', pathMatch: 'full', redirectTo: 'cockpit/live-overview' },
      { path: '**', redirectTo: 'login' }
    ], { useHash: true }),
    AutoCompleteModule,
    ConfirmDialogModule,
    TreeTableModule,
    ConfirmDialogModule,
    InputTextareaModule,

  ],
  providers: [
    AuthGuard,
    AuthService,
    DataService,
    MachineService,
    PackagingService,
    SkuService,
    TargetBpmService,
    SetupTypesService,
    TankTypeService,
    PoService,
    EventService,
    FillingProcessService,
    OperationTimesService,
    CollectionService,
    DaystatsService,
    FailureCategoryService,
    EventDescriptionService,
    NoStopScreenService,
    SampleOutlookService,
    ConfirmationService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, solidGauge, drilldown, exportData] },
    { provide: HTTP_INTERCEPTORS, useClass: HttpJwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ProcessPhaseLabelsPipe,
    FillingProcessPipe

  ],
  entryComponents: [
    FillingProcessOverviewComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
