import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProcessPhaseLabelsPipe } from 'app/pipes/process-phase-labels.pipe';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { Role } from '../../../common/Auth/Role';
import { Event } from '../../models/event';
import { EventDescription } from '../../models/event-description';
import { FillingProcess } from '../../models/filling-process';
import { Machine } from '../../models/machine';
import { ProcessPhaseLabel } from '../../models/process-phase';
import { AuthService } from '../../services/auth.service';
import { EventDescriptionService } from '../../services/event-description.service';
import { EventService } from '../../services/event.service';
import { FailureCategoryService } from '../../services/failure-category.service';
import { FillingProcessService } from '../../services/filling-process.service';
import { MachineService } from '../../services/machine.service';
import { SelectionService } from '../../services/selection.service';

@Component({
  selector: "app-events-overview-component",
  templateUrl: "events-overview.component.html",
  styleUrls: ["events-overview.component.scss"],
  providers: [DialogService],
})
export class EventsOverviewComponent implements OnInit {
  today = moment().toDate();
  machines: Machine[];
  dataAvailable$ = new BehaviorSubject(false);
  eventsData: any[];
  public events: Event[];
  public eventsToDisplay: Event[];
  public eventList;

  commentForEvent: string;
  displayComment = false;
  displayDialog = false;
  public selectedEvent;
  public selectedStartDate: Date;
  public selectedEndDate: Date;
  public eventDescriptions: EventDescription[];
  public eventNameForEditing: string;
  public selectedEventIsProcessPhase = false;
  public displayAddNewDialog = false;
  public newEventName: string;

  public newEventMachineName;
  public editEventMachineName: string;
  private eventDescriptionsForMachine: EventDescription[];

  // public newEventStartDate: Date;
  public newEventStartDate;
  public newEventEndDate: Date;
  public newEventProcessPhase;
  public newEventComment;
  public displayDeleteDialog = false;
  public eventToDelete: Event;
  changedStart = false;
  changedEnd = false;
  private startDay: number = null;
  private endDay: number = null;

  public selectedEventFilter = null;

  columnsEventTable = [
    { field: "name", header: "Event" },
    { field: "machineName", header: "Anlage" },
    { field: "startTimestampString", header: "Beginn" },
    { field: "endTimestampString", header: "Ende" },
    { field: "duration", header: "Dauer" },
    { field: "processPhaseLabel", header: "Phase" },
    { field: "isSlowdownTransl", header: "Geschw.verlust" },
  ];

  multiDeleteSelection = {};
  displayModalDeleteMany = false;
  displayModalEditMany = false;
  newMultiEventDescription: string = null;

  role: Role;
  public Role = Role;

  fillingProcesses: Partial<FillingProcess>[] = [
    { _id: '' },
  ]
  newFillingProcessId: string = '';

  // filter failureCategories
  firstLevelList = []; 
  secondLevelList = []; 
  thirdLevelList = []; 

  flatMap = [];

  failureCategories: any[] = [];

  firstLevelFormControl = new FormControl({value: '', disabled: true})
  secondLevelFormControl = new FormControl({value: '', disabled: true})
  thirdLevelFormControl = new FormControl({value: '', disabled: true})


  public stillLoading = false;

  constructor(
    public eventService: EventService,
    private machineService: MachineService,
    private eventDescriptionService: EventDescriptionService,
    private authService: AuthService,
    public selectionService: SelectionService,
    private fillingProcessService: FillingProcessService,
    private failureCategoryService: FailureCategoryService,
    private processPhaseLabelsPipe: ProcessPhaseLabelsPipe
  ) {
    this.role = this.authService.role;
  }

   async ngOnInit() {


    this.stillLoading = true;
    this.firstLevelFormControl.valueChanges.subscribe(firstLevel => {
      const second = this.firstLevelList.find(item => item._id === firstLevel);
      if (second && second.children && second.children.length ) {
        this.secondLevelList = second.children.map((item: {_id: string, label: string, children: any})  => ({_id: item._id, label: item.label, children: item.children  }));
        this.secondLevelList.unshift({_id: '', label: ' --- '})
        this.secondLevelFormControl.enable();
      } else {
        this.secondLevelList = [];
        this.secondLevelFormControl.disable();
      }
      this.secondLevelFormControl.patchValue('', {emitModelToViewChange: true});
    });

    this.secondLevelFormControl.valueChanges.subscribe(secondLevel => {
      const third = this.secondLevelList.find(item => item._id === secondLevel);
      if (third && third.children && third.children.length ) {
        this.thirdLevelList = third.children.map((item: {_id: string, label: string, children: any})  => ({_id: item._id, label: item.label, children: item.children  }));
        this.thirdLevelList.unshift({_id: '', label: ' --- '})
        this.thirdLevelFormControl.enable();
      } else {
        this.thirdLevelList = [];
        this.thirdLevelFormControl.disable();
      }
      this.thirdLevelFormControl.patchValue('', {emitModelToViewChange: true});
    });


    this.machines = await this.machineService.getActiveMachines();
    this.eventDescriptions = await this.eventDescriptionService.getEventDescriptions();
    this.eventDescriptions
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (ProcessPhaseLabel[a.name] && !ProcessPhaseLabel[b.name]) { return -1 }
        else if (!ProcessPhaseLabel[a.name] && ProcessPhaseLabel[b.name]) { return 1 }
        else if (ProcessPhaseLabel[a.name] && ProcessPhaseLabel[b.name]) {
          if (ProcessPhaseLabel[a.name] < ProcessPhaseLabel[b.name]) { return -1 }
          if (ProcessPhaseLabel[a.name] > ProcessPhaseLabel[b.name]) { return 1 }
        }
        return 0
      })
    this.setProcessPhase(this.newEventName);
    this.eventDescriptionsForMachine = this.eventDescriptions;
    this.alphLocalSortedEventsTransform();

    this.stillLoading = false;
    await this.fetchData(true);


  }

  filterSelectionChanged() {

      this.selectedEventFilter = null;

      let tree = null;
      if (this.firstLevelFormControl.value && this.secondLevelFormControl.value && this.thirdLevelFormControl.value) { 
        tree = this.failureCategories.find(item => item._id === this.firstLevelFormControl.value )
                            .children.find(item => item._id === this.secondLevelFormControl.value )
                            .children.find(item => item._id === this.thirdLevelFormControl.value );
      } else if (this.firstLevelFormControl.value && this.secondLevelFormControl.value) {
        tree = this.failureCategories.find(item => item._id === this.firstLevelFormControl.value )
                            .children.find(item => item._id === this.secondLevelFormControl.value );
      } else if (this.firstLevelFormControl.value) {
        tree = this.failureCategories.find(item => item._id === this.firstLevelFormControl.value );
      } 
  
      this.flatMap = [];
      if (tree) {
        this.getFlatMatchListFromTree(tree);
      }

      
      if (!this.flatMap.length) {
        this.eventsToDisplay = this.events;
      } else {
        this.eventsToDisplay = this.events.filter(item => item.eventDescription && this.flatMap.includes(item.eventDescription._id))
      }
 

  }

  private getFlatMatchListFromTree(tree) {
      if (tree && tree.children && tree.children.length) {
          tree.children.forEach(item => this.getFlatMatchListFromTree(item));
      } else {
        this.flatMap.push(tree._id)
      }
  }

  onDateChange = async (event) => {
    if (this.eventDatesChanged()) {
      const fillingProcesses = await this.fillingProcessService.getFillingProcesses(this.newEventStartDate, this.newEventEndDate, this.selectionService.forMachine).toPromise()
      this.fillingProcesses = [{ _id: '', comments: 'Leer' }, ...fillingProcesses];
    }

  }

  getDeleteSelectionLength = () => {
    return Object.entries(this.multiDeleteSelection).filter(entry => entry[1]).length
  }

  private async reload() {
    this.multiDeleteSelection = {};
    this.newMultiEventDescription = null;
    this.displayModalDeleteMany = false;
    this.displayModalEditMany = false;
    await this.fetchData(false);
  }

  deleteMultipleItems = async () => {
    const deleteItems = Object.entries(this.multiDeleteSelection).filter(entry => entry[1]).map(entry => entry[0]);



    const deleteObs = deleteItems.map(id => this.eventService.deleteEventObs(id));
    const deleteAll = await forkJoin(deleteObs).toPromise();
    await this.reload();

  }


  submitMultiEdit = async () => {

    const eventIdsToEdit = Object.entries(this.multiDeleteSelection).filter(entry => entry[1]).map(entry => entry[0]);
    const editObs = eventIdsToEdit.map(eventId => this.eventService.upDatePartialEventObs({_id: eventId, eventDescription:  {_id: this.newMultiEventDescription}}));
    const modifyAll = await forkJoin(editObs).toPromise();



    await this.reload();
  }

  private eventDatesChanged = () => {
    if (!this.startDay) {
      this.startDay = moment(this.newEventStartDate).startOf('day').unix();
      return true;
    }
    if (!this.endDay) {
      this.endDay = moment(this.newEventEndDate).startOf('day').unix();
      return true;
    }
    if (moment(this.newEventStartDate).startOf('day').unix() !== this.startDay || moment(this.newEventEndDate).startOf('day').unix() !== this.endDay) {
      this.endDay = moment(this.newEventEndDate).startOf('day').unix();
      this.startDay = moment(this.newEventStartDate).startOf('day').unix();
      return true;
    }

    return false;
  }


  public alphLocalSortedEvents = [];

  private alphLocalSortedEventsTransform() {

    const alphMap = this.eventDescriptionsForMachine.map(item => {
      return {
        name: item.name,
        id: item._id,
        processLabel: this.processPhaseLabelsPipe.transform(item.name),
      }
    });

    alphMap.push({
      name: "Nicht identifiziert",
      id: null,
      processLabel: "Nicht identifiziert",
    });

    this.alphLocalSortedEvents = alphMap.sort((a, b) => {
      const aLabel: string = a.processLabel ? a.processLabel.trim() : '';
      const bLabel: string = b.processLabel ? b.processLabel.trim() : '';
      return aLabel.localeCompare(bLabel, 'de'); //
    }).filter(item => item.processLabel);
  }

  async fetchData(resetFilter?: boolean) {


    this.stillLoading = true;
    if (resetFilter) {
      this.selectedEventFilter = null;

      this.firstLevelFormControl.patchValue('', {emitModelToViewChange: true});
      this.secondLevelFormControl.patchValue('', {emitModelToViewChange: true});
      this.thirdLevelFormControl.patchValue('', {emitModelToViewChange: true});

      this.firstLevelList = [];
      this.secondLevelList = [];
      this.thirdLevelList = [];


    }

    if (this.selectionService.selectedDates) {
      const startDate = moment(this.selectionService.selectedDates[0]).toISOString();
      const endDate = moment(this.selectionService.selectedDates[1]).toISOString();
      this.events = await this.eventService.getRawEvents(startDate, endDate, [
        this.selectionService.forMachine,
      ]);
      this.eventsToDisplay = this.events;
      this.eventList = Array.from(
        new Set(
          this.events.map((event: any) => {
            return event.eventDescription
              ? event.eventDescription.label
              : "Nicht Identifiziert";
          })
        )
      ).map((eventName) => {
        return {
          label: ProcessPhaseLabel[eventName]
            ? ProcessPhaseLabel[eventName]
            : eventName,
          value: eventName,
        };
      });
      this.eventList.sort((a, b) => {
        const aLabel: string = a.label.trim();
        const bLabel: string = b.label.trim();
        return aLabel.localeCompare(bLabel, 'de');

      })

      this.eventList.unshift({ label: "Alle Events", value: null });


    }
    this.changedStart = false;
    this.changedEnd = false;

    if (this.firstLevelFormControl.value != '') {
      this.filterSelectionChanged();
    } else if (this.selectedEventFilter) {
      this.filter(this.selectedEventFilter);
    }

    if (resetFilter) {
      this.failureCategories = await this.failureCategoryService.getFailureHierarchyObs(this.selectionService.forMachine).toPromise();
      const list = this.failureCategories.map((item: {_id: string, label: string, children: any})  => ({_id: item._id, label: item.label, children: item.children  }));
      list.unshift({_id: '', label: ' ---  ', children: null});
      this.firstLevelList = list;
      this.firstLevelFormControl.enable();
    }

    this.stillLoading = false;

  }
  // --> bis hier fetchData

  filter(selectedEventType) {
    if (!selectedEventType) {
      this.eventsToDisplay = this.events;
      this.selectedEventFilter = null;

    } else {
      this.selectedEventFilter = selectedEventType;
      this.eventsToDisplay = this.events.filter((event) => {
        if (event.eventDescription) {
          return event.eventDescription.label === selectedEventType;
        } else if (selectedEventType === "Nicht Identifiziert") {
          return event.eventDescription === null;
        }
      });
    }
  }

  onChangeMachineForExistingEvent(event) {
    this.selectedEvent.machine = this.machines.find((machine) =>
      machine.name.includes(event.target.value)
    );
    this.eventDescriptionsForMachine = this.eventDescriptions.filter(
      (item) =>
        !item.machines || item.machines.includes(this.selectedEvent.machine._id)
    );
    this.alphLocalSortedEventsTransform();
  }

  onChangeMachineForNewEvent(event) {
    const machine = this.machines.find((m) =>
      m.name.includes(event.target.value)
    );
    this.eventDescriptionsForMachine = this.eventDescriptions.filter(
      (item) => !item.machines || item.machines.includes(machine._id)
    );
    this.alphLocalSortedEventsTransform();
  }

  async onEditInit(event: Event) {


    this.stillLoading = true;

    this.selectedStartDate = moment(event.startTimestamp).toDate();
    this.selectedEndDate = moment(event.endTimestamp).toDate();
    this.selectedEvent = { ...event };
    this.changedStart = false;
    this.changedEnd = false;
    this.displayDialog = true;
    this.newFillingProcessId = event.fillingProcess;


    if (this.selectedStartDate && this.selectedEndDate) {
        const fillingProcesses = await this.fillingProcessService.getFillingProcesses(
          moment(this.selectedStartDate).subtract(1, 'days').toDate(), 
          moment(this.selectedEndDate).add(1,'days').toDate(), 
          this.selectionService.forMachine).toPromise()
        this.fillingProcesses = [{ _id: '', comments: 'Leer' }, ...fillingProcesses];    
    }

    this.editEventMachineName = this.selectedEvent.machine.name;
    this.eventDescriptionsForMachine = this.eventDescriptions.filter(
      (item) =>
        !item.machines || item.machines.includes(this.selectedEvent.machine._id)
    );
    this.alphLocalSortedEventsTransform();


    this.eventNameForEditing = event.eventDescription
    ? event.eventDescription.name
    : "Nicht identifiziert";    
    this.stillLoading = false;


  }

  onAddNewEvent() {

    this.stillLoading = true;

    this.newEventMachineName = this.machines.find(
      (machine) => machine._id === this.selectionService.forMachine
    ).name;
    this.eventDescriptionsForMachine = this.eventDescriptions.filter(
      (item) => !item.machines || item.machines.includes(this.selectionService.forMachine)
    );
    this.alphLocalSortedEventsTransform();


    this.newEventStartDate = this.selectionService.selectedDates[0];

    this.changedStart = false;
    this.changedEnd = false;

    this.displayAddNewDialog = true;

    this.stillLoading = false;

  }

  async onSaveNewEvent() {
    const eventDescription: EventDescription = this.eventDescriptions.find(
      (ed) => {
        return ed.name === this.newEventName;
      }
    );
    if (eventDescription?.isFailure === false) {
      this.newEventProcessPhase = eventDescription.name;
    }
    const machine: Machine = this.machines.find((m) => {
      return m.name === this.newEventMachineName;
    });
    let startTimestamp;
    if (this.changedStart)
      startTimestamp = moment(this.newEventStartDate)
        .second(0)
        .millisecond(0)
        .toISOString();
    else startTimestamp = this.newEventStartDate.toISOString();
    let endTimestamp;
    if (this.changedEnd)
      endTimestamp = moment(this.newEventEndDate)
        .second(0)
        .millisecond(0)
        .toISOString();
    else
      endTimestamp = this.newEventEndDate
        ? this.newEventEndDate.toISOString()
        : null;
    let durationInSeconds = moment(endTimestamp).diff(
      moment(startTimestamp),
      "seconds"
    );
    if (durationInSeconds < 0) durationInSeconds = 0;
    const newEvent = new Event(
      null,
      false,
      this.newEventProcessPhase,
      machine,
      startTimestamp,
      endTimestamp,
      eventDescription,
      null,
      null,
      durationInSeconds,
      this.newEventComment,
      null,
      null,
      null,
      this.newFillingProcessId ? this.newFillingProcessId : undefined,
      false
    );
    await this.eventService.postEvent(newEvent);
    await this.fetchData();
    this.displayAddNewDialog = false;
  }

  onDeleteInit(event: Event) {
    this.eventToDelete = event;
    this.displayDeleteDialog = true;
  }

  async onDeleteEvent() {
    await this.eventService.deleteEvent(this.eventToDelete._id);
    await this.fetchData();
    this.displayDeleteDialog = false;
  }

  setProcessPhase(eventName) {
    const eventDescription: EventDescription = this.eventDescriptions.find(
      (ed) => {
        return ed.name === eventName;
      }
    );
    if (eventDescription?.isFailure === false) {
      if (this.selectedEvent) {
        this.selectedEvent.processPhase = eventDescription.name;
      } else {
        this.newEventProcessPhase = eventDescription.name;
      }
      this.selectedEventIsProcessPhase = true;
    } else {
      this.selectedEventIsProcessPhase = false;
    }
  }

  isProcessPhase(event) {
    const eventDescription: EventDescription = this.eventDescriptions.find(
      (ed) => {
        return ed.name === event.target.value;
      }
    );
    if (eventDescription?.isFailure === false) {
      if (this.selectedEvent) {
        this.selectedEvent.processPhase = eventDescription.name;
      } else {
        this.newEventProcessPhase = eventDescription.name;
      }
      this.selectedEventIsProcessPhase = true;
    } else {
      this.selectedEventIsProcessPhase = false;
    }
  }

  async save() {
    const eventDescription: EventDescription = this.eventDescriptions.find(
      (ed) => {
        return ed.name === this.eventNameForEditing;
      }
    );
    if (eventDescription?.isFailure === false) {
      this.selectedEvent.processPhase = eventDescription.name;
    }
    const machine: Machine = this.machines.find((m) => {
      return m.name === this.selectedEvent.machine.name;
    });
    let startTimestamp;
    if (this.changedStart)
      startTimestamp = moment(this.selectedStartDate)
        .second(0)
        .millisecond(0)
        .toISOString();
    else startTimestamp = this.selectedStartDate.toISOString();
    let endTimestamp;
    if (this.changedEnd)
      endTimestamp = moment(this.selectedEndDate)
        .second(0)
        .millisecond(0)
        .toISOString();
    else
      endTimestamp = this.selectedEndDate
        ? this.selectedEndDate.toISOString()
        : null;
    let durationInSeconds = moment(endTimestamp).diff(
      moment(startTimestamp),
      "seconds"
    );
    if (durationInSeconds < 0) durationInSeconds = 0;
    const editedEvent = new Event(
      this.selectedEvent._id,
      this.selectedEvent.isSlowdown,
      this.selectedEvent.processPhase,
      machine,
      startTimestamp,
      endTimestamp,
      eventDescription,
      this.selectedEvent.createdAt,
      this.selectedEvent.updatedAt,
      durationInSeconds,
      this.selectedEvent.comment,
      this.selectedEvent.poId,
      this.selectedEvent.slowDownBottleCount,
      this.selectedEvent.currentSlowDownEventTime,
      this.newFillingProcessId ? this.newFillingProcessId : null,
      this.selectedEvent.isPlannedInterruption

    );



    await this.eventService.upDatePartialEventObs(editedEvent).toPromise();
    await this.fetchData();
    this.displayDialog = false;
  }

  onViewCommentClick(event: Event) {
    this.commentForEvent = event.comment;
    this.displayComment = true;
  }

  public clearEditEvent() {
    this.selectedEvent = null;
    this.editEventMachineName = null;
    this.eventNameForEditing = null;
    this.selectedStartDate = null;
    this.selectedEndDate = null;

    this.newFillingProcessId = '';
    this.fillingProcesses = [{ _id: '', comments: 'Leer' }];
  }

  public clearNewEvent() {
    this.newEventMachineName = null;
    this.newEventName = null;
    this.newEventStartDate = null;
    this.newEventEndDate = null;
    this.newEventProcessPhase = null;
    this.newEventComment = null;

    this.newFillingProcessId = '';
    this.fillingProcesses = [{ _id: '', comments: 'Leer' }];


  }


  async toggleActive(id: string) {

    const eventToUpate = this.eventsToDisplay.find(event => event._id == id);
    if (eventToUpate) {
      await this.eventService.upDatePartialEventObs({_id: eventToUpate._id, isPlannedInterruption: !eventToUpate.isPlannedInterruption}).toPromise();
      await this.reload();
    }


  }

}