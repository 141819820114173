import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { CategoryInfoMap } from '../../../models/categories';
import { Utils } from '../../../utils';
import {ProcessPhaseLabel} from '../../../models/process-phase';

@Component({
  selector: 'app-ta-evaluation-drilldown-chart',
  template: '<div [chart]="chart"></div>',
})

export class TaEvaluationDrilldownChartComponent implements OnInit {

  chart: Chart;
  categoryData = [];
  drilldownData = [];

  @Input() color: string;
  @Input() name: string;
  @Input()
  set data(equipData: any) {
    this.categoryData = [];
    this.drilldownData = [];
    this.sortDataIntoCategories(equipData);

    this.chart = new Chart({
      chart: {
        type: 'column',
        height: 200,
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          rotation: -45
        },
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Zeiten in Minuten'
        }
      },
      series: [{
        type: 'column',
        name: this.name,
        color: this.color,
        data: this.categoryData
      }],
      drilldown: {
        activeAxisLabelStyle: {
          textDecoration: 'none',
          color: 'default'
        },
        drillUpButton: {
          position: {
            y: -10,
            x: 0,
          },
        },
        series: this.drilldownData
      },
      lang: {
        drillUpText: 'Zurück zu Level 4'
      },
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:12px">{point.key}</span><br>',
        pointFormat: '{point.y:.2f}'
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
        },
      },
    });
  }

  constructor() {
  }

  ngOnInit() {
  }

  sortDataIntoCategories(equipData) {
    const drilldownData = {};
    const categoryData = {};
    for (const key in equipData) {
      if (equipData.hasOwnProperty(key)) {
        // the key is the FaultType of the event
        // for every key, there is an array {FaultType: [fault duration, level 4 Category]}
        const eventData = equipData[key];
        const eventDuration = eventData[0];
        const eventCategoryLvl4 = eventData[1];
        if (drilldownData[eventCategoryLvl4]) {
          // add the faultType and duration to the drilldown data
          drilldownData[eventCategoryLvl4].push([ProcessPhaseLabel[key], Utils.round(eventDuration, 2)]);
          const existingCategoryData = categoryData[eventCategoryLvl4];
          // add the duration of the faultType to the existing total of durations for the level 4 category
          categoryData[eventCategoryLvl4]['y'] = eventDuration + existingCategoryData['y'];
        } else {
          drilldownData[eventCategoryLvl4] = [[ProcessPhaseLabel[key], Utils.round(eventDuration, 2)]];
          categoryData[eventCategoryLvl4] = {
            'name': `${CategoryInfoMap.get(eventCategoryLvl4).label}`,
            'y': eventDuration,
            'drilldown': `${eventCategoryLvl4}`
          };
        }
        // drilldownData[eventCategoryLvl4].sort((a, b) => b[1] - a[1]);
      }
    }
    for (const key in categoryData) {
      if (categoryData.hasOwnProperty(key)) {
        this.categoryData.push(categoryData[key]);
      }
    }
    for (const key in drilldownData) {
      if (drilldownData.hasOwnProperty(key)) {
        this.drilldownData.push({
          'name': `${ProcessPhaseLabel[key]}`,
          'id': `${key}`,
          'data': drilldownData[key]
        });
      }
    }
  }
}
