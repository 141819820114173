import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment } from '../../environments/environment';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private dataAPIEndpoint = environment.APIEndpoint + '/user';


  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<User[]>(this.dataAPIEndpoint);
  }

  resetPassword(password: string, userId: string): Observable<any> {
    const body: any = {
      password: password,
    };
    return this.http.put<User>(this.dataAPIEndpoint + '/password/' + userId, body);
  }
}
