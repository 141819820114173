import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { isJwtExpiredUnsafe } from '../utils';
import { Role } from '../../common/Auth/Role'; 


interface AuthResponse {
  message: string;
  token: string;

  username: string;
  role: Role;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private storageTokenKey = 'apiToken';
  private apiEndpoint = environment.APIEndpoint;

  public username: string;
  public role: Role;

  public get token(): string {
    const t = localStorage.getItem(this.storageTokenKey);
    return t
  }


  public set token(value: string) {
    localStorage.setItem(this.storageTokenKey, value);
  }

  constructor(private http: HttpClient) { }

  private setFromToken(){
    try{
      const segments = this.token.split('.');
      // console.log(' DEBUG ', atob(segments[1]));
      this.role = JSON.parse(atob(segments[1])).role;
      this.username = JSON.parse(atob(segments[1])).username;
    }catch(err){
      console.log(" can't parse the current token ", err );
    }
  }

  

  public async login(loginname: string, password: string): Promise<any> {
    try {
      const {message, token} = await this.http.post<AuthResponse>(this.apiEndpoint + '/login', {loginname, password}).toPromise();
      console.log('Login message:', message);
      if (token) {
        this.token = token;
        this.setFromToken();
        return {success: true};
      }
      return {success: false, msg: 'Login fehlgeschlagen - kein Token '};
    } catch (err) {
      if (err === "Unauthorized") {
        return {success: false, msg: 'Login fehlgeschlagen - Nutzername und/oder Passwort stimmen nicht. '};
      }
      return {success: false, msg: err};
    }
  }

  public logout() {
    localStorage.removeItem(this.storageTokenKey);
    this.role = null;
    this.username = null;
    this.token = null;
  }

  public isAuthenticated(): boolean {
     if (this.token && !isJwtExpiredUnsafe(this.token)){
       if (!this.role || !this.username){
         this.setFromToken();
       }
       return true;
     }else{
       return false;
     }
  }

}
