<app-header-bar [title]="'Live Status'"></app-header-bar>

<div
  class="subheader-menu p-d-flex p-jc-end p-mb-4"
>
  <span class="date-label p-justify-end">{{ lastStateTimestampDisplay }}</span>
</div>

<div class="main-container" *ngIf="!noConnectionToCloudBackend">
  <div class="live-status-title p-d-flex p-jc-around p-ac-center p-pb-4">
    <div class="p-text-center machine">Anlage</div>
    <div class="p-text-center speed">Geschwindigkeit</div>
    <div class="p-text-center amount-container-day">Produzierte Menge (Tag)</div>
    <div class="p-text-center amount-container">Produzierte Menge heute (PO)</div>
    <div class="p-text-center process">Prozess</div>
  </div>
  <app-machine-data-component
    *ngFor="let currentMachineData of machineData$ | async"
    [machineName]="currentMachineData.machine"
    [machineType]="currentMachineData.machineType"
    [quantity]="currentMachineData.quantity"
    [currentBpm]="currentMachineData.currentBpm"
    [defaultMaxBpm]="currentMachineData.defaultMaxBpm"
    [eventLabel]="currentMachineData.eventLabel"
    [eventColor]="currentMachineData.eventColor"
    [lastEventTimestamp]="currentMachineData.eventStartTimestamp"
    [unitsPerContainer]="currentMachineData.unitsPerContainer"
    [connectionErrorStatus]="currentMachineData.connectionErrorStatus"
  >
  </app-machine-data-component>
</div>
<div class="main-container" *ngIf="noConnectionToCloudBackend">
  <h4>{{noConnectionToCloudBackend}}</h4>
</div>
