import { Component, OnInit } from "@angular/core";
import { PackagingService } from "app/services/packaging.service";
import { Packaging } from "app/models/packaging";
import { AuthService } from 'app/services/auth.service';
import { Role } from 'common/Auth/Role';

@Component({
  selector: "app-packaging-data",
  templateUrl: "./packaging-data.component.html",
  styleUrls: ["./packaging-data.component.scss"],
})
export class PackagingDataComponent implements OnInit {
  packagingData: Packaging[];

  packagingList = [];

  displayAddPackagingDialog: boolean = false;
  packaging: Packaging[];
  newPackagingName;
  newPackagingAmount;
  newPackagingUnitsPerContainer;
  newPackagingPackType;

  columnsPackagingTable = [
    { field: "name", header: "Name" },
    { field: "amountInLiters", header: "Menge in Liter" },
    { field: "unitsPerContainer", header: "Gebindeeinheit" },
    { field: "packType", header: "Packtyp" },
  ];

  public role: Role;
  public Role = Role;  // otherwise not accessable in the template

  constructor(private authService: AuthService, private packagingService: PackagingService) {
    this.role = this.authService.role;
  }


  async ngOnInit() {
    await this.getPackagings();
  }

  showDisplayAddPackagingDialog() {
    this.displayAddPackagingDialog = true;
  }

  clearAllNewItemData() {
    this.newPackagingName = null;
    this.newPackagingAmount = null;
    this.newPackagingUnitsPerContainer = null;
    this.newPackagingPackType = null;
  }

  async savePackaging() {
    const packaging = new Packaging(
      null,
      this.newPackagingName,
      this.newPackagingAmount,
      this.newPackagingUnitsPerContainer,
      this.newPackagingPackType,
      null,
      null
    );
    await this.packagingService.postPackaging(packaging);
    this.clearAllNewItemData();
    await this.getPackagings();
    this.displayAddPackagingDialog = false;
  }

  async getPackagings() {
    this.packagingData = await this.packagingService.getPackagings();
  }
}
