import { Component, OnInit } from '@angular/core';
import { Machine } from 'app/models/machine';
import { SetupType } from 'app/models/setup-type';
import { AuthService } from 'app/services/auth.service';
import { MachineService } from 'app/services/machine.service';
import { SetupTypesService } from 'app/services/setup-types.service';
import { Role } from 'common/Auth/Role';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: "app-setup-types",
  templateUrl: "./setup-types.component.html",
  styleUrls: ["./setup-types.component.scss"],
})
export class SetupTypesComponent implements OnInit {
  setupTypesData: SetupType[];
  machineList = [];

  machines: Machine[];

  displayAddSetupTypeDialog: boolean = false;

  public setEditFunctionForDialog: boolean;
  public newSetupTypeMachine: Machine;
  public newSetupTypeFirstLevel;
  public newSetupTypeSecondLevel;
  public newSetupTypeThirdLevel;
  public newSetupTypeTargetTimeUnequip;
  public newSetupTypeTargetTimeSetup;
  public newSetupTypeTargetTimeLab;
  public newSetupTypeTargetTimeWaitForStart;
  public newSetupTypeIsActive;
  public editedSetupTypeId;
  public showInactiveSetupTypes = false;

  private selectedSetupTypeIsActive;

  columnsTargetTimesTable = [
    {field: "machineName", header: "Anlage"},
    {field: "firstLevel", header: "Ebene 1"},
    {field: "secondLevel", header: "Ebene 2"},
    {field: "thirdLevel", header: "Ebene 3"},
    {field: "targetTimeUnequip", header: "Sollzeit Abrüsten"},
    {field: "targetTimeSetup", header: "Sollzeit Rüsten Filling"},
    {field: "targetTimeLab", header: "Sollzeit Freigabe Labor"},
    {
      field: "targetTimeWaitForStart",
      header: "Sollzeit Verzögerung Fülllbeginn",
    },
    {field: "validFromFormatted", header: "Gültig seit"},
    {field: "isActiveTranslation", header: "Aktiv"},
  ];

  public booleanDropDownOptions = [
    {label: "Ja", value: true},
    {label: "Nein", value: false},
  ];

  public role: Role;
  public Role = Role;  // otherwise not accessable in the template


  constructor(
    private authService: AuthService, 
    private setupTypesService: SetupTypesService,
    private machineService: MachineService,
    private confirmationService: ConfirmationService
  ) {
    this.role = this.authService.role;

  }

  async ngOnInit() {
    await this.getSetupTypes();
    this.machines = await this.machineService.getMachines();
  }

  showDisplayAddSetupTypeDialog() {
    this.displayAddSetupTypeDialog = true;
  }

  clearNewSetupType() {
    this.newSetupTypeMachine = null;
    this.newSetupTypeFirstLevel = null;
    this.newSetupTypeSecondLevel = null;
    this.newSetupTypeThirdLevel = null;
    this.newSetupTypeTargetTimeUnequip = null;
    this.newSetupTypeTargetTimeSetup = null;
    this.newSetupTypeTargetTimeLab = null;
    this.newSetupTypeTargetTimeWaitForStart = null;
    this.newSetupTypeIsActive = null;
    this.selectedSetupTypeIsActive = null;
    this.setEditFunctionForDialog = false;
  }

  async getSetupTypes() {
    const setupTypes = await this.setupTypesService.getSetupTypes();
    const machineListOptions = {};
    this.setupTypesData = setupTypes
      .filter((setupType: SetupType) => {
        return this.showInactiveSetupTypes ? setupType : setupType.isActive
      })
      .map((setupType: SetupType) => {
      setupType["isActiveTranslation"] = setupType.isActive ? "Ja" : "Nein";
      setupType["machineName"] = setupType.machine
        ? setupType.machine.name
        : "";
      setupType["validFromFormatted"] = moment(setupType.validFrom).format(
        "DD.MM.YYYY HH:mm:ss"
      );
      if (!machineListOptions[setupType["machineName"]]) {
        machineListOptions[setupType["machineName"]] = {
          label: setupType["machineName"],
          value: setupType["machineName"],
        };
      }
      return setupType;
    });
    this.machineList = [{label: "Alle", value: null}];
    for (const key in machineListOptions) {
      if (machineListOptions.hasOwnProperty(key)) {
        this.machineList.push(machineListOptions[key]);
      }
    }
  }

  onEditSetupType(setupType: SetupType) {
    this.selectedSetupTypeIsActive = setupType.isActive;
    this.displayAddSetupTypeDialog = true;
    this.setEditFunctionForDialog = true;
    this.editedSetupTypeId = setupType._id;
    this.newSetupTypeMachine = setupType.machine;
    this.newSetupTypeFirstLevel = setupType.firstLevel;
    this.newSetupTypeSecondLevel = setupType.secondLevel;
    this.newSetupTypeThirdLevel = setupType.thirdLevel;
    this.newSetupTypeTargetTimeUnequip = setupType.targetTimeUnequip;
    this.newSetupTypeTargetTimeSetup = setupType.targetTimeSetup;
    this.newSetupTypeTargetTimeLab = setupType.targetTimeLab;
    this.newSetupTypeTargetTimeWaitForStart = setupType.targetTimeWaitForStart;
    this.newSetupTypeIsActive = setupType.isActive;
  }

  async saveSetupType() {
    const newSetupType: SetupType = {
      _id: null,
      machine: this.newSetupTypeMachine,
      firstLevel: this.newSetupTypeFirstLevel,
      secondLevel: this.newSetupTypeSecondLevel,
      thirdLevel: this.newSetupTypeThirdLevel,
      targetTimeUnequip: this.newSetupTypeTargetTimeUnequip,
      targetTimeSetup: this.newSetupTypeTargetTimeSetup,
      targetTimeLab: this.newSetupTypeTargetTimeLab,
      targetTimeWaitForStart: this.newSetupTypeTargetTimeWaitForStart,
      validFrom: null,
      isActive: this.newSetupTypeIsActive,
      eventDescription: null,
    };

    if (this.setEditFunctionForDialog === true) {
      newSetupType._id = this.editedSetupTypeId;
      await this.setupTypesService.editSetupType(newSetupType);
    } else {
      await this.setupTypesService.postSetupType(newSetupType);
    }
    this.clearNewSetupType();
    await this.getSetupTypes();
    this.displayAddSetupTypeDialog = false;
    this.setEditFunctionForDialog = false;
  }

  async onInActivate(setupType: SetupType) {
    this.confirmationService.confirm({
      message: 'Soll dieser Rüsttyp wirklich deaktiviert werden?',
      accept: async () => {
        await this.setupTypesService.inactivateSetupType(setupType);
        await this.getSetupTypes();
      },
      key: "setupTypeConfirmDialog"
    })
  }
}
