import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpJwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skip interception if the call is to the /login route bc. Basic auth is used there
        const urlSegments = request.url.split('/');
        const endpoint = urlSegments[urlSegments.length - 1];
        if (endpoint === 'login') {
            return next.handle(request);
        }
        // otherwise add JWT to Bearer
        const token = this.authService.token;
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        } else {
          this.router.navigate(['/login']).catch(console.log);
        }

        return next.handle(request);
    }
}
