import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-filling-amount-chart',
  template: '<div [chart]="chart"></div>',
})

export class FillingAmountChartComponent implements OnInit {
  chart: Chart;

  @Input()
  set data({ amountFilling, amountPo }: { amountFilling: number, amountPo: number, difference: number }) {

    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }

    if (amountFilling > amountPo) {
      this.chart.addSeries({
        type: 'pie',
        name: 'Kartons',
        data: [
          { y: amountFilling - amountPo, name: 'ÜBER' },
          { y: amountPo, name: 'SOLL' }
        ],
        colors: ['#6A8DB6', '#3a7248']
      }, true, true);
    } else {
      this.chart.addSeries({
        type: 'pie',
        name: 'Kartons',
        data: [
          { y: amountFilling, name: 'IST' },
          { y: amountPo - amountFilling, name: 'REST' },
        ],
        colors: ['#3a7248', '#CCCCCC']
      }, true, true);
    }
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: 180,
        height: 180
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          colors: ['#01AA4F', '#777777']
        }
      },
      exporting: { enabled: false },
      series: []
    });
  }

  ngOnInit() {
  }
}
