<div class="p-d-flex p-flex-row p-jc-center p-pb-2">
  <p-button 
    (click)="showDisplayAddTanktypeDialog()"
    *ngIf="[Role.ADMIN].includes(role)"
  >
    Neuer Tanktyp
  </p-button>
</div>
<p-table
  *ngIf="tanktypeData"
  [columns]="columnsTanktype"
  [value]="tanktypeData"
  [paginator]="tanktypeData?.length > 15"
  [rows]="15"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
      <th>aktiv</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>


      <td>
        <div 
          *ngIf="[Role.ADMIN].includes(role)"
          class="p-d-flex p-dir-row p-jc-evenly">
          <img
            [class]="rowData.isActive ? 'cursor-pointer' : null"
            [src]="
              rowData.isActive
                ? './assets/icons/toggle-on.svg'
                : './assets/icons/toggle-off.svg'
            "
            (click)="toggleActive(rowData._id)"
            alt=""
          />
          </div>
      </td>

    </tr>
  </ng-template>
</p-table>

<!-- add packaging dialog -->

<p-dialog
  header="Neuen Tanktyp hinzufügen"
  [(visible)]="displayAddTanktypeDialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <div>
    <form (ngSubmit)="saveTanktype()" #packagingForm="ngForm">

      <div class="p-field p-grid">
        <label class="p-col-5" for="packagingName">Name</label>
        <div class="p-col">
          <input
            class="form-control"
            id="packagingName"
            name="packagingName"
            type="text"
            pInputText
            required
            [(ngModel)]="newPackagingName"
          />
        </div>
      </div>

      <div class="p-d-flex p-jc-end">
        <div>
          <button 
            type="button"
            pButton
            [disabled]="!packagingForm.valid"
            icon="fa fa-check"
            (click)="saveTanktype()"
            label="Speichern"
          ></button>
        </div>
      </div>
        
    </form>
  </div>
</p-dialog>
