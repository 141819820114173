import {Component, OnInit} from '@angular/core';
import {Roles} from 'app/models/roles';
import {User} from 'app/models/user';
import {take} from 'rxjs/operators';
import {UserService} from '../../services/user.service';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  Roles = Roles;
  userData = [];
  public columnsUser = [
    {field: 'username', header: 'Benutzername'},
    {field: 'role', header: 'Rolle'},
    {field: 'resetpw', header: 'Passwort-Reset'},
  ];
  public showResetPasswordDialog = false;
  public newPassword: string;
  public selectedUser: User;
  public showPassword = false;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.getUsers().pipe(take(1)).subscribe(users => {
      this.userData = users;
    });
  }

  public clearPassword(){
    this.newPassword = null;
    this.selectedUser = null;
  }

  public onClickResetPassword(user: User) {
    this.showResetPasswordDialog = true;
    this.selectedUser = user;
  }

  public saveNewPassword() {
    this.userService.resetPassword(this.newPassword, this.selectedUser._id).pipe(take(1)).subscribe(() => {
      this.showResetPasswordDialog = false;
    });
  }
}
