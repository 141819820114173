import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { SetupColors } from '../../../models/colors';
import { Utils } from '../../../utils';
import {ProcessPhaseLabel} from '../../../models/process-phase';

@Component({
  selector: 'app-setup-evaluation-chart',
  template: '<div [chart]="chart"></div>',
})

export class SetupEvaluationChartComponent implements OnInit {

  chart: Chart;

  @Input()
  set data(equipData: {
    failureEvents: {event: string, durationInMinutes: number}[],
    setupEvents: {event: string, durationInMinutes: number}[],
  }) {

    if (JSON.stringify(equipData) === JSON.stringify({})) {
      return;
    }
    
    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }
    // add categories
    const convertedEquipEvents = [];
    const convertedFailureEvents = [];

    equipData.failureEvents.forEach(e => {
      convertedFailureEvents.push([e.event, Utils.round(e.durationInMinutes, 2)]);
    });
    equipData.setupEvents.forEach(e => {
      let label = e.event;
      let color;
      if (ProcessPhaseLabel[e.event]) label = ProcessPhaseLabel[e.event];
      if (SetupColors[e.event]) color = SetupColors[e.event];
      convertedEquipEvents.push([label, Utils.round(e.durationInMinutes, 2), SetupColors[e.event], color]);
    });

    convertedFailureEvents.sort((a, b) => a[1] - b[1]);
    convertedFailureEvents.forEach(data => {
      this.chart.addSeries({
        type: 'column',
        name: data[0],
        data: [Utils.round(data[1], 2)],
        color: data[2],
        stack: 'FaultData'
      }, true, true);
    });
    convertedEquipEvents.sort((a, b) => a[1] - b[1]);
    convertedEquipEvents.forEach(data => {
      this.chart.addSeries({
        type: 'column',
        name: data[0],
        data: [Utils.round(data[1], 2)],
        color: data[2],
        stack: 'EquipData'
      }, true, true);
    });
 
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        type: 'column',
        height: 200
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0
      },
      yAxis: {
        title: {
          text: 'Zeiten in Min'
        },
      },
      series: [],
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '{series.name}<br />',
        pointFormat: '{point.y:.2f} min'
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: 'white',
            inside: true,
            format: '{series.name}',
          },
        }
      }
    });
  }

  ngOnInit() {
  }
}
