import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment } from '../../environments/environment';
import {TankType} from '../models/tank-type'


@Injectable()
export class TankTypeService {

  dataAPIEndpoint = environment.APIEndpoint + '/tanktype';

  constructor(private http: HttpClient) { }


  getTankTypes() {
    return this.http.get<TankType[]>(this.dataAPIEndpoint + '/');
  }

  toggleActivation(tankType: TankType) {
    return this.http.put<TankType>(this.dataAPIEndpoint + '/toggleactive', tankType);
  }

  
  postTankType(tankType: TankType) {
    return this.http.post<TankType>(this.dataAPIEndpoint + '/', tankType);
  }


}
