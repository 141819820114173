import {Component, OnInit, OnDestroy} from '@angular/core';
import {DataService} from '../../services/data.service';
import {BehaviorSubject} from 'rxjs';
import {ProcessPhase, ProcessPhaseLabel} from '../../models/process-phase';
import { GlobalStateForCockpit } from '../../models/globalStateForCockpit'
import * as moment from 'moment';

@Component({
  selector: 'app-machine-live-overview-component',
  templateUrl: './machine-live-overview.component.html',
  styleUrls: ['./machine-live-overview.component.scss']
})
export class MachineLiveOverviewComponent implements OnInit, OnDestroy {

  lastStateTimestampDisplay = 'Warte auf Info...';
  machines: any = {};
  color: string;

  public machineData$ = new BehaviorSubject([]);
  public noConnectionToCloudBackend: string = null;

  updateInterval;

  private machineOrder = ['Anlage2', 'Anlage 3', 'Anlage 4', 'Anlage 5', 'Anlage 13', 'Anlage 8', 'Anlage 9', 'Wickler 1', 'Wickler 2'];

  constructor(public dataService: DataService) {
  }

  async ngOnInit() {
    await this.fetchData();
    this.updateInterval = setInterval(this.fetchData.bind(this), 10000);
  }

  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }

  async fetchData() {

    try {
      const res: GlobalStateForCockpit[] = await this.dataService.getLiveSpeedDate();
      this.lastStateTimestampDisplay = moment().format('DD.MM.YYYY - HH:mm:ss');
      let eventLabel: string;
      let eventColor: string;
      let eventStartTimestamp: Date;
      const dataObjectArray = res.map((machineGlobalState) => {
        if (machineGlobalState.failureStartTimestamp) {
          eventLabel = machineGlobalState.failureName ?? 'Die aktuelle Störung wurde noch nicht identifiziert';
          eventColor = 'red';
          eventStartTimestamp = machineGlobalState.failureStartTimestamp;
        } else if (machineGlobalState.currentProcessPhase) {
          eventLabel = ProcessPhaseLabel[machineGlobalState.currentProcessPhase];
          switch (machineGlobalState.currentProcessPhase) {
            case ProcessPhase.BETWEEN_PO:
              eventColor = 'grey';
              break;
            case ProcessPhase.RUNNING:
              eventColor = 'green';
              break;
            default:
              eventColor = 'blue';
          }
          eventStartTimestamp = machineGlobalState.currentProcessPhaseStartTimestamp;
        }
  
        return {
          eventLabel: eventLabel,
          eventColor: eventColor,
          eventStartTimestamp: eventStartTimestamp,
          currentBpm: machineGlobalState.currentBpm,
          connectionErrorStatus: machineGlobalState.connectionErrorStatus,
          defaultMaxBpm: machineGlobalState.defaultMaxBpm,
          machine: machineGlobalState.machineName,
          machineType: machineGlobalState.machineType,
          unitsPerContainer: machineGlobalState.unitsPerContainer ?? 1,
          quantity: {
            actualAmountToday: machineGlobalState.actualAmountToday,
            rawAmountFillingProcessInUnits: machineGlobalState.rawAmountFillingProcessInUnits,
            targetAmountPoInUnits: machineGlobalState.targetAmountPoInUnits,
            targetAmountToday: machineGlobalState.targetAmountToday
          }
        };
      }).sort( (a, b) => {
        return this.machineOrder.indexOf(a.machine) - this.machineOrder.indexOf(b.machine);
      });
      this.noConnectionToCloudBackend = null;

      this.machineData$.next(dataObjectArray);
    } catch (err) {
      this.noConnectionToCloudBackend = err;
    }

  }
}

