<app-header-bar [title]="'Konfigurationen'"></app-header-bar>
<div class="main-container p-col-12">
  <p-tabView (onChange)="handleTabViewChange($event)">
    <!-- failure hierarcy -->

    <p-tabPanel header="Fehler Hierarchie">
      <div class="subheader p-d-flex p-jc-between">
        <div class="subheader-left">
          <p-dropdown
            [options]="selectionService.machineList"
            [ngModel]="selectionService.forMachine"
            (ngModelChange)="machineSelectionChanged($event)"
            placeholder="Anlage wählen"
          >
            <ng-template pTemplate="machine" let-machine>
              <div>
                <span>{{ machine.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <p-checkbox
            [ngModel]="includeInactiveCategories"
            (ngModelChange)="toggleShowInactiveCategories()"
            label="Inaktive Kategorien anzeigen"
            binary="true"
          ></p-checkbox>
        </div>
        <div class="subheader-buttons">
          <div *ngIf="selectedFailure && [Role.ADMIN].includes(role)">
            <p-button
              type="button"
              label="Deaktivieren"
              *ngIf="!selectedFailure?.label.includes('Inaktiv')"
              class="p-button-sm p-ml-1"
              (onClick)="onRemoveFailureCategory()"
            >
            </p-button>
            <p-button
              type="button"
              label="Aktivieren"
              *ngIf="selectedFailure?.label.includes('Inaktiv')"
              class="p-button-sm p-ml-1"
              (onClick)="onAddFailureCategoryWithoutChildren()"
            >
            </p-button>
            <p-button
              type="button"
              label="Kategorie + Unterkategorien aktivieren"
              *ngIf="
                selectedFailure?.label.includes('Inaktiv') &&
                selectedFailure?.children &&
                selectedFailure?.children.length > 0
              "
              class="p-button-sm p-ml-1"
              (onClick)="onAddFailureCategoryWithChildren()"
            >
            </p-button>
            <p-button
              type="button"
              label="Unterkategorien"
              *ngIf="
                selectedFailure?.children &&
                !selectedFailure?.label.includes('Inaktiv')
              "
              class="p-button-sm p-ml-1"
              icon="fa fa-plus"
              (onClick)="onCreateChildren()"
            ></p-button>
            <p-button
              type="button"
              icon="fa fa-close"
              class="p-button-sm p-ml-1"
              (onClick)="onCancelSelectedFailure()"
            ></p-button>
          </div>
        </div>
      </div>
      <div class="main-container">
        <p-tree
          [value]="failureHierarchy"
          layout="horizontal"
          selectionMode="single"
          [(selection)]="selectedFailure"
        ></p-tree>
      </div>
    </p-tabPanel>

    <!-- opening times -->

    <p-tabPanel header="Werksöffnung">
      <div class="subheader-menu">
        <p-multiSelect
          [defaultLabel]="
            selectionService.selectedMachines?.length === 0
              ? 'Auswahl der Anlagen'
              : '{0} Anlage(n) ausgewählt'
          "
          [options]="selectionService.machineList"
          [ngModel]="selectionService.selectedMachines"
          (ngModelChange)="selectionService.selectedMachinesChanged($event)"
          [displaySelectedLabel]="true"
          [maxSelectedLabels]="0"
          [selectedItemsLabel]="'{0} Anlage(n) ausgewählt'"
          (onChange)="getAllSpecialDays()"
        ></p-multiSelect>
      </div>
      <div class="operation-times-calender p-d-flex p-jc-center">
        <p-calendar
          *ngIf="specialDays"
          dateFormat="dd.mm.yy"
          [(ngModel)]="selectedDate"
          [locale]="selectionService.de"
          styleClass="large-calendar"
          [inline]="true"
        >
          <ng-template pTemplate="date" let-date>
            <span
              (click)="addDay(date)"
              [ngClass]="specialDaysOfYearAndMonth[date.year] 
                         && specialDaysOfYearAndMonth[date.year][date.month] 
                         && specialDaysOfYearAndMonth[date.year][date.month].includes(date.day) ? 'mark-background' : ''"
              class="calendar-cell"
              >{{ date.day }}</span
            >
          </ng-template>
        </p-calendar>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<!-- edit date dialog -->

<p-dialog [(visible)]="editDate" class="width-600">
  <p-header>
    Öffnungszeit bearbeiten -
    {{
      editDate ? (editDate?.date | date: "dd.MM.yyyy") : "Kein Datum ausgewählt"
    }}
  </p-header>
  <div id="modal-content-operation-times">
    <div *ngIf="editDate?.times">
      <div
        class="p-grid p-fluid p-d-flex p-jc-between p-ai-center p-pt-3"
        *ngFor="let time of editDate?.times; index as i"
      >
        <div class="p-col-1">
          <label for="start">Beginn:</label>
        </div>
        <div class="p-col-4">
          <p-calendar
            [(ngModel)]="time.startTime"
            timeOnly="true"
            hourFormat="24"
            id="start"
            appendTo="body"
          >
          </p-calendar>
        </div>
        <div class="p-col-1">
          <label for="end">Ende:</label>
        </div>
        <div class="p-col-4">
          <p-calendar
            [(ngModel)]="time.endTime"
            timeOnly="true"
            hourFormat="24"
            id="end"
            appendTo="body"
          >
          </p-calendar>
        </div>

        <div class="p-col-1">
          <p-button
            class="p-button-sm"
            type="button"
            icon="fa fa-minus"
            (onClick)="removeOperationTime(i)"
          ></p-button>
        </div>
        <div class="p-col-1">
          <p-button
            *ngIf="i === editDate?.times.length - 1"
            class="p-button-sm"
            type="button"
            icon="fa fa-plus"
            (onClick)="addOperationTime()"
          ></p-button>
        </div>
      </div>
    </div>

    <div
      class="p-pt-4 p-text-center width-600"
      *ngIf="editDate ? editDate?.times.length === 0 : editDate"
    >
      <span>Dieser Tag hat keine Arbeitszeiten.</span>
    </div>
  </div>
  <div class="p-pt-4 p-text-center">
  <span *ngIf="faultyTimesForSpecialDayExist" class="times-error"
    >Bitte in eine korrekte Reihenfolge und keine Überschneidungen der
    Zeiträume.</span
  >
  </div>

  <p-footer>
    <div class="p-d-flex p-jc-end">
      <p-button
        *ngIf="!isNewSpecialDay"
        [disabled]="currentlySaving"
        class="float-left "
        type="button"
        (onClick)="removeSpecialDay()"
        icon="fa fa-trash"
        label=" Sondertag löschen"
      ></p-button>
      <p-button
        *ngIf="editDate ? editDate?.times.length == 0 : editDate"
        type="button"
        (onClick)="addOperationTime()"
        icon="fa fa-plus"
        label=" Zeitraum hinzufügen"
      ></p-button>
      <p-button
        *ngIf="editDate ? editDate?.times.length > 0 : editDate"
        type="button"
        icon="fa fa-ban"
        (onClick)="removeAllTimeForDay()"
        label=" Kein Arbeitstag"
      ></p-button>
        <p-button
        type="button"
        icon="fa fa-check"
        [disabled]="currentlySaving"
        (onClick)="saveSpecialDay()"
        label=" Speichern"
      ></p-button>

    </div>
  </p-footer>
</p-dialog>

<!-- add category dialog -->

<p-dialog
  [(visible)]="openAddChildrenDialogue"
  [blockScroll]="true"
  showEffect="fade"
  [modal]="true"
  styleClass="width-800 overflow-auto"
  [closable]="true"
>
  <p-header>
    {{
      selectedFailure?.label ? selectedFailure?.label + ": " : ""
    }}Unterkategorien zufügen
  </p-header>
  <div id="modal-content-category">
    <div
      class="p-grid p-fluid p-d-flex p-jc-between p-ai-center p-pt-3"
      *ngFor="let child of selectedFailure?.newChildren; index as i"
    >
      <div class="p-col-1">
        <label for="label">Label:</label>
      </div>
      <div class="p-col-4">
        <input
          id="label"
          type="text"
          pInputText
          placeholder="Bitte Label eingeben..."
          [(ngModel)]="child.label"
        />
      </div>
      <div class="p-col-1">
        <label for="isEventDescr">Typ:</label>
      </div>
      <div class="p-col-4">
        <p-dropdown
          id="isEventDescr"
          [options]="
          selectedFailure?.label ? [
            { label: 'Kategorie', value: false },
            { label: 'Störgrund', value: true }
          ]: [
          { label: 'Kategorie', value: false }
          ]"
          [(ngModel)]="child.isEventDescr"
          appendTo="body"
        >
        </p-dropdown>
      </div>

      <div class="p-col-1">
        <p-button
          class="p-button-sm"
          type="button"
          icon="fa fa-minus"
          (onClick)="onRemoveChild(i)"
        ></p-button>
      </div>
      <div class="p-col-1">
        <p-button
          *ngIf="i === selectedFailure?.newChildren.length - 1"
          class="p-button-sm"
          type="button"
          icon="fa fa-plus"
          (onClick)="onAddNewChild()"
        ></p-button>
      </div>
    </div>
    <div class="addCategoryDialog p-grid p-fluid">
      <div class="p-offset-11 p-col-1"></div>
    </div>
  </div>
  <p-footer>
    <div class="p-d-flex p-jc-end">
      <p-button
        type="button"
        icon="fa fa-check"
        (onClick)="saveChildrenForCategory()"
        label=" Speichern"
      ></p-button>
    </div>
  </p-footer>
</p-dialog>
