import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-machine-amount-chart',
  template: '<div [chart]="chart"></div>',
})

export class MachineAmountChartComponent implements OnInit {
  chart: Chart;

  @Input()
  set data({ currentInBoxes, targetInBoxes }: { currentInBoxes: number, targetInBoxes: number, difference: number }) {

    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }

    if (currentInBoxes > targetInBoxes) {
      this.chart.addSeries({
        type: 'pie',
        name: 'Kartons',
        data: [
          { y: currentInBoxes - targetInBoxes, name: 'ÜBER' },
          { y: targetInBoxes, name: 'SOLL' }
        ],
        colors: ['#99CC00', '#3a7248']
      }, true, true);
      
    } else {
      this.chart.addSeries({
        type: 'pie',
        name: 'Kartons',
        data: [
          { y: currentInBoxes, name: 'IST' },
          { y: targetInBoxes - currentInBoxes, name: 'REST' },
        ],
        colors: ['#3a7248', '#CCCCCC']
      }, true, true);
      
    }
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: 150,
        height: 150
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          colors: ['#01AA4F', '#777777']
        }
      },
      exporting: { enabled: false },
      series: []
    });
  }

  ngOnInit() {
  }
}
