import {Component, Input} from '@angular/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent {

  @Input('title') title: string;
  public noBranding = environment.noBranding;

  constructor() {
  }
}
