import { Component, OnInit } from '@angular/core';
import { Machine } from 'app/models/machine';
import { AuthService } from 'app/services/auth.service';
import { MachineService } from 'app/services/machine.service';
import { Role } from 'common/Auth/Role';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit {
  machines: Machine[];

  public displayDialog = false;
  public selectedMachine: Machine;
  public tabletIsConnected = false;

  public role: Role;
  public Role = Role;  // otherwise not accessable in the template

  constructor(private authService: AuthService, private machineService: MachineService) {
    this.role = this.authService.role;
  }


  async ngOnInit(){
    this.machines = await this.machineService.getMachines();
  }

  onEditMachine(machine: Machine) {
    this.selectedMachine = {...machine};
    this.tabletIsConnected = this.selectedMachine.socketID != null;
    this.displayDialog = true;
  }

  async saveMachineSettings() {
    let tabletConnection;
    if (this.tabletIsConnected) {
      tabletConnection = this.selectedMachine.socketID;
    } else {
      tabletConnection = null;
    }
    const editedMachine = new Machine(
      this.selectedMachine._id,
      this.selectedMachine.name,
      this.selectedMachine.isActive,
      this.selectedMachine.DIport,
      this.selectedMachine.DIhost,
      tabletConnection,
      this.selectedMachine.restartDelayTime,
      this.selectedMachine.slowDownEventTime,
      this.selectedMachine.shortStopDuration,
      this.selectedMachine.machineType,
      this.selectedMachine.accumulateTimeFactor,
      this.selectedMachine.defaultMaxBpm,
      this.selectedMachine.defaultUnitsPerContainer,
      this.selectedMachine.createdAt,
      this.selectedMachine.updatedAt
    );

    await this.machineService.updateMachine(editedMachine);
    this.machines = await this.machineService.getMachines();
    this.displayDialog = false;
  }

}
