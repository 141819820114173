<app-header-bar [title]="'NoStop Screen'"></app-header-bar>
<div class="main-container p-grid p-col-12">
  <!-- Beginning of No Clearance Table  -->
  <p-table
    [columns]="columnsNoStopScreen"
    [value]="noStopData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-3">
        NoStop Aufträge ohne Freigabe
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; let idx = index">
          <th [ngClass]="(idx === 3 ) ? 'p-col-2' : 'p-col-1'">
            {{ col.header }} 
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr *ngIf="rowData.clearance == null" style="background-color: #e6c229">
        <td>{{ rowData.start | date: "dd.MM.yyyy, HH:mm" }}</td>
        <td>{{ rowData.machine }}</td>
        <td>{{ rowData.sku }}</td>
        <td>{{ rowData.sku | skuToSkuDescription | async}}</td>

        <td>Freigabe ausstehend</td>
        <td>{{ rowData.clearanceTimestamp | date: "dd.MM.yyyy, HH:mm" }}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- End of No Clearance Table -->

  <!-- Beginning of Clearance Table  -->
  <p-table
    [columns]="columnsNoStopScreen"
    [value]="noStopData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-3">Freigaben</div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; let idx = index">
          <th [ngClass]="(idx === 3 ) ? 'p-col-2' : 'p-col-1'">
            {{ col.header }} 
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr *ngIf="rowData.clearance == true" style="background-color: #009933">
        <td>{{ rowData.start | date: "dd.MM.yyyy, HH:mm" }}</td>
        <td>{{ rowData.machine }}</td>
        <td>{{ rowData.sku }}</td>
        <td>{{ rowData.sku | skuToSkuDescription | async}}</td>

        <td>Pass</td>
        <td>{{ rowData.clearanceTimestamp | date: "dd.MM.yyyy, HH:mm" }}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- End of Clearance Table -->

  <!-- Beginning of Fails Table -->
  <p-table
    [columns]="columnsNoStopScreen"
    [value]="noStopData"
    [responsive]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-text-center p-pt-3">Fails</div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns; let idx = index">
          <th [ngClass]="(idx === 3 ) ? 'p-col-2' : 'p-col-1'">
            {{ col.header }} 
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr *ngIf="rowData.clearance == false" style="background-color: #ff0000">
        <td class="blink-effect">{{ rowData.start | date: "dd.MM.yyyy, HH:mm" }}</td>
        <td class="blink-effect">{{ rowData.machine }}</td>
        <td class="blink-effect">{{ rowData.sku }}</td>
        <td>{{ rowData.sku | skuToSkuDescription | async}}</td>

        <td class="blink-effect">Fail</td>
        <td class="blink-effect">
          {{ rowData.clearanceTimestamp | date: "dd.MM.yyyy, HH:mm" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- End of Fails Table -->
</div>
