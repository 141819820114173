<div div class="p-d-flex p-flex-row p-jc-center p-pb-2">
  <p-button
    *ngIf="[Role.ADMIN].includes(role)"
    (click)="showDisplayAddSetupTypeDialog()"
    class="p-mr-5"
  >
    Neuer Rüsttyp
  </p-button>
  <p-checkbox
    [(ngModel)]="showInactiveSetupTypes"
    [binary]="true"
    (ngModelChange)="getSetupTypes()"
    label="Inaktive Rüsttypen anzeigen"
  >
  </p-checkbox>
</div>
<p-table
  #targetTimes
  [columns]="columnsTargetTimesTable"
  [value]="setupTypesData"
  [paginator]="setupTypesData?.length > 15"
  [rows]="15"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngSwitch]="col.field">
        {{ col.header }}
        <p-dropdown
          *ngSwitchCase="'machineName'"
          [options]="machineList"
          [style]="{ width: '100%' }"
          (onChange)="targetTimes.filter($event.value, col.field, 'equals')"
        ></p-dropdown>
      </th>
      <th>
        <span *ngIf="[Role.ADMIN].includes(role)">Bearbeiten</span>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
      <td>
        <div 
          *ngIf="[Role.ADMIN].includes(role)"
          class="p-d-flex p-dir-row p-jc-evenly">
          <img
            [class]="rowData.isActive ? 'cursor-pointer' : null"
            [src]="
              rowData.isActive
                ? './assets/icons/toggle-on.svg'
                : './assets/icons/toggle-off.svg'
            "
            (click)="rowData.isActive ? onInActivate(rowData) : null"
            alt=""
          />

          <!-- edit icon -->
          <svg
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            (click)="rowData.isActive ? onEditSetupType(rowData) : null"
            [ngStyle]="
              rowData.isActive ? { cursor: 'pointer' } : { fill: '#C0C0C0' }
            "
          >
            <path
              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
            />
          </svg>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- add / edit setup type button -->

<p-dialog
  [header]="
    setEditFunctionForDialog ? 'Rüsttyp bearbeiten' : 'Rüsttyp hinzufügen'
  "
  [(visible)]="displayAddSetupTypeDialog"
  (onHide)="clearNewSetupType()"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <form #setupTypeForm="ngForm">
    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeMachine">Anlage</label>
      <div class="p-col">
        <p-dropdown
          id="setupTypeMachine"
          name="setupTypeMachine"
          required
          [(ngModel)]="newSetupTypeMachine"
          [options]="machines"
          optionLabel="name"
          placeholder="Anlage wählen"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeFirstLevel">Ebene 1</label>
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeFirstLevel"
          name="setupTypeFirstLevel"
          type="text"
          pInputText
          required
          [(ngModel)]="newSetupTypeFirstLevel"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeSecondLevel">Ebene 2</label>
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeSecondLevel"
          name="setupTypeSecondLevel"
          type="text"
          pInputText
          [(ngModel)]="newSetupTypeSecondLevel"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeThirdLevel">Ebene 3</label>
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeThirdLevel"
          name="setupTypeThirdLevel"
          type="text"
          pInputText
          [(ngModel)]="newSetupTypeThirdLevel"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeTargetTimeUnequip"
        >Sollzeit Abrüsten</label
      >
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeTargetTimeUnequip"
          name="setupTypeTargetTimeUnequip"
          type="number"
          pInputText
          required
          [(ngModel)]="newSetupTypeTargetTimeUnequip"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeTargetTimeSetup"
        >Sollzeit Rüsten Filling</label
      >
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeTargetTimeSetup"
          name="setupTypeTargetTimeSetup"
          type="number"
          pInputText
          required
          [(ngModel)]="newSetupTypeTargetTimeSetup"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeTargetTimeLab"
        >Sollzeit Freigabe Labor</label
      >
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeTargetTimeLab"
          name="setupTypeTargetTimeLab"
          type="number"
          pInputText
          required
          [(ngModel)]="newSetupTypeTargetTimeLab"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="setupTypeTargetTimeWaitForStart"
        >Sollzeit Verzögerung Füllbeginn</label
      >
      <div class="p-col">
        <input
          class="form-control"
          id="setupTypeTargetTimeWaitForStart"
          name="setupTypeTargetTimeWaitForStart"
          type="number"
          pInputText
          required
          [(ngModel)]="newSetupTypeTargetTimeWaitForStart"
        />
      </div>
    </div>

    <div *ngIf="setEditFunctionForDialog" class="p-field p-grid">
      <label class="p-col-5" for="setupTypeActive">Aktiv</label>
      <div class="p-col">
        <p-dropdown
          [disabled]="true"
          id="setupTypeActive"
          name="setupTypeActive"
          [(ngModel)]="newSetupTypeIsActive"
          [options]="booleanDropDownOptions"
          placeholder=" "
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-d-flex p-jc-end">
      <div>
      <button
        type="button"
        pButton
        [disabled]="!setupTypeForm.valid"
        icon="fa fa-check"
        (click)="saveSetupType()"
        label="Speichern"
      ></button>
    </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog
  header="Bestätigen"
  icon="pi pi-exclamation-triangle"
  acceptLabel="Ja"
  rejectLabel="Nein"
  key="setupTypeConfirmDialog"
  styleClass="width-600"
>
</p-confirmDialog>
