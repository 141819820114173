import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';


type Subroute = 'oee-details'
  | 'machine-performance'
  | 'live-speed'
  | 'no-oil'
  | 'technical-failures'
  | 'slowdowns'
  | 'shortstop-failures'
  | 'adjustment-work';

@Injectable()
export class DataService {

  dataAPIEndpoint = environment.APIEndpoint + '/data';
  data: any;

  constructor(private http: HttpClient) {
  }

  static createFlexibleDateParams(startDate, endDate?, machineId?) {
    let params;
    if (endDate) {
      params = new HttpParams().append('start', startDate.toISOString()).append('end', endDate.toISOString()).append('machine', machineId);
    } else {
      params = new HttpParams().append('start', startDate.toISOString()).append('machine', machineId);
      ;
    }
    return params;
  }

  getLiveSpeedDate(): Promise<any> {
    return this.http.get(this.dataAPIEndpoint + '/live-speed').toPromise();
  }

  getMachinePerformanceData(start, end, machineId: string): Promise<any> {
    if (!end) end = start;
    const params = new HttpParams().append('start', start.toISOString()).append('end', end.toISOString()).append('machine', machineId);
    return this.http.get(this.dataAPIEndpoint + '/machine-performance', {params: params}).toPromise();
  }

  getDataFor(subroute: Subroute, body: {
    start,
    end,
    machineIds: string[]
  }): Promise<any> {
    if (!body.end) body.end = body.start;
    return this.http.post(this.dataAPIEndpoint + '/' + subroute, body).toPromise();
  }

}
