import { Injectable } from "@angular/core";
import * as moment from "moment";
import { MachineService } from "./machine.service";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class SelectionService {
  // machine Selection
  forMachine: string;
  selectedMachines = [];
  machineList = [];

  // date selection
  today = moment().toDate();
  selectedDates = [this.today];

  constructor(
    public dataService: DataService,
    public machineService: MachineService
  ) {
    this.getMachines();
    this.getDates();
  }

  // make this private to prevent other components from calling this initial setup function
  private async getMachines() {
    const machines = await this.machineService.getActiveMachines();
    machines.forEach((machine) => {
      this.machineList.push({
        label: machine.name,
        value: machine._id,
      });
      if (machine.name === "Anlage 8") {
        this.forMachine = machine._id;
        this.selectedMachines.push(machine._id);
      }
    });
  }

  // selection change - dropdown
  forMachineChanged(event) {
    this.forMachine = event;
    this.selectedMachines = [event];
  }

  // selection change - multiSelect
  selectedMachinesChanged(event) {
    this.selectedMachines = event;
    this.forMachine = event[0];
  }

  // time frame selection
  de = {
    firstDayOfWeek: 1,
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    today: "Heute",
    clear: "Löschen",
  };

  async getDates() {
    this.today = new Date();
  }
}
