<app-header-bar [title]="'Maschinen Performance'"></app-header-bar>
<div class="page-subheader-bar p-pt-6">
  <div class="p-grid subheader-menu">
    <span>Anlage</span>
    <p-multiSelect [defaultLabel]="
        selectionService.selectedMachines?.length > 0
          ? 'Alle Anlagen ausgewählt'
          : 'Keine Anlage ausgewählt'
      " [options]="selectionService.machineList" [ngModel]="selectionService.selectedMachines"
      (ngModelChange)="selectionService.selectedMachinesChanged($event)" [displaySelectedLabel]="true"
      [maxSelectedLabels]="0" [selectedItemsLabel]="'{0} Anlage(n) ausgewählt'"></p-multiSelect>

    <span>Zeitraum</span>
    <p-calendar dateFormat="dd.mm.yy" F [(ngModel)]="selectionService.selectedDates" selectionMode="range"
      [maxDate]="selectionService.today" [defaultDate]="selectionService.today"
      [locale]="selectionService.de" placeholder="Heute"></p-calendar>
    <p-button (click)="fetchData()" label="Daten holen"></p-button>
  </div>
</div>

<!-- hier beginnt die box zum anzeigen der performance -->

<div class="main-container performance p-grid">
  <ng-container *ngFor="let day of allDaysMachineData$ | async | keyvalue">
    <div class="p-col-10 p-pt-5">
      <div>
        <span class="p-pl-3">
          {{ day.key | date }}
        </span>
      </div>
      <ng-container *ngFor="let oneMachineDay of day.value | keyvalue">
        <div *ngIf="oneMachineDay.value; let oneDay" class="p-pl-6 p-pt-4 p-pb-4">
          <span class="green-text">
            {{ allMachines[oneMachineDay.key].name }}
          </span>
          <div class="p-grid p-col-12">
            <app-machine-speed-chart *ngIf="oneDay.speedValues.length > 0; else noTodaySpeed" class="p-col-8"
              [data]="oneDay.speedValues" [machineBpm]="allMachines[oneMachineDay.key].bpm"
              [machineType]="allMachines[oneMachineDay.key].type">
            </app-machine-speed-chart>

            <ng-template #noTodaySpeed>
              <div class="p-d-flex p-ai-center p-col-4">
                <p>Keine Geschwindigkeits-Daten vorhanden.</p>
              </div>
            </ng-template>

            <app-machine-amount-chart *ngIf="
                oneDay.quantity.currentInBoxes || oneDay.quantity.targetInBoxes;
                else noTodayQuantity
              " [data]="oneDay.quantity">
            </app-machine-amount-chart>

            <p *ngIf="oneDay.quantity.currentInBoxes || oneDay.quantity.targetInBoxes">
              IST
              <span>{{ (oneDay.quantity.currentInBoxes) || "-" }}</span>
              <br />
              SOLL
              <span>{{ (oneDay.quantity.targetInBoxes) || "-" }}</span>
              <br />
              DIFF
              <span [ngClass]="{
                  red: oneDay.quantity.difference < 0,
                  'light-blue': oneDay.quantity.difference > 0
                }">{{ (oneDay.quantity.difference | bottlesToBoxes: oneDay?.quantity?.unitsPerContainer) || "-" }}</span>
            </p>

            <ng-template #noTodayQuantity>
              <div class="p-d-flex p-ai-center p-col-4">
                <p>Keine Mengen-Daten vorhanden.</p>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>