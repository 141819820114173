import { Component, OnInit } from "@angular/core";
import { Machine } from 'app/models/machine';
import { Role } from "../../../common/Auth/Role";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-master-data-component",
  templateUrl: "master-data.component.html",
  styleUrls: ["master-data.component.scss"],
})
export class MasterDataComponent implements OnInit {
  tabIndex;
  
  role: Role;
  public Role = Role;
  public machine: Machine;
  
  constructor(private authService: AuthService) {
    this.role = this.authService.role;
  }

  async ngOnInit() {}

  async onTabChange(e) {
    this.tabIndex = e.index;
  }
}