<app-header-bar [title]="'Stammdaten'"></app-header-bar>
<div class="main-container p-grid p-col-12">
  <p-tabView (onChange)="onTabChange($event)">

    <p-tabPanel header="Anlagen">
      <app-machines></app-machines>
    </p-tabPanel>

    <p-tabPanel header="Gebinde">
      <app-packaging-data></app-packaging-data>
    </p-tabPanel>

    <p-tabPanel header="SKUs">
      <app-sku-data></app-sku-data>
    </p-tabPanel>

    <p-tabPanel header="Soll-BPMs">
      <app-target-bpm></app-target-bpm>
    </p-tabPanel>

    <p-tabPanel header="Rüsttypen">
      <app-setup-types></app-setup-types>
    </p-tabPanel>

    <p-tabPanel header="Tanktypen">
      <app-tanktype-data></app-tanktype-data>
    </p-tabPanel>

  </p-tabView>
</div>