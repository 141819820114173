import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-live-speed-chart',
  template: '<div class="p-jc-center" [chart]="chart"></div>'
})

export class LiveSpeedChartComponent implements OnInit {

  chart: Chart;

  @Input()
  machineBpm: number;

  @Input()
  data: any;

  constructor() {


  }




  ngOnInit() {

    this.chart = new Chart(<any>{
      chart: {
        type: 'solidgauge',
        width: 70,
        height: 100,
        margin: 0
      },
      title: null,
      pane: {
        center: ['50%', '65%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '50%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.01, '#ff0000'], // red
          [0.6, '#E6C229'], // yellow
          [1, '#009933'] // green
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          enabled: false
        },
        min: 0,
        max: this.machineBpm,
      },
      plotOptions: {
        solidgauge: {
          innerRadius: '50%',
          dataLabels: {
            enabled: false,
          },
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Geschwindigkeit',
        data: [0],
      }],
      exporting: { enabled: false }
    });

    this.setAndShowChart(this.data);

  }


  setAndShowChart( lastBPM ) {
    if (this.chart.ref) {
      this.chart.ref.series[0].data[0].update(lastBPM);
    } else {
      this.chart.addSeries({
        type: 'solidgauge',
        name: 'Geschwindigkeit',
        data: [lastBPM],
      }, true, true);
    }

  }

}



