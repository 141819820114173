import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Po} from '../models/po';
import {FillingProcess} from '../models/filling-process';

@Injectable()
export class PoService {

  dataAPIEndpoint = environment.APIEndpoint + '/po';
  data: any;

  constructor(private http: HttpClient) {
  }

  getPos(startDate, endDate): Promise<Po[]> {
    let params;
    if (endDate) {
      params = new HttpParams().append('start', startDate).append('end', endDate);
    } else {
      params = new HttpParams().append('start', startDate);
    }
    return this.http.get<Po[]>(this.dataAPIEndpoint + '/all', {params: params}).toPromise();
  }

  updatePo(po) {
    return this.http.put(this.dataAPIEndpoint + '/', po).toPromise();
  }

  postPo(po: Po) {
    return this.http.post(this.dataAPIEndpoint + '/', po).toPromise();
  }

  deletePo(id: string) {
    return this.http.delete(this.dataAPIEndpoint + '/' + id).toPromise();
  }

  checkPo(poNumber: string) {
    const params = new HttpParams().append('poNumber', poNumber);
    return this.http.get<Po>(this.dataAPIEndpoint + '/check-po-number', {params: params}).toPromise();
  }

  reassignFpToExistingPo(fillingProcesses: FillingProcess[], poId: string, oldPoId: string) {
    const fpAndPo = {fillingProcesses, poId, oldPoId};
    return this.http.put(this.dataAPIEndpoint + '/reassign-fp', fpAndPo).toPromise();
  }
}
