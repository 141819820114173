import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Machine} from '../models/machine';

@Injectable()
export class MachineService {

  dataAPIEndpoint = environment.APIEndpoint + '/machine';
  data: any;

  constructor(private http: HttpClient) {
  }

  getMachines(): Promise<Machine[]> {
    return this.http.get<Machine[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getActiveMachines(): Promise<Machine[]> {
    return this.http.get<Machine[]>(this.dataAPIEndpoint + '/all-active').toPromise();
  }

  updateMachine(machine: Machine) {
    return this.http.put(this.dataAPIEndpoint + '/update-machine', machine).toPromise();
  }
}

