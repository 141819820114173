
export interface CategoryData {
  label: string;
  color: string;
}

export enum EventCategory {
  CAT_OEE = 'cat_oee',

  CAT_NOT_IDENTIFIED = 'cat_not_identified',
  CAT_SHORT = 'cat_short',
  CAT_BETWEEN_PO = 'cat_between_po',
  CAT_REMAINDER = 'cat_remainder',

  CAT_EQ_WAIT = 'cat_eq_wait',
  CAT_EQ_LAB = 'cat_equip_lab',
  CAT_EQUIP_STATES = 'cat_equip_states',
  CAT_EQUIPSTATE = 'cat_equipstate',
  CAT_UNEQUIP = 'cat_unequip',

  CAT_TA = 'cat_ta',
  CAT_ANLAGE = 'cat_anlage',
  CAT_TA_SONST = 'cat_ta_sonst',
  CAT_EDV = 'cat_edv',
  CAT_KW = 'cat_kw',
  CAT_FILTER = 'cat_filter',
  CAT_KW_OTHER = 'cat_kw_other',
  CAT_RM = 'cat_rm',
  CAT_PERSONAL = 'cat_personal',
  CAT_OIL = 'cat_oil',
  CAT_KK = 'cat_kk',
  CAT_QUALI_PACK = 'cat_quali_pack',
  CAT_NACHGEL_PROZ = 'cat_nachgel_proz',
  CAT_BESPRECHUNG = 'cat_besprechung',
  CAT_RIS = 'cat_ris',
  CAT_TESTS = 'cat_tests',
  CAT_SONST_SONST = 'cat_sonst_sonst',
  CAT_BREAK = 'cat_break',
}

export const CategoryInfoMap: Map<string, CategoryData> = new Map([
  [EventCategory.CAT_OEE, { label: 'OEE', color: '#004718' }],  // dark green
  [EventCategory.CAT_NOT_IDENTIFIED, { label: 'Nicht identifiziert', color: '#CCCCCC' }], // castrol medium grey
  [EventCategory.CAT_SHORT, { label: 'Kurzstops', color: '#537F4F' }], // orange
  [EventCategory.CAT_BETWEEN_PO, { label: 'Zwischen Aufträgen', color: '#525759' }], // castrol dark grey
  [EventCategory.CAT_REMAINDER, { label: 'Geschwindigkeitsverluste', color: '#7F1B15' }], // reddish
  [EventCategory.CAT_EQ_WAIT, { label: 'Verzögerung Füllbeginn', color: '#02A9B2' }],
  [EventCategory.CAT_EQ_LAB, { label: 'Freigabezeit', color: '#02797F' }],
  [EventCategory.CAT_EQUIP_STATES, { label: 'Rüsten/Abrüsten', color: '#04F1FF' }], // greenish-blue
  [EventCategory.CAT_EQUIPSTATE, { label: 'Rüsten Filling', color: '#04F1FF' }],
  [EventCategory.CAT_UNEQUIP, { label: 'Abrüsten', color: '#016065' }],
  [EventCategory.CAT_TA, { label: 'Technische Ausfälle', color: '#7197C3' }], // castrol light blue
  [EventCategory.CAT_ANLAGE, { label: 'Anlagenstörungen', color: '#36485D' }],
  [EventCategory.CAT_TA_SONST, { label: 'TA Sonstiges', color: '#455C76' }],
  [EventCategory.CAT_EDV, { label: 'EDV', color: '#202A36' }],
  [EventCategory.CAT_KW, { label: 'Komponentenwechsel', color: '#613B7F' }], // purple
  [EventCategory.CAT_FILTER, { label: 'Filter', color: '#AF6BE5' }],
  [EventCategory.CAT_KW_OTHER, { label: 'Anderer Komponentenwechsel', color: '#301E40' }],
  [EventCategory.CAT_PERSONAL, { label: 'Personalmangel', color: '#CC9700' }],
  [EventCategory.CAT_OIL, { label: 'Öl', color: '#FFBD00' }], // yellow-orange
  [EventCategory.CAT_KK, { label: 'Keine Komponente', color: '#7F6826' }],
  [EventCategory.CAT_QUALI_PACK, { label: 'QA Packmittel', color: '#BF6D5' }], // pink-ish
  [EventCategory.CAT_NACHGEL_PROZ, { label: 'Nachgelagerte Prozesse', color: '#B4AC42' }], // olive-green
  [EventCategory.CAT_BESPRECHUNG, { label: 'Besprechungen', color: '#AC8643' }], // ~castrol light tan
  [EventCategory.CAT_RIS, { label: 'RIS Tätigkeiten', color: '#FEC563' }],
  [EventCategory.CAT_TESTS, { label: 'Tests', color: '#3F3119' }],
  [EventCategory.CAT_SONST_SONST, { label: 'Sonstiges', color: '#E4B259' }],
  [EventCategory.CAT_BREAK, { label: 'Pausen', color: '#9EBF09' }], // brown
]);


