import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Role } from 'common/Auth/Role';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }



  canActivate(route: ActivatedRouteSnapshot) {

   const allowedRoles: Role[] = [].concat(route.data['roles']);

    if (this.authService.isAuthenticated() && allowedRoles.includes(this.authService.role)) {
      return true;
    }
    this.router.navigate(['/login']).catch(console.log);
    return false;
  }


}



