import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {Role} from '../../../common/Auth/Role';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  public noBranding = environment.noBranding;
  role: Role;
  public Role = Role;  // otherwise not accessable in the template

  constructor(private router: Router, private authService: AuthService) {
    this.role = this.authService.role;
  }

  async submitLogout() {
    await this.router.navigate(['login']);
  }
}
