import {CategoryInfoMap, EventCategory} from './categories';
import {ProcessPhase} from './process-phase';
const SetupColors = {};
const ChartColors = {};


// equip stacked chart greenish-blue
SetupColors[ProcessPhase.SETUP_WAIT] = '#bb9e85';
SetupColors[ProcessPhase.SETUP_LAB] = '#ddb6b5';
SetupColors[ProcessPhase.UNEQUIP] = '#6e8586';
SetupColors['Rüsten'] = '#d9d9d9';

// chart names

// colors definied by Castrol
ChartColors['slowdowns'] = '#ccff00';
ChartColors['ta'] = '#404040';
ChartColors['ja'] = '#00474b';
ChartColors['shortstops'] = '#99cc00';
ChartColors['oil'] = '#ff0000';
ChartColors['setup'] = '#d9d9d9';

ChartColors['oee'] = '#009900';
ChartColors['top'] = '#6A8DB6';


export { SetupColors, ChartColors };

