<nav>
  <h1 *ngIf="noBranding">OEE Cockpit <span ><i>Demo</i></span></h1>
  <h1 *ngIf="!noBranding">Castrol Cockpit </h1>

    <!--img class="castrol-logo" src="../../../assets/castrol-logo-round.png"-->
  <a routerLink="/cockpit/live-overview" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-diagram-strategy-white.svg">
    <span>Live Status</span>
  </a>
  <a routerLink="/cockpit/machine-performance" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-diagram-graph-points-white.svg">
    <span>Maschinen Performance</span>
  </a>
  <a routerLink="/cockpit/oee-dashboard" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-graph-white.svg">
    <span>OEE Dashboard</span>
  </a>
  <a routerLink="/cockpit/events-overview" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-admin-cogs-white.svg">
    <span>Events</span>
  </a>
  <a routerLink="/cockpit/po-overview" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-tick-checklist-white.svg">
    <span>PO Übersicht</span>
  </a>
  <a *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)"routerLink="/cockpit/master-data" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-categories-white.svg">
    <span>Stammdaten</span>
  </a>
  <a *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)" routerLink="/cockpit/configuration-change" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-calendar-white.svg">
    <span>Konfigurationen</span>
  </a>
  <a *ngIf="[Role.ADMIN].includes(role)" routerLink="/cockpit/collection" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-cloud-download-white.svg">
    <span >JSON Export</span>
  </a>
  <a routerLink="/cockpit/no-stop-screen" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/logistic.svg">
    <span>NoStop Screen</span>
  </a>
  <a routerLink="/cockpit/sample-outlook" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/logistic.svg">
    <span>Sample Outlook</span>
  </a>

  <a *ngIf="[Role.ADMIN].includes(role)" routerLink="/cockpit/user-management" [routerLinkActive]="['link-active']">
    <img class="nav-logo svg" src="./assets/icons/bp-icon-contractor-white.svg">
    <span>Benutzerverwaltung</span>
  </a>

  <div class="bottom">
    <p-button label="Logout" (onClick)="submitLogout()"></p-button>
  </div>
</nav>
