import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';

import { Role } from '../../../../common/Auth/Role';
import { FillingProcess } from '../../../models/filling-process';
import { Machine } from '../../../models/machine';
import { Po } from '../../../models/po';
import { SetupType } from '../../../models/setup-type';
import { TankType } from '../../../models/tank-type';
import { AuthService } from '../../../services/auth.service';
import { FillingProcessService } from '../../../services/filling-process.service';
import { MachineService } from '../../../services/machine.service';
import { TankTypeService } from '../../../services/tank-type.service';


@Component({
  templateUrl: './filling-process-overview.component.html',
  styleUrls: ['./filling-process-overview.component.scss'],
  providers: [DialogService]
})

export class FillingProcessOverviewComponent implements OnInit {

  fillingProcesses: FillingProcess[] = this.config.data.fillingProcesses;
  public po: Po = this.config.data.po;
  public selectedFillingProcess;
  public poIsIncomplete;
  public fpToDelete: FillingProcess;
  public displayDeleteFpDialog = false;
  public hideEndInput = false;
  public editedRawAmountFillingProcessInUnits: number;
  public selectedSetupType: SetupType[] = [];
  public fillingProcessForSetupType: FillingProcess;

  machines: Machine[];
  private tankTypes: TankType[] = [];
  activeTankTypes:  TankType[] = [];
  today = moment().toDate();

  selectedStartDate;
  selectedEndDate;
  de = {
    firstDayOfWeek: 1,
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Löschen'
  };

  role: Role;
  public Role = Role;

  constructor(
    public config: DynamicDialogConfig,
    public fillingProcessService: FillingProcessService,
    public ref: DynamicDialogRef,
    private machineService: MachineService,
    private tankTypeService: TankTypeService,
    private authService: AuthService
  ) {
    this.role = this.authService.role;
  }

  async ngOnInit() {
    this.tankTypes = await this.tankTypeService.getTankTypes().pipe(
      map(values => [{_id: 'null', name: ' -- ohne Angabe --', isActive: true}, ...values])).toPromise();

    this.activeTankTypes = this.tankTypes.filter(item => item.isActive);

    if (this.po.sku) {
      this.fillingProcesses.forEach((fp: FillingProcess) => {
        fp['rawAmountInContainer'] = Math.floor(fp.rawAmountFillingProcessInUnits / this.po.sku.packaging.unitsPerContainer);
        this.poIsIncomplete = false;
      });
    } else {
      this.poIsIncomplete = true;
    }
    this.machines = await this.machineService.getMachines();
  }

  onDeleteInit(fp) {
    this.displayDeleteFpDialog = true;
    this.fpToDelete = {...fp};
  }

  async onDeleteFp() {
    await this.fillingProcessService.deleteFp(this.fpToDelete._id);
    this.displayDeleteFpDialog = false;
    this.ref.close();
  }

  onEditInit(rowData) {
    rowData.poNumber = this.po.poNumber;
    rowData.poId = this.po._id;
    this.selectedStartDate = moment(rowData.startTimestamp).toDate();
    this.selectedEndDate = moment(rowData.endTimestamp).toDate();
    this.poIsIncomplete = !this.po.sku;
    this.selectedFillingProcess = {...rowData};

  }

  async onSave() {
    let multiplicationFactor;
    if (!this.editedRawAmountFillingProcessInUnits) {
      multiplicationFactor = 1;
      this.editedRawAmountFillingProcessInUnits = this.selectedFillingProcess.rawAmountFillingProcessInUnits;
    } else {
      multiplicationFactor = this.po.sku ? this.po.sku.packaging.unitsPerContainer : this.fillingProcesses[0].machine.defaultUnitsPerContainer;
    }
    const rawAmountFillingProcessInUnits = this.editedRawAmountFillingProcessInUnits * multiplicationFactor;
    const startTimestamp = this.selectedStartDate.toISOString();
    const endTimestamp = this.selectedEndDate.toISOString();
    const machine: Machine = this.machines.find(m => {
      return m.name === this.selectedFillingProcess.machine.name;
    });

    if (this.selectedFillingProcess.tankType === "null") this.selectedFillingProcess.tankType = null;
    const fillingProcess = {
      _id: this.selectedFillingProcess._id,
      rawAmountFillingProcessInUnits: rawAmountFillingProcessInUnits,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      machine: machine,
      comments: this.selectedFillingProcess.comments,
      poNumber: this.selectedFillingProcess.poNumber,
      poId: this.po._id,
      tankType: this.selectedFillingProcess.tankType ? this.selectedFillingProcess.tankType : undefined
    };
    await this.fillingProcessService.updateFillingProcess(fillingProcess);
    this.selectedFillingProcess = {};
    this.ref.close();
  }
  onCancel() {
    this.selectedFillingProcess = {};
  }

  onCalendarInput() {
    this.hideEndInput = true;
  }

  onCalendarClose() {
    this.hideEndInput = false;
  }

  onSelectSetupType(rowData) {
    this.selectedSetupType = [];
    this.selectedSetupType.push(rowData.setupType);
    this.fillingProcessForSetupType = rowData;
  }


  getTankNameById(id) {
    if (!id) return null;
    return this.tankTypes.find(item => item._id === id)?.name;
  }
}
