<div id="content" class="p-d-flex p-ai-center p-jc-around">
  <div class="p-d-flex p-ai-center machine">
    <div [ngClass]="getButtonClass(eventColor)" style="display: block"></div>
    {{machineName}}
  </div>

  <div class="speed p-d-flex p-ai-center p-jc-around">
    <app-live-speed-chart [data]="currentBpm" [machineBpm]="defaultMaxBpm"></app-live-speed-chart>
    <div class="bpm p-flex-row">
      {{currentBpm}}
      <div class="fraction p-flex-column p-pl-3">
        <div *ngIf="machineType === 1" class="fraction-upper">Gebinde</div>
        <div *ngIf="machineType === 2" class="fraction-upper">Paletten</div>
        <div>min</div>
      </div>
    </div>
  </div>

  <div class="p-d-flex p-ai-center amount-container-day">
    <div class="p-p-2">
      <b>IST </b>
      <span>{{ (quantity?.actualAmountToday | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
    <div class="p-p-2">
      <b>SOLL </b>
      <span>{{ (quantity?.targetAmountToday | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
    <div class="p-p-2" *ngIf="quantity?.targetAmountToday > quantity?.actualAmountToday">
      <b>REST </b>
      <span>{{ ((quantity?.targetAmountToday - quantity?.actualAmountToday) | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
    <div class="p-p-2" *ngIf="quantity?.actualAmountToday > quantity?.targetAmountToday">
      <b>ÜBER </b>
      <span>{{ ((quantity?.actualAmountToday - quantity?.targetAmountToday) | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
  </div>

  <div class="p-d-flex p-ai-center amount-container">
    <div class="p-p-2">
      <b>IST </b>
      <span>{{ (quantity?.rawAmountFillingProcessInUnits | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
    <div class="p-p-2">
      <b>SOLL </b>
      <span>{{ (quantity?.targetAmountPoInUnits | bottlesToBoxes: unitsPerContainer) || "-" }}</span>
    </div>
  </div>

  <div class="p-d-flex p-ai-center process">
    <div>
      <div *ngIf="connectionErrorStatus">
        <span [ngClass]="'red-text'">Netzwerkprobleme ({{CES[connectionErrorStatus]}})</span> 
      </div>
      <div *ngIf="!connectionErrorStatus">
        <span [ngClass]="getColorClass(eventColor)"> {{eventLabel}}{{lastEventTimestamp  && displayTime ? ': seit '+ displayTime : ''}}</span>
      </div>
    </div>
  </div>
</div>