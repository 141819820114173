<app-header-bar [title]="'JSON-Export aus der Datenbank'"></app-header-bar>

<div class="subheader-menu">
  <span>Anlage</span>
  <p-multiSelect
  [defaultLabel]="
    selectionService.selectedMachines?.length > 0
      ? 'Alle Anlagen ausgewählt'
      : 'Keine Anlage ausgewählt'
  "
  [options]="selectionService.machineList"
  [ngModel]="selectionService.selectedMachines"
  (ngModelChange)="selectionService.selectedMachinesChanged($event)"
  [displaySelectedLabel]="true"
  [maxSelectedLabels]="0"
  [selectedItemsLabel]="'{0} Anlage(n) ausgewählt'"
></p-multiSelect>
  <span class="">Zeitraum</span>
  <p-calendar
    dateFormat="dd.mm.yy"
    [(ngModel)]="selectionService.selectedDates"
    selectionMode="range"
    [maxDate]="selectionService.today"
    [defaultDate]="selectionService.today"
    [locale]="selectionService.de"
    placeholder="Heute"
  ></p-calendar>
</div>

<br />

<div class="main-container p-col-12 p-pl-4">
  <table>
    <tr *ngFor="let collection of collections">
      <td>
        {{ collection.name }} <br />
        <i>{{ collection.collection }}</i>
      </td>
      <td>
        <p-button type="button" (click)="downloadFile(collection.collection, FileTypes.JSON)">
          JSON
        </p-button>
      </td>
    </tr>

    <tr *ngFor="let collection of zipCollections">
      <td>
        {{ collection.name }} <br />
        <i>{{ collection.collection }}</i>
      </td>
      <td>
        <p-button
          type="button"
          (click)="downloadFile(collection.collection, FileTypes.ZIP)"
        >
          Zip
        </p-button>
      </td>
    </tr>
  </table>
</div>
