<td class="main-container p-pb-6">
  <p-table
    *ngIf="fillingProcesses.length >= 1"
    [value]="fillingProcesses"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>PO</th>
        <th class="p-col-2">Beginn</th>
        <th class="p-col-2">Ende</th>
        <th >Soll-Menge PO</th>
        <th >Ist-Menge</th>
        <th >Anlage</th>
        <th>Kommentare</th>
        <th>Rüsttyp</th>
        <th>Tanktyp</th>

        <th *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">Bearbeiten</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <ng-template id="calendarPlaceholder"></ng-template>
      <tr class="p-text-center">
        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ po.poNumber ? po.poNumber : "-" }}
          </div>
          <div>
            <input
              class="modal-input"
              *ngIf="rowData._id === selectedFillingProcess?._id"
              minlength="7"
              maxlength="7"
              [(ngModel)]="selectedFillingProcess.poNumber"
            />
          </div>
        </td>
        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ rowData.startTimestamp | date: "HH:mm (d.M.)" }}
          </div>
          <div *ngIf="rowData._id === selectedFillingProcess?._id">
            <p-calendar
            required
            id="startDate"
            dateFormat="dd.mm.yy"
            placeholder="{{ rowData.startTimestamp }}"
            [(ngModel)]="selectedStartDate"
            [locale]="de"
            [maxDate]="today"
            [showTime]="true"
            appendTo="body"
          >
          </p-calendar>
          </div>
        </td>

        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ rowData.endTimestamp | date: "HH:mm (d.M.)" }}
          </div>
          <div
            *ngIf="rowData._id === selectedFillingProcess?._id && !hideEndInput"
          >
            <p-calendar
              id="endDate"
              dateFormat="dd.mm.yy"
              placeholder="{{ rowData.endTimestamp }}"
              [(ngModel)]="selectedEndDate"
              [showTime]="true"
              [locale]="de"
              [maxDate]="today"
              [minDate]="selectedStartDate"
              appendTo="body"
            >
            </p-calendar>
          </div>
        </td>

        <td>
          {{
            po.targetAmountInUnits
              | bottlesToBoxes
                : (po.sku
                    ? po.sku.packaging.unitsPerContainer
                    : rowData.machine.defaultUnitsPerContainer)
          }}
          <span *ngIf="po.sku || rowData.machine.defaultUnitsPerContainer">
            EA
          </span>
          <span *ngIf="!po.sku && !rowData.machine.defaultUnitsPerContainer">
            Gebinde
          </span>
        </td>

        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{
              rowData.rawAmountFillingProcessInUnits
                | bottlesToBoxes
                  : (po.sku
                      ? po.sku.packaging.unitsPerContainer
                      : rowData.machine.defaultUnitsPerContainer)
            }}
            <span *ngIf="po.sku || rowData.machine.defaultUnitsPerContainer">
              EA
            </span>
            <span *ngIf="!po.sku && !rowData.machine.defaultUnitsPerContainer">
              Gebinde
            </span>
          </div>
          <input
            class="modal-input"
            *ngIf="rowData._id === selectedFillingProcess?._id"
            type="text"
            [ngModel]="
              selectedFillingProcess.rawAmountFillingProcessInUnits
                | bottlesToBoxes
                  : (po.sku
                      ? po.sku.packaging.unitsPerContainer
                      : rowData.machine.defaultUnitsPerContainer)
            "
            (ngModelChange)="editedRawAmountFillingProcessInUnits = $event"
          />
        </td>

        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ rowData.machine.name }}
          </div>
          <div *ngIf="rowData._id === selectedFillingProcess?._id">
            <select
              id="machine"
              [(ngModel)]="selectedFillingProcess.machine.name"
            >
              <ng-container *ngFor="let machine of machines">
                <option [value]="machine.name">
                  {{ machine.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </td>
        <td>
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ rowData.comments }}
          </div>
          <input
            class="comment-input"
            *ngIf="rowData._id === selectedFillingProcess?._id"
            type="text"
            [(ngModel)]="selectedFillingProcess.comments"
          />
        </td>
        <td>
          <div class="text-underline cursor-pointer" (click)="onSelectSetupType(rowData)">
            {{ rowData.setupType?.firstLevel }}
          </div>
        </td>

        <td>



          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            {{ getTankNameById(rowData.tankType)  }}
          </div>
          <div *ngIf="rowData._id === selectedFillingProcess?._id">
            <select
              id="tanktyper"
              [(ngModel)]="selectedFillingProcess.tankType"
            >
              <ng-container *ngFor="let tankType of activeTankTypes">
                <option [value]="tankType._id">
                  {{ getTankNameById(tankType._id) }}
                </option>
              </ng-container>
            </select>
          </div>


        </td>

        <td *ngIf="[Role.ADMIN, Role.SHIFTLEAD].includes(role)">
          <div *ngIf="rowData._id !== selectedFillingProcess?._id">
            <img
              *ngIf="
                !poIsIncomplete ||
                po?.fillingProcesses[0].machine.machineType === 2
              "
              class="cursor-pointer p-pr-2"
              (click)="onEditInit(rowData)"
              src="./assets/icons/pencil.svg"
              alt=""
            />
            <img
              class="cursor-pointer p-pr-2"
              (click)="onDeleteInit(rowData)"
              src="./assets/icons/delete.svg"
              alt=""
            />
          </div>
          <div *ngIf="rowData._id === selectedFillingProcess?._id">
            <img
              class="cursor-pointer p-pr-2"
              (click)="onSave()"
              src="./assets/icons/check.svg"
              alt=""
            />
            <img
              class="cursor-pointer p-pr-2"
              (click)="onCancel()"
              src="./assets/icons/window-close.svg"
              alt=""
            />
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div
    *ngIf="
      [Role.ADMIN, Role.SHIFTLEAD].includes(role) &&
      poIsIncomplete && po?.fillingProcesses[0].machine.machineType === 1
    "
    class="p-pt-4 p-text-center red-text"
  >
    Bitte vor dem Bearbeiten PO Informationen angeben.
  </div>

  <div *ngIf="po.sku && fillingProcesses.length >= 1" class="p-text-center p-pt-6">
    <b>Gesamtmenge:</b>
    {{
      po["actualAmount"]
        | bottlesToBoxes
          : (po.sku
              ? po.sku.packaging.unitsPerContainer
              : fillingProcesses[0].machine.defaultUnitsPerContainer)
    }}
    von
    {{ po.targetAmountInUnits / po.sku.packaging.unitsPerContainer }} Einheiten
    abgefüllt.
  </div>

  <div *ngIf="selectedSetupType.length > 0">
    <div class="p-pt-6 setup-type-header">
      Rüsttyp für Füllvorgang vom
      {{
        fillingProcessForSetupType.startTimestamp | date: "dd.MM.yyyy, HH:mm"
      }}
    </div>
    <p-table [value]="selectedSetupType">
      <ng-template pTemplate="header">
        <tr class=" p-text-left">
          <th>Ebene 1</th>
          <th>Ebene 2</th>
          <th>Ebene 3</th>
          <th>Sollzeit Abrüsten</th>
          <th>Sollzeit Rüsten Filling</th>
          <th>Sollzeit Freigabe Labor</th>
          <th>Sollzeit Verzögerung Fülllbeginn</th>
          <th>Gültig seit</th>
          <th>Aktiv</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-setupType>
        <tr>
          <td>{{ setupType.firstLevel }}</td>
          <td>{{ setupType.secondLevel }}</td>
          <td>{{ setupType.thirdLevel }}</td>
          <td>{{ setupType.targetTimeUnequip }}</td>
          <td>{{ setupType.targetTimeSetup }}</td>
          <td>{{ setupType.targetTimeLab }}</td>
          <td>{{ setupType.targetTimeWaitForStart }}</td>
          <td>{{ setupType.validFrom | date: "dd.MM.yyyy, HH:mm" }}</td>
          <td>{{ setupType.isActive | booleanToLabel }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    header="Füllvorgang löschen"
    [(visible)]="displayDeleteFpDialog"
    [baseZIndex]="10000"
    [modal]="true"
    [autoZIndex]="false"
    [style]="{ width: '400px', overflow: 'auto' }"
  >
    <div *ngIf="fpToDelete" class="p-pt-4">
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label for="newFpStartDate">Beginn:</label>
        </div>
        <div id="newFpStartDate" class="p-col-8">
          {{ fpToDelete.startTimestamp | date: "dd.MM.yyyy, HH:mm" }}
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col-4">
          <label for="newFpEndDate">Ende:</label>
        </div>
        <div id="newFpEndDate" class="p-col-8">
          {{ fpToDelete.endTimestamp | date: "dd.MM.yyyy, HH:mm" }}
        </div>
      </div>
      <div class="p-d-flex p-jc-end p-pt-4">
        <button
          type="button"
          pButton
          icon="fa fa-check"
          (click)="onDeleteFp()"
          label="Löschen"
        ></button>
      </div>
    </div>
  </p-dialog>

  <!-- test -->
</td>
