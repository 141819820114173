import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import {Sku} from '../models/sku';
import {EventDescription} from '../models/event-description';

@Injectable()
export class EventDescriptionService {

  dataAPIEndpoint = environment.APIEndpoint + '/event-description';
  data: any;

  constructor(private http: HttpClient) { }

  getEventDescriptions(): Promise<EventDescription[]> {
    return this.http.get<EventDescription[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getEventDescriptionById(id: string): Observable<EventDescription> {
    const params = new HttpParams().append('id', id);
    return this.http.get<EventDescription>(this.dataAPIEndpoint, {params: params});
  }


  addNewEventDescription(label, failureCategory, machineId) {
    const eventDescription = {
      label: label,
      failureCategory: failureCategory,
      machineId: machineId
    };
    return this.http.post(this.dataAPIEndpoint + '/add-new-event-description', eventDescription).toPromise();
  }


  addMachineToEventDescription(id, machineId) {
    const eventDescription = {
      id: id,
      machineId: machineId,
    };
    return this.http.post(this.dataAPIEndpoint + '/add-machine-to-event-description', eventDescription).toPromise();
  }

  removeMachineFromEventDescription(id, machineId) {
    const eventDescription = {
      id: id,
      machineId: machineId,
    };
    return this.http.post(this.dataAPIEndpoint + '/remove-machine-from-event-description', eventDescription).toPromise();
  }
}
