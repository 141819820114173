import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Sku} from '../models/sku';

@Injectable()
export class SkuService {

  dataAPIEndpoint = environment.APIEndpoint + '/sku';
  data: any;

  constructor(private http: HttpClient) { }


  getSkus() {
    return this.http.get<Sku[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getSku(id: string) {
    const params = new HttpParams().append('id', id);
    return this.http.get(this.dataAPIEndpoint + '/', {params: params}).toPromise();
  }
  getSkuByNumber(skuNumber: string) {
    const params = new HttpParams().append('skuNumber', skuNumber);
    return this.http.get<Sku>(this.dataAPIEndpoint + '/number', {params: params}).toPromise();
  }

  searchSku(searchString: string) {
    const params = new HttpParams().append('searchString', searchString);
    return this.http.get<Sku[]>(this.dataAPIEndpoint + '/searchSku', {params: params}).toPromise();
  }

  postSku(sku: Sku) {
    return this.http.post<Sku>(this.dataAPIEndpoint + '/', sku).toPromise();
  }

  editSku(sku: Sku) {
    return this.http.put<Sku>(this.dataAPIEndpoint + '/', sku).toPromise();
  }

  changeSkuActiveState(sku: Sku) {
    return this.http.put<Sku>(this.dataAPIEndpoint + '/change-active-state', sku).toPromise();
  }
}
