import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor( private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
        catchError((err) => {

            const error = err.error || err.statusText;
                if ([401, 403].indexOf(err.status) !== -1) {
                    this.router.navigate(['/login']).catch(console.log);
                } else if (err.statusText === 'Unknown Error' && err.ok === false) {
                    return throwError('Momentan KEINE Verbindung zum Cloud-BACKEND möglich');
                }
                return throwError(error);
            })
        );
    }
}
