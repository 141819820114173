import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usernameInput: string;
  passwordInput: string;

  constructor(
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.authService.logout();
  }

  async submitLogin() {
    const result = await this.authService.login(this.usernameInput, this.passwordInput);
    console.log('SUCCESS?', result.success);
    if (result.success) {
      this.router.navigate(['cockpit/live-overview']);
    } else {
      console.log('Error logging in');
      alert(result.msg);
      /*this.msgService.add({
        severity: 'error',
        summary: 'Login fehlgeschlagen',
        detail: 'Nutzername und/oder Passwort stimmen nicht.'
      })*/
    }
  }

}
