import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fillingProcessPipe'
})
export class FillingProcessPipe implements PipeTransform {   
  transform(eventName: string) {
    return eventName ? eventName.slice(0,3) + '..' + eventName.slice(21)  : '-';
  }
}
