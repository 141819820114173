import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    // calling isAuthenticated() re-sets username and roles from token !!!
    if ( !this.authService.isAuthenticated() ) {
      this.router.navigate(['/login']).catch(console.log);
    }
  }
}
