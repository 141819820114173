import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Role } from 'common/Auth/Role';
import * as moment from 'moment';

import { SpecialDay } from '../../models/special-days';
import { EventDescriptionService } from '../../services/event-description.service';
import { FailureCategoryService } from '../../services/failure-category.service';
import { OperationTimesService } from '../../services/operation-times.service';
import { SelectionService } from '../../services/selection.service';

@Component({
  selector: "app-configuration-change-component",
  templateUrl: "configuration-change.component.html",
  styleUrls: ["configuration-change.component.scss"],
})
export class ConfigurationChangeComponent implements OnInit {
  constructor(
    private operationTimesService: OperationTimesService,
    private failureCategoryService: FailureCategoryService,
    private eventDescriptionService: EventDescriptionService,
    public selectionService: SelectionService,
    private authService: AuthService,
  ) {}


  public currentlySaving = false;
  role: Role;
  public Role = Role;  // otherwise not accessable in the template

  selectedDate: Date;
  specialDays: SpecialDay[];
  specialDaysOfYearAndMonth: any;
  editDate;
  isNewSpecialDay;
  machineList = [];
  faultyTimesForSpecialDayExist = false;
  failureHierarchy = [
    {
      label: "",
      expanded: true,
      children: [],
    },
  ];
  selectedFailure;
  openAddChildrenDialogue = false;
  includeInactiveCategories = false;
  hierarchyExpansion = {};

  async ngOnInit() {
    this.role = this.authService.role;

    await this.getFailureHierarchy();
  }
  // test
  display: boolean = false;

  showDialog() {
      this.display = true;
  }

  public async machineSelectionChanged($event) {
    this.selectionService.forMachineChanged($event);
    await this.getFailureHierarchy();
  }

  public async  toggleShowInactiveCategories() {
    this.includeInactiveCategories = !this.includeInactiveCategories;
    await this.getFailureHierarchy();
  }

  private async getFailureHierarchy() {
    this.rememberHierarchyExpansion(this.failureHierarchy[0]);
    this.selectedFailure = null;
    this.failureHierarchy[0].children = await this.failureCategoryService.getFailureHierarchy(
      this.selectionService.forMachine,
      this.includeInactiveCategories
    );
    this.expandHierarchy(this.failureHierarchy[0]);
  }

  expandHierarchy(node) {
    if (this.hierarchyExpansion[node._id] && node.children) {
      node.expanded = true;
      node.children.forEach((child) => this.expandHierarchy(child));
    } else node.expanded = false;
  }

  rememberHierarchyExpansion(node) {
    if (node.expanded && node.children) {
      this.hierarchyExpansion[node._id] = true;
      node.children.forEach((child) => this.rememberHierarchyExpansion(child));
    } else {
      this.hierarchyExpansion[node._id] = false;
    }
  }

  async getAllSpecialDays() {
    this.specialDays = [];
    this.specialDaysOfYearAndMonth = [];
    let specialDaysInCommon = await this.operationTimesService.getSpecialDays(
      this.selectionService.selectedMachines[0]
    );
    for (const machine of this.selectionService.selectedMachines) {
      const specialDaysForMachine = await this.operationTimesService.getSpecialDays(
        machine
      );
      specialDaysInCommon = this.findSpecialDaysInCommon(
        specialDaysInCommon,
        specialDaysForMachine
      );
    }
    this.specialDays = specialDaysInCommon;
    this.specialDays.forEach((sd: SpecialDay) => {
      this.convertTimesStringsToDate(sd.times, sd.date);
    });

    this.specialDays.forEach((sd: SpecialDay) => {
      // const [year, month, day] = new Date(sd.date).toISOString().split('T')[0].split('-');

      const currentDate = new Date(sd.date);
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const day = currentDate.getDate();

      if (!this.specialDaysOfYearAndMonth[year]) {
        this.specialDaysOfYearAndMonth[year] = [];
      }
      if (!this.specialDaysOfYearAndMonth[year][month]) {
        this.specialDaysOfYearAndMonth[year][month] = [];
      }
      this.specialDaysOfYearAndMonth[year][month].push(day); 
    });
    this.isNewSpecialDay = false;
    this.editDate = null;
    this.faultyTimesForSpecialDayExist = false;
  }

  async addDay(selectedDate) {
    const date = moment(selectedDate);
    const utcDate = moment(date)
      .add(date.utcOffset(), "minutes")
      .utc()
      .toDate();
    if (this.getSpecialDay(utcDate)) {
      this.editDate = this.getSpecialDay(utcDate);
      this.editDate.times.forEach((time) => {
        time.startTime = moment(time.startTime).toDate();
        time.endTime = moment(time.endTime).toDate();
      });
    } else {
      this.isNewSpecialDay = true;
      let endTime = moment(date).clone().hours(21).minutes(30).utc().toDate();
      if (date.day() === 5)
        endTime = moment(date).clone().hours(21).minutes(30).utc().toDate();
      this.editDate = {
        date: utcDate,
        machine: null,
        times: [
          {
            startTime: moment(date).clone().hours(6).minutes(5).utc().toDate(),
            endTime: endTime,
          },
        ],
      };
    }
  }

  async saveSpecialDay() {
    this.currentlySaving = true;
    this.faultyTimesForSpecialDayExist = this.validateTimeArray(this.editDate);
    if (!this.faultyTimesForSpecialDayExist) {
      this.convertDateToTimeString(this.editDate.times);
      for (const machine of this.selectionService.selectedMachines) {
        this.editDate.machine = machine;
        await this.operationTimesService.setSpecialDay(this.editDate);
      }
      await this.getAllSpecialDays();
    }
    this.currentlySaving = false;
  }

  async removeSpecialDay() {
    this.currentlySaving = true;

    for (const machine of this.selectionService.selectedMachines) {
      await this.operationTimesService.removeSpecialDay({
        date: this.editDate.date,
        machine: machine,
      });
    }
    await this.getAllSpecialDays();
    this.currentlySaving = false;

  }

  async removeAllTimeForDay() {
    this.editDate.times = [];
  }

  addOperationTime() {
    if (this.editDate.times.length > 0) {
      const endOfPreviousTimePeriod = this.editDate.times[
        this.editDate.times.length - 1
      ].endTime;
      this.editDate.times.push({
        startTime: endOfPreviousTimePeriod,
        endTime: moment(this.editDate.date)
          .clone()
          .hours(21)
          .minutes(30)
          .utc()
          .toDate(),
      });
    } else {
      this.editDate.times.push({
        startTime: moment(this.editDate.date)
          .clone()
          .hours(6)
          .minutes(5)
          .utc()
          .toDate(),
        endTime: moment(this.editDate.date)
          .clone()
          .hours(21)
          .minutes(30)
          .utc()
          .toDate(),
      });
    }
  }
  removeOperationTime(operationTimeIndex) {
    this.editDate.times.splice(operationTimeIndex, 1);
  }

  getSpecialDay(date) {
    return this.specialDays.find((specialDay: SpecialDay) =>
      this.checkIfSameDay(specialDay.date, date)
    );
  }

  checkIfSameDay(a: Date, b: Date) {
    return moment(a).isSame(b, "day");
  }

  convertTimesStringsToDate(
    times: { startTime: string; endTime: string }[],
    date
  ) {
    times.forEach((time: {}) => {
      for (const pointOfTime in time) {
        if (time.hasOwnProperty(pointOfTime)) {
          const timeArray = time[pointOfTime].split(":");
          const hours = timeArray[0];
          const minutes = timeArray[1];
          time[pointOfTime] = moment(date)
            .clone()
            .hours(hours)
            .minutes(minutes);
        }
      }
    });
  }

  convertDateToTimeString(times: { startTime: string; endTime: string }[]) {
    times.forEach((time: {}) => {
      for (const pointOfTime in time) {
        if (time.hasOwnProperty(pointOfTime)) {
          let timeString: string;
          timeString = `${moment(time[pointOfTime]).hours()}:${moment(
            time[pointOfTime]
          ).minutes()}`;
          time[pointOfTime] = timeString;
        }
      }
    });
  }

  validateTimeArray(specialDay: SpecialDay) {
    if (specialDay.times.length === 0) return false;
    let timeFormatIsFaulty = false;
    let lastPointOfTime = specialDay.times[0].startTime;
    let foundFaultyTimes = false;
    specialDay.times.forEach((time: { startTime; endTime }) => {
      if (!foundFaultyTimes) {
        if (
          moment(time.startTime).hours() < moment(lastPointOfTime).hours() ||
          (moment(time.startTime).hours() === moment(lastPointOfTime).hours() &&
            moment(time.startTime).minutes() <
              moment(lastPointOfTime).minutes())
        ) {
          timeFormatIsFaulty = true;
          foundFaultyTimes = true;
        } else if (
          moment(time.endTime).hours() < moment(time.startTime).hours() ||
          (moment(time.endTime).hours() === moment(time.startTime).hours() &&
            moment(time.endTime).minutes() < moment(time.startTime).minutes())
        ) {
          timeFormatIsFaulty = true;
          foundFaultyTimes = true;
        } else lastPointOfTime = time.endTime;
      }
    });
    return timeFormatIsFaulty;
  }

  findSpecialDaysInCommon(
    currentSpecialDaysArray: SpecialDay[],
    specialDaysForMachine: SpecialDay[]
  ) {
    const specialDaysInCommon = [];
    currentSpecialDaysArray.forEach((currentSD: SpecialDay) => {
      const sameDay = specialDaysForMachine.find(
        (machineSD: SpecialDay) => machineSD.date === currentSD.date
      );
      if (sameDay) specialDaysInCommon.push(sameDay);
    });
    return specialDaysInCommon;
  }

  onCancelSelectedFailure() {
    this.selectedFailure = null;
  }

  async onAddFailureCategoryWithoutChildren() {
    if (this.selectedFailure.children) {
      await this.failureCategoryService.addMachineToCategory(
        this.selectedFailure._id,
        this.selectionService.forMachine,
        false
      );
    } else
      await this.eventDescriptionService.addMachineToEventDescription(
        this.selectedFailure._id,
        this.selectionService.forMachine
      );
    this.selectedFailure = null;
    await this.getFailureHierarchy();
  }

  async onAddFailureCategoryWithChildren() {
    await this.failureCategoryService.addMachineToCategory(
      this.selectedFailure._id,
      this.selectionService.forMachine,
      true
    );
    this.selectedFailure = null;
    await this.getFailureHierarchy();
  }

  async onRemoveFailureCategory() {
    if (this.selectedFailure.children) {
      await this.failureCategoryService.removeMachineFromCategory(
        this.selectedFailure._id,
        this.selectionService.forMachine
      );
    } else
      await this.eventDescriptionService.removeMachineFromEventDescription(
        this.selectedFailure._id,
        this.selectionService.forMachine
      );
    this.selectedFailure = null;
    await this.getFailureHierarchy();
  }

  async saveChildrenForCategory() {
    let path = "";
    if (this.selectedFailure.path && this.selectedFailure.label) {
      this.selectedFailure.path.forEach((parent) => (path += ";" + parent));
      path += ";" + this.selectedFailure.label + ";";
    } else path = null;
    for (const child of this.selectedFailure.newChildren) {
      if (child.label) {
        if (child.isEventDescr) {
          await this.eventDescriptionService.addNewEventDescription(
            child.label,
            this.selectedFailure._id,
            this.selectionService.forMachine
          );
        } else {
          await this.failureCategoryService.addNewFailureCategory(
            child.label,
            path,
            this.selectionService.forMachine
          );
        }      
      }
    }
    await this.getFailureHierarchy();
    this.openAddChildrenDialogue = false;
  }

  onCreateChildren() {
    this.selectedFailure["newChildren"] = [{ label: "", isEventDescr: false }];
    this.openAddChildrenDialogue = true;
  }

  onAddNewChild() {
    this.selectedFailure.newChildren.push({ label: "", isEventDescr: false });
  }

  onRemoveChild(childIndex) {
    this.selectedFailure.newChildren.splice(childIndex, 1);
  }

  async handleTabViewChange(e) {
    var index = e.index;
    if (index === 1) {  // case of  "Werksöffnungen"
      await this.getAllSpecialDays();
    }
}

}
