import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Event} from '../models/event';
import {EventDescription} from '../models/event-description';
import {Daystats} from '../models/daystats';
import {toPromise} from 'rxjs-compat/operator/toPromise';

@Injectable()

export class EventService {

  dataAPIEndpoint = environment.APIEndpoint + '/event';
  data: any;

  constructor(private http: HttpClient) {
  }

  getRawEvents(startDate, endDate, machineIds: string[]): Promise<Event[]> {
    if (!endDate) endDate = startDate;
    const body = {
      start: startDate,
      end: endDate,
      machineIds
    };
    return this.http.post<Event[]>(this.dataAPIEndpoint + '/all', body).toPromise();
  }

  upDateEvent(event: Event) {
    return this.http.put(this.dataAPIEndpoint + '/', event).toPromise();
  }

  upDatePartialEventObs(event: Partial<Event>) {
    return this.http.patch(this.dataAPIEndpoint + '/', event);
  }

  postEvent(event: Event) {
    return this.http.post(this.dataAPIEndpoint + '/', event).toPromise();
  }

  deleteEvent(id: string) {
    return this.deleteEventObs(id).toPromise();
  }

  deleteEventObs(id: string) {
    return this.http.delete(this.dataAPIEndpoint + '/' + id);
  }
}

