<!-- machines table -->

<p-table [value]="machines">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Anlage</th>
      <th>Restart Timer (s)</th>
      <th>Geschw. Verlust Timer (s)</th>
      <th>Aktiv</th>
      <th>Kurzstopp Dauer (s)</th>
      <th>Tablet verbunden</th>
      <th>Verzögerungs- faktor</th>
      <th>max. Geschwindigkeit</th>
      <th>Verpackungs- einheit</th>
      <th>
        <span *ngIf="[Role.ADMIN].includes(role)">Bearbeiten</span>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
    <tr class="p-text-center">
      <td>
        {{ rowData.name }}
      </td>
      <td>
        {{ rowData.restartDelayTime }}
      </td>
      <td>
        {{ rowData.slowDownEventTime }}
      </td>
      <td>
        {{ rowData.isActive | booleanToLabel }}
      </td>
      <td>
        {{ rowData.shortStopDuration }}
      </td>
      <td>
        {{ rowData.socketID | socketIdToLabel }}
      </td>
      <td>
        {{ rowData.accumulateTimeFactor }}
      </td>
      <td>
        {{ rowData.defaultMaxBpm }}
      </td>
      <td>
        {{ rowData.defaultUnitsPerContainer }}
      </td>
      <td>
        <img
          *ngIf="[Role.ADMIN].includes(role)"
          class="cursor-pointer"
          (click)="onEditMachine(rowData)"
          src="./assets/icons/pencil.svg"
          alt=""
        />
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- edit machine dialog -->

<p-dialog
  header="Anlagen Parameter bearbeiten"
  [(visible)]="displayDialog"
  [modal]="true"
  styleClass="width-600 overflow-auto"
>
  <div id="modal-content2" *ngIf="selectedMachine">
    <div class="p-field p-grid">
      <label class="p-col-5" for="machine">Anlage</label>
      <div class="p-col-7">
        <input
          pInputText
          id="machine"
          disabled="true"
          [value]="this.selectedMachine.name"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="restart">Restart Timer (s)</label>
      <div class="p-col-7">
        <input
          pInputText
          id="restart"
          type="number"
          [(ngModel)]="this.selectedMachine.restartDelayTime"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="slowDown">Geschw. Verlust Timer (s)</label>
      <div class="p-col-7">
        <input
          pInputText
          id="slowDown"
          type="number"
          [(ngModel)]="this.selectedMachine.slowDownEventTime"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="active">Aktiv</label>
      <div class="p-col-7">
        <select id="active" [(ngModel)]="this.selectedMachine.isActive">
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      </div>
    </div>

    <div class="p-field p-grid p-pt-2">
      <label class="p-col-5" for="shortStop">Kurzstopp Dauer (s)</label>
      <div class="p-col-7">
        <input
          pInputText
          id="shortStop"
          type="number"
          [(ngModel)]="this.selectedMachine.shortStopDuration"
        />
      </div>
    </div>

    <div class="p-field p-grid p-pt-2">
      <label class="p-col-5" for="socketID">Tablet verbunden</label>
      <div class="p-col-7">
        <select
          id="socketID"
          [disabled]="!tabletIsConnected"
          [(ngModel)]="tabletIsConnected"
        >
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="accumulationFactor">Verzögerungsfaktor</label>
      <div class="p-col-7">
        <input
          pInputText
          id="accumulationFactor"
          type="number"
          [(ngModel)]="this.selectedMachine.accumulateTimeFactor"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="defaultMaxBpm"
        >max. Geschwindigkeit/min</label
      >
      <div class="p-col-7">
        <input
          pInputText
          id="defaultMaxBpm"
          type="number"
          [(ngModel)]="this.selectedMachine.defaultMaxBpm"
        />
      </div>
    </div>

    <div class="p-field p-grid">
      <label class="p-col-5" for="defaultPackaging">Verpackungseinheit</label>
      <div class="p-col-7">
        <input
          pInputText
          id="defaultPackaging"
          type="number"
          [(ngModel)]="this.selectedMachine.defaultUnitsPerContainer"
        />
      </div>
    </div>
  </div>

  <p-footer>
    <div class="p-d-flex p-jc-end p-pt-2">
      <div>
      <button
        type="button"
        pButton
        icon="fa fa-check"
        (click)="saveMachineSettings()"
        label="Speichern"
      ></button>
      </div>
    </div>
  </p-footer>
</p-dialog>

