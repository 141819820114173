import {Machine} from './machine';
import {Packaging} from './packaging';

export class TargetBpm {
  constructor(public _id: string,
              public bpm: number,
              public machine: Machine,
              public packaging: Packaging,
              public createdAt: Date,
              public updatedAt: Date) {

  }

}
