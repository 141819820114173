import {ProcessPhase} from './process-phase';
import {Machine} from './machine';
import {EventDescription} from './event-description';

export class Event {
  constructor(public _id: string,
              public isSlowdown: boolean,
              public processPhase: ProcessPhase,
              public machine: Machine,
              public startTimestamp: string,
              public endTimestamp: string,
              public eventDescription: Partial<EventDescription> = null,
              public createdAt: Date,
              public updatedAt: Date,
              public durationInSeconds: number,
              public comment: string = null,

              public poId: string,
              public slowDownBottleCount: number,
              public currentSlowDownEventTime: number,
              public fillingProcess: string,
              public isPlannedInterruption: boolean,

  ) { }
}
