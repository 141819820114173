import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Utils} from '../../../utils';
import {ProcessPhaseLabel} from '../../../models/process-phase';
import {ChartColors} from "../../../models/colors";

@Component({
  selector: 'app-slowdown-evaluation-chart',
  template: '<div [chart]="chart"></div>',
})

export class SlowdownEvaluationChartComponent implements OnInit {

  chart: Chart;

  @Input() name: string;
  @Input() color: string;
  @Input()
  set data(data: any[]) {
    if (this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }
    const convertedOeeDuration = [];
    const convertedSlowdownDuration = []
    data.forEach((element: {
      event: string,
      actualSlowdownDuration: number,
      slowDownOeeMinutes: number,
      hidden: boolean
    }) => {
      let label: string;
      if (ProcessPhaseLabel[element.event]) {
        label = ProcessPhaseLabel[element.event];
      } else {
        label = element.event;
      }
      if (!element.hidden) {
        convertedSlowdownDuration.push([label, Utils.round(element.actualSlowdownDuration, 2)]);
        convertedOeeDuration.push([label, Utils.round(element.slowDownOeeMinutes, 2)]);
      }
    });


    this.chart.addSeries({
      type: 'column',
      name: 'OEE',
      data: convertedOeeDuration,
      color: ChartColors['oee']
    }, true, true);

    this.chart.addSeries({
      type: 'column',
      name: 'Geschwindigkeitsverlust',
      data: convertedSlowdownDuration,
      color: ChartColors['slowdowns']
    }, true, true);
  }

  constructor() {
    this.chart = new Chart({
      chart: {
        type: 'column',
        height: 220,
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          rotation: -30,
          useHTML: true,
          style: {
            width: 100,
            textAlign: 'right',
            lineHeight: '11px'
          },
        },
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Zeiten in Min'
        }
      },
      series: [],
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '{series.name}<br>',
        pointFormat: '{point.y:.2f}min',
        style: {
          width: 130,
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          stacking: 'normal',
        },
      }
    });
  }

  ngOnInit() {
  }


}
