import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { environment } from '../../environments/environment';
import { Sku } from '../models/sku';

@Injectable()
export class FailureCategoryService {

  dataAPIEndpoint = environment.APIEndpoint + '/failure-category';
  data: any;

  constructor(private http: HttpClient) {
  }


  getFailureCategories() {
    return this.http.get<Sku[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getFailureCategoryById(id: string) {
    const params = new HttpParams().append('id', id);
    return this.http.get(this.dataAPIEndpoint, {params: params});
  }

  getFailureHierarchyObs(machineId: string, includeInactive = false) {
    let params;
    if (includeInactive) {
      params = new HttpParams().append('machine', machineId).append('includeInactive', `${includeInactive}`);
    } else {
      params = new HttpParams().append('machine', machineId);
    }
    return this.http.get<TreeNode[]>(environment.APIEndpoint + '/line-info/failure-hierarchy', {params: params});
  }
  getFailureHierarchy(machineId: string, includeInactive = false) {
    return this.getFailureHierarchyObs(machineId, includeInactive).toPromise();
  }


  addNewFailureCategory(label, path, machineId) {
    const category = {
      path: path,
      label: label,
      machineId: machineId
    };
    return this.http.post(this.dataAPIEndpoint + '/add-new-category', category).toPromise();
  }

  addMachineToCategory(id, machineId, includeAllChildren) {
    const category = {
      id: id,
      machineId: machineId,
      includeAllChildren: includeAllChildren
    };
    return this.http.post(this.dataAPIEndpoint + '/add-machine-to-category', category).toPromise();
  }

  removeMachineFromCategory(id, machineId) {
    const category = {
      id: id,
      machineId: machineId,
    };
    return this.http.post(this.dataAPIEndpoint + '/remove-machine-from-category', category).toPromise();
  }
}
