import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Packaging} from '../models/packaging';
import {Sku} from '../models/sku';

@Injectable()
export class PackagingService {

  dataAPIEndpoint = environment.APIEndpoint + '/packaging';
  data: any;

  constructor(private http: HttpClient) { }


  getPackagings() {
    return this.http.get<Packaging[]>(this.dataAPIEndpoint + '/all').toPromise();
  }

  getPackaging(id: string) {
    const params = new HttpParams().append('id', id);
    return this.http.get<Packaging>(this.dataAPIEndpoint, {params: params}).toPromise();
  }

  postPackaging(packaging: Packaging) {
    return this.http.post<Packaging>(this.dataAPIEndpoint + '/', packaging).toPromise();
  }

}
