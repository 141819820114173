import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Role } from 'common/Auth/Role';
import { TankType } from '../../../models/tank-type';

import { TankTypeService } from '../../../services/tank-type.service';

@Component({
  selector: "app-tanktype-data",
  templateUrl: "./tanktype-data.component.html",
  styleUrls: ["./tanktype-data.component.scss"],
})
export class TankTypeDataComponent implements OnInit {
  tanktypeData: TankType[];

  displayAddTanktypeDialog: boolean = false;

  newPackagingName;


  columnsTanktype = [
    { field: "name", header: "Name" },
  ];

  public role: Role;
  public Role = Role;  // otherwise not accessable in the template

  constructor(private authService: AuthService, private tanktypeService: TankTypeService) {
    this.role = this.authService.role;
  }


  async ngOnInit() {
    this.tanktypeData = await this.tanktypeService.getTankTypes().toPromise();
  }

  showDisplayAddTanktypeDialog() {
    this.displayAddTanktypeDialog = true;
  }

  clearAllNewItemData() {
    this.newPackagingName = null;
    this.displayAddTanktypeDialog = false;


  }
  async saveTanktype() {

    if(!this.newPackagingName) return alert(" Sie müssen einen Namen angeben.")

    const newTank = new TankType();
    newTank.isActive = true;
    newTank.name = this.newPackagingName;

    
    await this.tanktypeService.postTankType(newTank).toPromise();

    this.tanktypeData = await this.tanktypeService.getTankTypes().toPromise();
    this.clearAllNewItemData();
  } 


  async toggleActive(id: string) {
    const currentTank = this.tanktypeData.find(item => item._id === id);
    await this.tanktypeService.toggleActivation(currentTank).toPromise();
    this.tanktypeData = await this.tanktypeService.getTankTypes().toPromise();
  }
  


}
