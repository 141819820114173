import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment } from '../../environments/environment';
import {SetupType} from '../models/setup-type';


@Injectable()
export class SetupTypesService {

  dataAPIEndpoint = environment.APIEndpoint + '/setuptypes';
  data: any;

  constructor(private http: HttpClient) { }


  getSetupTypes() {
    return this.http.get<SetupType[]>(this.dataAPIEndpoint + '/').toPromise();
  }

  postSetupType(setupType: SetupType) {
    return this.http.post<SetupType>(this.dataAPIEndpoint + '/', setupType).toPromise();
  }

  editSetupType(setupType: SetupType) {
    return this.http.put<SetupType>(this.dataAPIEndpoint + '/', setupType).toPromise();
  }

  inactivateSetupType(setupType: SetupType) {
    return this.http.put<SetupType>(this.dataAPIEndpoint + '/inactivate', setupType).toPromise();
  }

}
