import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { NoStopData } from "../models/no-stop-data";

@Injectable()
export class NoStopScreenService {
  private apiEndpoint = environment.APIEndpoint;
  data: any;
  dataAPIEndpoint = this.apiEndpoint + '/filling-process/no-stop-data';

  constructor(private http: HttpClient) {}

  getNoStopData() {
    return this.http.get<NoStopData[]>(this.dataAPIEndpoint).toPromise();
  }
}
