<app-header-bar [title]="'OEE Dashboard'"></app-header-bar>

<div class="subheader-menu p-pt-2 p-d-flex p-dir-col">
  <div class="p-d-flex p-dir-row p-ai-center p-pt-2">
    <span>Anlage</span>
    <p-multiSelect
      [defaultLabel]="
        selectionService.selectedMachines?.length > 0
          ? 'Alle Anlagen ausgewählt'
          : 'Keine Anlage ausgewählt'
      "
      [options]="selectionService.machineList"
      [ngModel]="selectionService.selectedMachines"
      (ngModelChange)="onChangeMachineDropdown($event)"
      [displaySelectedLabel]="true"
      [maxSelectedLabels]="0"
      [selectedItemsLabel]="'{0} Anlage(n) ausgewählt'"
      [disabled]="disableMachineDropdown"
    ></p-multiSelect>
    <span class="">Zeitraum</span>
    <p-calendar
      dateFormat="dd.mm.yy"
      [(ngModel)]="selectionService.selectedDates"
      selectionMode="range"
      [maxDate]="selectionService.today"
      [defaultDate]="selectionService.today"
      [locale]="selectionService.de"
      placeholder="Heute"
    >
    </p-calendar>
    <p-button [disabled]="dataCurrentlyLoading$ | async" (click)="onFetchData()" label="Daten holen"
      ></p-button
    >
  </div>
  <div class="p-dir-row p-ai-center p-pt-4 p-pb-4">
    <span>Packtype</span>
    <p-multiSelect
      [defaultLabel]="'Packtype wählen'"
      class="p-col-6"
      [options]="packtypeList"
      [(ngModel)]="selectedPacktypes"
      [displaySelectedLabel]="true"
      [maxSelectedLabels]="0"
      [selectedItemsLabel]="'{0} Packtype(s) ausgewählt'"
      (ngModelChange)="onChangePacktypeDropdown()"
      [disabled]="disablePacktypeDropdown"
    ></p-multiSelect>
  </div>
</div>

<!-- highcharts display -->

<div class="main-container">
  <div *ngIf="dataAvailable" class="p-grid p-col-12">
    <div
      *ngIf="dataCurrentlyLoading$ | async; else loadedData"
      class="loader p-offset-6"
    ></div>
    <ng-template #loadedData>
      <div class="p-col-6 p-p-5">
        <span>OEE im Zeitraum</span>
        <div class="p-text-center" *ngIf="overtime">Überbuchung: {{overtime}} min.</div>
        <app-oee-evaluation-chart
          [data]="oeeDetails$ | async"
        ></app-oee-evaluation-chart>
      </div>

      <div class="p-col-6 p-p-5">
        <span>Rüsten / Abrüsten Details</span>
        <app-setup-evaluation-chart
          [data]="setupDetails$ | async"
        ></app-setup-evaluation-chart>
      </div>

      <div class="p-col-6 p-p-5">
        <span>Geschwindigkeitsverluste</span>
        <p-checkbox
          class="float-right p-pl-2"
          [(ngModel)]="showSlowdownRemainder"
          binary="true"
          inputId="showSlowdownRemainder"
          (onChange)="toggleShowRemainder()"
        ></p-checkbox>
        <span
          style="font-size: 0.75rem"
          class="float-right"
          >GV ohne Zuordnung anzeigen</span
        >
        <app-slowdown-evaluation-chart
          [data]="slowdowns$ | async"
          [color]="slowdownColor"
        >
        </app-slowdown-evaluation-chart>
      </div>

      <div
        *ngFor="let chartData of columnChartsData"
        class="p-col-6 p-p-5"
      >
        <span>{{ chartData.name }}</span>
        <app-detailed-failure-evaluation
          [data]="chartData.data$ | async"
          [name]="chartData.name"
          [color]="chartData.color"
        >
        </app-detailed-failure-evaluation>
      </div>

    </ng-template>
  </div>

  <ng-container *ngIf="!dataAvailable">
    <div class="p-my-auto p-mt-6">
      <p>
        Bitte wählen Sie einen Zeitraum für OEE- und Störzeitberechnungen.
      </p>
    </div>
  </ng-container>
</div>
