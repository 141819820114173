import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Po} from '../models/po';
import { Observable } from 'rxjs';
import { FillingProcess } from '../models/filling-process';

@Injectable()
export class FillingProcessService {

  dataAPIEndpoint = environment.APIEndpoint + '/filling-process';

  data: any;

  constructor(private http: HttpClient) {
  }

  updateFillingProcess(editedFillingProcess) {
    return this.http.put(this.dataAPIEndpoint + '/', editedFillingProcess).toPromise();
  }

  postNewFillingProcess(fp, po) {
    const body = {
      fillingProcess: fp,
      po: po
    };
    return this.http.post<Po>(this.dataAPIEndpoint + '/new-fp', body).toPromise();
  }

  deleteFp(id: string) {
    return this.http.delete(this.dataAPIEndpoint + '/' + id).toPromise();
  }

  getFillingProcesses(start, end, machineIds): Observable<FillingProcess[]> {
    const collectionAPIEndpoint = environment.APIEndpoint + '/collections/fillingprocesses';

    const body = {
      startDate: start,
      endDate: end,
      machineIds
    };
    return this.http.post<FillingProcess[]>(collectionAPIEndpoint, body)
  } 
}
