import { Machine } from './machine';
import { SetupType } from './setup-type';
import { TargetTime } from './target-time';

export class FillingProcess {
  constructor(public _id: string,
              public rawAmountFillingProcessInUnits: number,
              public startTimestamp: Date,
              public endTimestamp: Date,
              public machine: Machine,
              public targetTime: TargetTime,
              public createdAt: Date,
              public updatedAt: Date,
              public comments: string,
              public setupType: SetupType,
              public poId: string,
              public tankType?: string
  ) {}
}
