import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as moment from 'moment';

@Component({
  selector: 'app-machine-speed-chart',
  template: '<div [chart]="chart"></div>'
})

export class MachineSpeedChartComponent implements OnInit {

  date: number[] = [];
  chart: Chart;
  maxValue: number;

  /*
  @Input()
  set machineBpm(value: number){
    this.maxValue = value;
    console.log(' set Max bpm Value from DB for this machine ', this.maxValue)
  }
  */

  @Input()
  machineBpm: number;

  @Input()
  data: any;

  @Input() 
  machineType: number;

  constructor() {
  }

  ngOnInit() {
    
      this.maxValue = this.machineBpm ? this.machineBpm : 1 ;
      this.setAndShowChart(this.data);
    
  }

  setAndShowChart( value: any ){

    if (this.chart && this.chart.ref) {
      while (this.chart.ref.series.length > 0) {
        this.chart.ref.series[0].remove();
      }
    }

    const transformed = [];
    value.forEach(element => {

      const timezoneOffset = new Date(moment(element[0]).toISOString()).getTimezoneOffset() / 60;
      const local = moment(element[0]).clone().subtract(timezoneOffset, 'hours');
      transformed.push({x: local.valueOf(), y: element[1], event: element[2]});


      return element;
    });

    let bpmName = 'Gebinde';
    if (this.machineType === 2) bpmName = 'Paletten';
    bpmName += '/Minute';


    this.chart = new Chart({
      chart: {
        type: 'spline',
        zoomType: 'x',
        height: 150,
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45,
        }
      },
      yAxis: {
        title: {
          text: bpmName
        },
        min: 0,
        max: 1.1 * this.maxValue, 
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter: function () {
          // @ts-ignore
          if (this.point.event) {
            // @ts-ignore
            return `<span style="font-size: 0.85em"><b>${this.y} ${bpmName}</b></span><br /><span style="font-size:  0.85em">${this.point.event}</span>`;
          } else {
            return `<span style="font-size: 0.85em"><b>${this.y} ${bpmName}</b></span>`;
          }
        }
      },
      credits: {
        enabled: false
      },
      series: []
    });


    this.chart.addSeries({
      type: 'spline',
      name: bpmName,
      data: transformed,
      zones: [{
        value: 0.05 * this.maxValue,
        color: '#ff0000' // $castrol-red
      }, {
        color: '#3a7248' // $castrol-green
      }],
      lineWidth: 2
    }, true, true);
  }


}
