import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bottlesToBoxes'
})
export class BottlesToBoxesPipe implements PipeTransform {
  transform(bottles: number, boxSize: number) {
    return Math.floor(bottles / boxSize);
  }
}
